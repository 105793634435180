// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import includes from 'lodash/includes';
import first from 'lodash/first';

import constants from 'app/shared/constants/ConstantsBundle';

if (__SERVER__) {
  var setCookie = require('set-cookie-parser');
}

const RequesterActions = {
  pushxEid(xEid) {
    return function (dispatch) {
      dispatch({
        type: constants.PUSH_XEID,
        payload: xEid,
      });
    };
  },
  saveServerSideRequestCookies(cookies) {
    return (dispatch) => {
      return dispatch({
        type: constants.SET_SERVER_SIDE_COOKIES,
        payload: cookies,
      });
    };
  },
  saveServerSideResponseCookies(response) {
    return (dispatch, getState) => {
      if (__SERVER__) {
        const cookieArray = setCookie.parse(response); //eslint-disable-line block-scoped-var
        const state = getState();
        const updatedServerSideCookies = assign({}, state.user.serverSideCookies);

        cookieArray.forEach((cookie) => {
          updatedServerSideCookies[cookie.name] = cookie.value;
        });

        return dispatch({
          type: constants.SET_SERVER_SIDE_COOKIES,
          payload: updatedServerSideCookies,
        });
      }
    };
  },
  saveServerSideSetCookieArray(cookieArray) {
    return (dispatch, getState) => {
      const state = getState();

      const existingCookiesToAdd = state.user.serverSideSetCookiesArray.filter((oldCookieLine) => {
        let shouldInclude = true;

        cookieArray.forEach((newCookieLine) => {
          const oldCookieName = first(oldCookieLine.split('='));

          if (includes(newCookieLine, `${oldCookieName}=`)) {
            shouldInclude = false;
          }
        });

        return shouldInclude;
      });

      const totalCookies = cookieArray.concat(existingCookiesToAdd);

      return dispatch({
        type: constants.SET_SERVER_SIDE_SET_COOKIE_ARRAY,
        payload: totalCookies,
      });
    };
  },
  addRequestToSsrTrace(entry) {
    return (dispatch) => {
      return dispatch({
        type: constants.ADD_REQUEST_TO_SSR_TRACE,
        payload: entry,
      });
    };
  },
  updateSessionToken(sessionToken) {
    return (dispatch) => {
      if (sessionToken) {
        return dispatch({
          type: constants.UPDATE_SESSION_TOKEN,
          payload: sessionToken,
        });
      }

      return false;
    };
  },
  updateCsrfToken(creds) {
    return (dispatch) => {
      if (creds && creds.csrfToken) {
        return dispatch({
          type: constants.UPDATE_CSRF_TOKEN,
          payload: creds.csrfToken,
        });
      }

      return false;
    };
  },
};

export default RequesterActions;

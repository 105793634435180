import getBrowserLogger from '@zg-rentals/logger-browser';
import type { DatadogPluginConfig } from '@zg-rentals/monitor-browser';
import type { Logger, LoggerOptions } from '@zg-rentals/logger-browser';
import type { MonitorPlugin, MonitorReporter } from '@zg-rentals/monitor-base';
import type { RpBootstrapOptions } from '@zg-rentals/rp-bootstrap-base';
import { BrowserTracer } from '@zg-rentals/trace-browser';
import { getClient, setClient } from '@zg-rentals/http-client';
import { getCurrentEnvForClientSide, PRODUCTION } from '@zg-rentals/rental-platform-config';
import { getDefaults, RpBootstrap } from '@zg-rentals/rp-bootstrap-base';
import { isDev, isTest } from '@zg-rentals/environment-utils';
import { logError, setGlobalErrorContext } from '@zg-rentals/log-error';
import { setAppInfo } from '@zg-rentals/app-info';

import {
  BrowserLogReporter,
  BrowserMonitor,
  BrowserMonitorMarlinReporter,
  DatadogPlugin,
  WebVitalsPlugin,
} from '@zg-rentals/monitor-browser';

export type RpBootstrapBrowserOptions = {
  loggerOptions?: LoggerOptions;
  monitorOptions?: {
    reporters?: Array<(logger: Logger) => MonitorReporter>;
    plugins?: Array<(logger: Logger) => MonitorPlugin>;
    datadogPluginConfig?: DatadogPluginConfig;
  };
} & RpBootstrapOptions;

export class RpBootstrapBrowser extends RpBootstrap {
  private options?: RpBootstrapBrowserOptions;
  private logger?: Logger;
  private monitor?: BrowserMonitor;
  private tracer?: BrowserTracer;
  private isBrowser: boolean;

  constructor() {
    super();
    this.isBrowser = typeof window !== 'undefined';
  }

  public bootstrap(options: RpBootstrapBrowserOptions) {
    if (this.isInitialized) {
      // eslint-disable-next-line no-console
      console.debug('RpBootstrapBrowser: already bootstrapped');
      return this;
    }

    this.isInitialized = true;

    this.options = options;
    const appInfo = this.getInfo();

    this.initLogger();
    this.initMonitor();
    this.initTracer();

    if (!isTest()) {
      this.logger?.debug('bootstrapped' + JSON.stringify(appInfo));
    }

    setAppInfo({ ...appInfo });
    setGlobalErrorContext(appInfo);

    this.configureGlobals(this.logger!, this.monitor!, this.tracer!);

    setClient(
      getClient().extend((defaults) => ({
        ...defaults,
        headers: {
          ...defaults.headers,
          'x-build-id': this.options?.buildNumber ? `${this.options.buildNumber}` : undefined,
        },
      })),
    );

    this.addErrorEventListeners();

    return this;
  }

  public getLogger(name?: string): Logger {
    if (!this.logger) {
      throw new Error('RpBootstrapBrowser: logger not initialized');
    }
    if (!name) {
      return this.logger;
    }
    return this.logger.child({ name });
  }

  public getMonitor(): BrowserMonitor {
    if (!this.monitor) {
      throw new Error('RpBootstrapBrowser: monitor not initialized');
    }
    return this.monitor;
  }

  public getTracer(): BrowserTracer {
    if (!this.tracer) {
      throw new Error('RpBootstrapBrowser: tracer not initialized');
    }
    return this.tracer;
  }

  public getInfo() {
    if (!this.options) {
      throw new Error('RpBootstrapBrowser: options not initialized');
    }
    return {
      appName: this.options.appName,
      buildNumber: this.options.buildNumber,
    };
  }

  public reset() {
    super.reset();
    this.logger = undefined;
    this.tracer = undefined;
    this.monitor = undefined;
    this.options = undefined;
  }

  private initLogger() {
    const { loggerOptions = {} } = this.options!;
    const { logPath, ...loggerOptionsRest } = loggerOptions;
    const newLogPath = isDev() ? undefined : logPath;

    this.logger = getBrowserLogger({
      name: this.options!.appName,
      ...loggerOptionsRest,
      logPath: newLogPath,
    });

    if (this.isBrowser) {
      // @ts-ignore
      window.log = this.logger;
    }
  }

  private initMonitor() {
    const reporters: Array<MonitorReporter> = [];
    const plugins: Array<MonitorPlugin> = [];
    if (this.isBrowser) {
      plugins.push(new WebVitalsPlugin(this.logger));
      if (this.options?.monitorOptions?.reporters) {
        reporters.push(...this.options.monitorOptions.reporters.map((r) => r(this.logger!)));
      }
      if (this.options?.monitorOptions?.plugins) {
        plugins.push(...this.options.monitorOptions.plugins.map((p) => p(this.logger!)));
      }

      if (this.options?.monitorOptions?.datadogPluginConfig && getCurrentEnvForClientSide() === PRODUCTION) {
        plugins.push(
          new DatadogPlugin({
            logger: this.getLogger('datadogPlugin'),
            datadogOptions: {
              service: this.options.appName,
              version: `${this.options.buildNumber}`,
              // spread at the end to allow for overrides
              ...this.options.monitorOptions.datadogPluginConfig.datadogOptions,
            },
          }),
        );
      }
      reporters.push(
        new BrowserMonitorMarlinReporter({
          logger: this.getLogger('BrowserMonitorMarlinReporter'),
          marlinOpts: {
            appName: this.options?.appName,
          },
        }),
      );

      // Inject a log reporter if no other reporters are configured, nicer devexp
      if (!reporters.length) {
        reporters.push(
          new BrowserLogReporter({
            logger: this.getLogger('browser-log-reporter'),
          }),
        );
      }
    }

    this.monitor = new BrowserMonitor({
      logger: this.logger,
      reporters,
      plugins,
    });

    if (this.isBrowser) {
      this.monitor.count({ name: 'browser init' });
    }
  }

  private initTracer() {
    if (this.isBrowser) {
      this.tracer = new BrowserTracer(this.monitor!);
      this.tracer.init();
    }
  }

  private addErrorEventListeners() {
    if (this.isBrowser) {
      window.addEventListener('error', (errorEvent) => {
        const { error, filename, lineno, colno } = errorEvent;
        logError({
          error,
          context: {
            filename: filename,
            lineNumber: lineno,
            columnNumber: colno,
          },
        });
      });
      window.addEventListener('unhandledrejection', (e) => {
        logError({
          error: e.reason,
          errorType: 'unhandledRejection',
        });
      });
    }
  }
}

let rpBootstrap = new RpBootstrapBrowser();

let isInitializedDefault = false;

export function bootstrap(options: RpBootstrapBrowserOptions) {
  if (isInitializedDefault) {
    rpBootstrap = new RpBootstrapBrowser();
  }
  isInitializedDefault = false;

  const defaults = getDefaults();

  return rpBootstrap.bootstrap({
    loggerOptions: {
      level: defaults.logLevel ?? 'info',
    },
    ...defaults,
    ...options,
  });
}

export function getDefaultBootstrap(overrides?: Partial<RpBootstrapBrowserOptions>) {
  if (rpBootstrap.isInitialized) {
    return rpBootstrap;
  }

  isInitializedDefault = true;

  const { appName, buildNumber, logLevel } = getDefaults();

  return rpBootstrap.bootstrap({
    appName: appName ?? 'unknown',
    buildNumber: buildNumber,
    loggerOptions: {
      level: logLevel ?? 'info',
      ...overrides?.loggerOptions,
    },
    ...overrides,
  });
}

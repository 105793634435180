import map from 'lodash/map';
import FloorplanUnit from 'app/shared/models/FloorplanUnit';
import Photo from 'app/shared/models/Photo';
import numberUtils from 'app/shared/utils/numberUtils';

interface Image {
  caption?: string;
  medium?: {
    url?: string;
    height?: number;
    width?: number;
  };
  large?: {
    url?: string;
    height?: number;
    width?: number;
  };
  squareThumb?: {
    url?: string;
  };
}

interface FloorplanModelProps {
  availabilityDate?: string;
  baths?: string | number;
  beds?: string | number;
  high?: string | number;
  layouts?: Array<Image>;
  low?: string | number;
  modelId?: string;
  name?: string;
  photos?: Array<Image>;
  sqft?: string | number;
  type?: string;
  units?: Array<any>; // Define the appropriate type if available
  zillow3dTourUrl?: string;
}

class FloorplanModel {
  modelId: string;
  name: string;
  type: string;
  availabilityDate: string;
  beds: number;
  baths: number;
  sqft: number;
  low: number;
  high: number;
  priceRangeDisplay: Array<string>;
  photos: Array<Photo>;
  largePhotos: Array<Photo>;
  thumbPhotos: Array<Photo>;
  units: Array<FloorplanUnit>;
  zillow3dTourUrl: string;

  constructor({
    availabilityDate = '',
    baths = '',
    beds = '',
    high = '',
    layouts = [],
    low = '',
    modelId = '',
    name = '',
    photos = [],
    sqft = '',
    type = '',
    units = [],
    zillow3dTourUrl = '',
  }: FloorplanModelProps = {}) {
    const modelImages: Array<Image> = [...layouts, ...photos];

    this.modelId = String(modelId);
    this.name = String(name);
    this.type = String(type);

    this.availabilityDate = String(availabilityDate);
    this.beds = Number(beds);
    this.baths = Number(baths);
    this.sqft = Number(sqft);

    this.low = Number(low);
    this.high = Number(high);
    this.priceRangeDisplay = [numberUtils.asCurrency(Number(low)), numberUtils.asCurrency(Number(high))];

    this.photos = map(modelImages, (image: Image = {}) => {
      return new Photo({
        caption: image.caption,
        url: image.medium?.url,
        height: image.medium?.height,
        width: image.medium?.width,
      });
    });

    this.largePhotos = map(modelImages, (image: Image = {}) => {
      return new Photo({
        caption: image.caption,
        url: image.large?.url,
        height: image.large?.height,
        width: image.large?.width,
      });
    });

    this.thumbPhotos = map(modelImages, (image: Image = {}) => {
      return new Photo({
        url: image.squareThumb?.url,
        caption: image.caption,
      });
    });

    this.units = map(units, (unit) => {
      return new FloorplanUnit(unit);
    });

    this.zillow3dTourUrl = String(zillow3dTourUrl);
  }
}

export default FloorplanModel;

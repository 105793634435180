import { filterUtils_getFilterLabels } from 'app/shared/utils/filterUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import capitalize from 'lodash/capitalize';
import MapData from 'app/shared/models/MapData';

interface Filter {
  [key: string]: any;
}

interface QueryObj {
  [key: string]: any;
}

interface MapDataProps {
  [key: string]: any;
}

interface SavedSearchParams {
  sid?: string | null;
  filter?: Filter;
  resourceId?: string;
  created?: number;
  name?: string;
  emailFrequency?: string;
  numberOfNewResults?: number;
  queryObj?: QueryObj;
  mapData?: MapDataProps;
  borderAreaId?: string | number | null;
}

class SavedSearch {
  searchId: string;
  borderAreaId: number;
  created: number;
  name: string;
  emailFrequency: string;
  numberOfNewResults: number;
  frequencyDescription: string;
  url: string;
  mapData: MapData;
  filter: Filter;
  filterDescription: string;

  constructor({
    sid = null,
    filter = {},
    resourceId = 'new-york-ny',
    created = 0,
    name = 'My search alert',
    emailFrequency = 'never',
    numberOfNewResults = 0,
    queryObj = {},
    mapData = {},
    borderAreaId = null,
  }: SavedSearchParams = {}) {
    const searchId = sid;
    const frequencyDescription = capitalize(emailFrequency).replace('Hourly', 'Instant');
    const filterDescription = filterUtils_getFilterLabels(filter);
    const queryParams = queryUtils.stringify(queryObj);
    const path = routeUtils.buildAreaPath({
      areaResourceId: resourceId,
      searchSlug: filter.search?.slug || '', // Assuming slug could be optional
    });
    const url = path + queryParams;

    this.searchId = String(searchId);
    this.borderAreaId = borderAreaId !== null ? Number(borderAreaId) : 0;
    this.created = Number(created);
    this.name = String(name);
    this.emailFrequency = String(emailFrequency);
    this.numberOfNewResults = Number(numberOfNewResults);
    this.frequencyDescription = String(frequencyDescription);
    this.url = String(url);
    this.mapData = new MapData(mapData);
    this.filter = filter;
    this.filterDescription = String(filterDescription);
  }
}

export default SavedSearch;

export const PRODUCTION = Symbol('PRODUCTION');
export const COMET_1 = Symbol('COMET_1');
export const STAGING = Symbol('STAGING');
export const DEV = Symbol('DEV');

export type EnvType = typeof PRODUCTION | typeof COMET_1 | typeof STAGING | typeof DEV;

type EnvByHostname = Record<string, EnvType>;

// this obj is not all inclusive
// `getCurrentEnvForClientSide` relies on the `includes` method to match all valid hostnames
const ENV_BY_HOSTNAME: EnvByHostname = {
  'hotpads.com': PRODUCTION,
  'www.zillow.com': PRODUCTION,
  'www.trulia.com': PRODUCTION,
  'streeteasy.com': PRODUCTION,
  'comet1.testpads.net': COMET_1,
  'www.qa.zillow.net': COMET_1,
  'testpads.net': STAGING,
  // ra3 and ra4 have been decommissioned
  'www.ra1.zillow.net': STAGING,
  'www.ra2.zillow.net': STAGING,
  localhost: DEV,
} as const;

export function getCurrentEnvForClientSide() {
  const currentHostname = location.hostname;
  for (const [hostname, env] of Object.entries(ENV_BY_HOSTNAME)) {
    if (currentHostname.includes(hostname)) {
      return env;
    }
  }

  // TODO log
  return DEV;
}

import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import AreaStat from 'app/shared/models/AreaStat';
import type Area from './Area';

type AreaType = 'neighborhood' | 'city' | 'county' | 'zip';

interface Model {
  low: number;
  high: number;
}

interface Pricing {
  models?: Array<Model>;
  summary?: {
    priceLow?: number;
    priceHigh?: number;
  };
}

interface PricingStats {
  [key: string]: string;
}

interface AreaPriceComparisonProps {
  links?: Array<Area>;
  pricingStats?: PricingStats;
  pricing?: Pricing;
}

class AreaPriceComparison {
  neighborhood: AreaStat | null = null;
  city: AreaStat | null = null;
  county: AreaStat | null = null;
  zip: AreaStat | null = null;

  constructor({ links = [], pricingStats = {}, pricing = {} }: AreaPriceComparisonProps = {}) {
    const { models, summary: { priceLow = 0, priceHigh = 0 } = {} } = pricing;

    let averagePrice = (priceLow + priceHigh) / 2;

    if (models && models.length > 0) {
      const cumulativePrice = reduce(models, (sum, model) => sum + (model.low + model.high) / 2, 0);

      averagePrice = cumulativePrice / models.length;
    }

    if (!averagePrice) {
      return;
    }

    links.forEach((area: Area) => {
      const areaType = area.type as AreaType;
      if (this[areaType] === null) {
        forEach(pricingStats, (percentage, areaId) => {
          if (area.id === areaId) {
            const percentageValue = parseInt(percentage) / 100;

            let priceDifference = 0;
            priceDifference = averagePrice - averagePrice / (percentageValue + 1);

            const areaAveragePrice = Math.round(averagePrice - priceDifference);
            priceDifference = Math.round(Math.abs(priceDifference));

            this[areaType] = new AreaStat({
              areaId: area.id,
              areaName: String(area.name),
              areaType: String(area.type),
              areaUriV2: String(area.uriV2),
              listPrice: averagePrice,
              areaAveragePrice,
              averagePrice,
              priceDifference,
              percentageValue,
            });
          }
        });
      }
    });
  }
}

export default AreaPriceComparison;

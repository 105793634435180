import type { MarlinOptions } from '@zg-rentals/marlin';
import { Marlin } from '@zg-rentals/marlin';

export type MarlinBrowserOptions = Pick<MarlinOptions, 'headers' | 'api'>;

export class MarlinBrowser extends Marlin {
  constructor(options: MarlinBrowserOptions) {
    super({ ...options, useCrossOrigin: true }, 1_000);
  }
}

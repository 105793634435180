import { css } from 'styled-components';

// Can be changed when our spacing standards change
const spacexs = '4px';
const space1x = '8px';
const space2x = '16px';
const space3x = '24px';
const space4x = '32px';
const space5x = '40px';
const space6x = '48px';
const space7x = '54px';
const space8x = '64px';
const space10x = '80px';
const space12x = '96px';
const space13x = '104px';

const SPACE_INSET = {
  '$space-inset-xs': `${spacexs} ${spacexs} ${spacexs} ${spacexs}`,
  '$space-inset-1x': `${space1x} ${space1x} ${space1x} ${space1x}`,
  '$space-inset-2x': `${space2x} ${space2x} ${space2x} ${space2x}`,
  '$space-inset-3x': `${space3x} ${space3x} ${space3x} ${space3x}`,
  '$space-inset-4x': `${space4x} ${space4x} ${space4x} ${space4x}`,
  '$space-inset-5x': `${space5x} ${space5x} ${space5x} ${space5x}`,
  '$space-inset-6x': `${space6x} ${space6x} ${space6x} ${space6x}`,
  '$space-inset-7x': `${space7x} ${space7x} ${space7x} ${space7x}`,
  '$space-inset-8x': `${space8x} ${space8x} ${space8x} ${space8x}`,
  '$space-inset-10x': `${space10x} ${space10x} ${space10x} ${space10x}`,
  '$space-inset-12x': `${space12x} ${space12x} ${space12x} ${space12x}`,
  '$space-inset-13x': `${space13x} ${space13x} ${space13x} ${space13x}`,
};

const SPACE_STACKED = {
  '$space-stack-xs': `0 0 ${spacexs} 0`,
  '$space-stack-1x': `0 0 ${space1x} 0`,
  '$space-stack-2x': `0 0 ${space2x} 0`,
  '$space-stack-3x': `0 0 ${space3x} 0`,
  '$space-stack-4x': `0 0 ${space4x} 0`,
  '$space-stack-5x': `0 0 ${space5x} 0`,
  '$space-stack-6x': `0 0 ${space6x} 0`,
  '$space-stack-7x': `0 0 ${space7x} 0`,
  '$space-stack-8x': `0 0 ${space8x} 0`,
  '$space-stack-10x': `0 0 ${space10x} 0`,
  '$space-stack-12x': `0 0 ${space12x} 0`,
  '$space-stack-13x': `0 0 ${space13x} 0`,
};

const SPACE_INLINE = {
  '$space-inline-xs': `0 ${spacexs} 0 0`,
  '$space-inline-1x': `0 ${space1x} 0 0`,
  '$space-inline-2x': `0 ${space2x} 0 0`,
  '$space-inline-3x': `0 ${space3x} 0 0`,
  '$space-inline-4x': `0 ${space4x} 0 0`,
  '$space-inline-5x': `0 ${space5x} 0 0`,
  '$space-inline-6x': `0 ${space6x} 0 0`,
  '$space-inline-7x': `0 ${space7x} 0 0`,
  '$space-inline-8x': `0 ${space8x} 0 0`,
  '$space-inline-10x': `0 ${space10x} 0 0`,
  '$space-inline-12x': `0 ${space12x} 0 0`,
  '$space-inline-13x': `0 ${space13x} 0 0`,
};

// Spacing on all four sides of element
export const inset = {
  _xs: css`
    padding: ${SPACE_INSET['$space-inset-xs']};
  `,
  _1x: css`
    padding: ${SPACE_INSET['$space-inset-1x']};
  `,
  _2x: css`
    padding: ${SPACE_INSET['$space-inset-2x']};
  `,
  _3x: css`
    padding: ${SPACE_INSET['$space-inset-3x']};
  `,
  _4x: css`
    padding: ${SPACE_INSET['$space-inset-4x']};
  `,
  _5x: css`
    padding: ${SPACE_INSET['$space-inset-5x']};
  `,
  _6x: css`
    padding: ${SPACE_INSET['$space-inset-6x']};
  `,
  _7x: css`
    padding: ${SPACE_INSET['$space-inset-7x']};
  `,
  _8x: css`
    padding: ${SPACE_INSET['$space-inset-8x']};
  `,
  _10x: css`
    padding: ${SPACE_INSET['$space-inset-10x']};
  `,
  _12x: css`
    padding: ${SPACE_INSET['$space-inset-12x']};
  `,
  _13x: css`
    padding: ${SPACE_INSET['$space-inset-13x']};
  `,
};

// Spacing only on the bottom of the element
export const stacked = {
  _xs: css`
    margin: ${SPACE_STACKED['$space-stack-xs']};
  `,
  _1x: css`
    margin: ${SPACE_STACKED['$space-stack-1x']};
  `,
  _2x: css`
    margin: ${SPACE_STACKED['$space-stack-2x']};
  `,
  _3x: css`
    margin: ${SPACE_STACKED['$space-stack-3x']};
  `,
  _4x: css`
    margin: ${SPACE_STACKED['$space-stack-4x']};
  `,
  _5x: css`
    margin: ${SPACE_STACKED['$space-stack-5x']};
  `,
  _6x: css`
    margin: ${SPACE_STACKED['$space-stack-6x']};
  `,
  _7x: css`
    margin: ${SPACE_STACKED['$space-stack-7x']};
  `,
  _8x: css`
    margin: ${SPACE_STACKED['$space-stack-8x']};
  `,
  _10x: css`
    margin: ${SPACE_STACKED['$space-stack-10x']};
  `,
  _12x: css`
    margin: ${SPACE_STACKED['$space-stack-12x']};
  `,
  _13x: css`
    margin: ${SPACE_STACKED['$space-stack-13x']};
  `,
};

// Spacing only on the right of the element
export const inline = {
  _xs: css`
    margin: ${SPACE_INLINE['$space-inline-xs']};
  `,
  _1x: css`
    margin: ${SPACE_INLINE['$space-inline-1x']};
  `,
  _2x: css`
    margin: ${SPACE_INLINE['$space-inline-2x']};
  `,
  _3x: css`
    margin: ${SPACE_INLINE['$space-inline-3x']};
  `,
  _4x: css`
    margin: ${SPACE_INLINE['$space-inline-4x']};
  `,
  _5x: css`
    margin: ${SPACE_INLINE['$space-inline-5x']};
  `,
  _6x: css`
    margin: ${SPACE_INLINE['$space-inline-6x']};
  `,
  _7x: css`
    margin: ${SPACE_INLINE['$space-inline-7x']};
  `,
  _8x: css`
    margin: ${SPACE_INLINE['$space-inline-8x']};
  `,
  _10x: css`
    margin: ${SPACE_INLINE['$space-inline-10x']};
  `,
  _12x: css`
    margin: ${SPACE_INLINE['$space-inline-12x']};
  `,
  _13x: css`
    margin: ${SPACE_INLINE['$space-inline-13x']};
  `,
};

export const padding = {
  top: {
    _xs: css`
      padding-top: ${spacexs};
    `,
    _1x: css`
      padding-top: ${space1x};
    `,
    _2x: css`
      padding-top: ${space2x};
    `,
    _3x: css`
      padding-top: ${space3x};
    `,
    _4x: css`
      padding-top: ${space4x};
    `,
    _5x: css`
      padding-top: ${space5x};
    `,
    _6x: css`
      padding-top: ${space6x};
    `,
    _7x: css`
      padding-top: ${space7x};
    `,
    _8x: css`
      padding-top: ${space8x};
    `,
    _10x: css`
      padding-top: ${space10x};
    `,
    _12x: css`
      padding-top: ${space12x};
    `,
    _13x: css`
      padding-top: ${space13x};
    `,
  },
  right: {
    _xs: css`
      padding-right: ${spacexs};
    `,
    _1x: css`
      padding-right: ${space1x};
    `,
    _2x: css`
      padding-right: ${space2x};
    `,
    _3x: css`
      padding-right: ${space3x};
    `,
    _4x: css`
      padding-right: ${space4x};
    `,
    _5x: css`
      padding-right: ${space5x};
    `,
    _6x: css`
      padding-right: ${space6x};
    `,
    _7x: css`
      padding-right: ${space7x};
    `,
    _8x: css`
      padding-right: ${space8x};
    `,
    _10x: css`
      padding-right: ${space10x};
    `,
    _12x: css`
      padding-right: ${space12x};
    `,
    _13x: css`
      padding-right: ${space13x};
    `,
  },
  bottom: {
    _xs: css`
      padding-bottom: ${spacexs};
    `,
    _1x: css`
      padding-bottom: ${space1x};
    `,
    _2x: css`
      padding-bottom: ${space2x};
    `,
    _3x: css`
      padding-bottom: ${space3x};
    `,
    _4x: css`
      padding-bottom: ${space4x};
    `,
    _5x: css`
      padding-bottom: ${space5x};
    `,
    _6x: css`
      padding-bottom: ${space6x};
    `,
    _7x: css`
      padding-bottom: ${space7x};
    `,
    _8x: css`
      padding-bottom: ${space8x};
    `,
    _10x: css`
      padding-bottom: ${space10x};
    `,
    _12x: css`
      padding-bottom: ${space12x};
    `,
    _13x: css`
      padding-bottom: ${space13x};
    `,
  },
  left: {
    _xs: css`
      padding-left: ${spacexs};
    `,
    _1x: css`
      padding-left: ${space1x};
    `,
    _2x: css`
      padding-left: ${space2x};
    `,
    _3x: css`
      padding-left: ${space3x};
    `,
    _4x: css`
      padding-left: ${space4x};
    `,
    _5x: css`
      padding-left: ${space5x};
    `,
    _6x: css`
      padding-left: ${space6x};
    `,
    _7x: css`
      padding-left: ${space7x};
    `,
    _8x: css`
      padding-left: ${space8x};
    `,
    _10x: css`
      padding-left: ${space10x};
    `,
    _12x: css`
      padding-left: ${space12x};
    `,
    _13x: css`
      padding-left: ${space13x};
    `,
  },
};

export const margin = {
  top: {
    _xs: css`
      margin-top: ${spacexs};
    `,
    _1x: css`
      margin-top: ${space1x};
    `,
    _2x: css`
      margin-top: ${space2x};
    `,
    _3x: css`
      margin-top: ${space3x};
    `,
    _4x: css`
      margin-top: ${space4x};
    `,
    _5x: css`
      margin-top: ${space5x};
    `,
    _6x: css`
      margin-top: ${space6x};
    `,
    _7x: css`
      margin-top: ${space7x};
    `,
    _8x: css`
      margin-top: ${space8x};
    `,
    _10x: css`
      margin-top: ${space10x};
    `,
    _12x: css`
      margin-top: ${space12x};
    `,
    _13x: css`
      margin-top: ${space13x};
    `,
  },
  right: {
    _xs: css`
      margin-right: ${spacexs};
    `,
    _1x: css`
      margin-right: ${space1x};
    `,
    _2x: css`
      margin-right: ${space2x};
    `,
    _3x: css`
      margin-right: ${space3x};
    `,
    _4x: css`
      margin-right: ${space4x};
    `,
    _5x: css`
      margin-right: ${space5x};
    `,
    _6x: css`
      margin-right: ${space6x};
    `,
    _7x: css`
      margin-right: ${space7x};
    `,
    _8x: css`
      margin-right: ${space8x};
    `,
    _10x: css`
      margin-right: ${space10x};
    `,
    _12x: css`
      margin-right: ${space12x};
    `,
    _13x: css`
      margin-right: ${space13x};
    `,
  },
  bottom: {
    _xs: css`
      margin-bottom: ${spacexs};
    `,
    _1x: css`
      margin-bottom: ${space1x};
    `,
    _2x: css`
      margin-bottom: ${space2x};
    `,
    _3x: css`
      margin-bottom: ${space3x};
    `,
    _4x: css`
      margin-bottom: ${space4x};
    `,
    _5x: css`
      margin-bottom: ${space5x};
    `,
    _6x: css`
      margin-bottom: ${space6x};
    `,
    _7x: css`
      margin-bottom: ${space7x};
    `,
    _8x: css`
      margin-bottom: ${space8x};
    `,
    _10x: css`
      margin-bottom: ${space10x};
    `,
    _12x: css`
      margin-bottom: ${space12x};
    `,
    _13x: css`
      margin-bottom: ${space13x};
    `,
  },
  left: {
    _xs: css`
      margin-left: ${spacexs};
    `,
    _1x: css`
      margin-left: ${space1x};
    `,
    _2x: css`
      margin-left: ${space2x};
    `,
    _3x: css`
      margin-left: ${space3x};
    `,
    _4x: css`
      margin-left: ${space4x};
    `,
    _5x: css`
      margin-left: ${space5x};
    `,
    _6x: css`
      margin-left: ${space6x};
    `,
    _7x: css`
      margin-left: ${space7x};
    `,
    _8x: css`
      margin-left: ${space8x};
    `,
    _10x: css`
      margin-left: ${space10x};
    `,
    _12x: css`
      margin-left: ${space12x};
    `,
    _13x: css`
      margin-left: ${space13x};
    `,
  },
};

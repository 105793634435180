// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as S from './styles';
import AppActions from 'app/shared/flux/actions/AppActions';
import Text from 'app/shared/core/Text';
import IconX from 'images/icons/x-white.svg';

class Notification extends React.Component {
  static propTypes = {
    notificationStyle: PropTypes.oneOf(['primary', 'alert', 'accent']),
    onTimeout: PropTypes.func,
    visibleTime: PropTypes.number,
  };

  static defaultProps = {
    notificationStyle: 'primary',
    onTimeout: () => {},
    visibleTime: 4000,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const { onTimeout, visibleTime } = this.props;
    this.timer = setTimeout(() => {
      this.hideNotification();
      onTimeout();
    }, visibleTime);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  hideNotification = () => {
    this.props.dispatch(AppActions.toggleNotificationMessage({ visible: false }));
  };

  render() {
    const { children, notificationStyle } = this.props;
    const message = children;

    return (
      <S.NotificationContainer key="Notification" notificationStyle={notificationStyle}>
        <S.NotificationContent>
          <Text size="sm">{message}</Text>
          <S.NotificaionClose alt="close message" onClick={() => this.hideNotification()}>
            <S.NotificationCloseSvg src={IconX} />
          </S.NotificaionClose>
        </S.NotificationContent>
      </S.NotificationContainer>
    );
  }
}

export default connect()(Notification);

// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import constants from 'app/shared/constants/ConstantsBundle';
import merge from 'lodash/merge';
import reduxUtils from 'app/shared/utils/reduxUtils';

import GeoJson from 'app/shared/models/GeoJson';

const initState = () => ({
  area: {},
  within: {},
  facts: {
    listingCounts: null,
  },
  factsByBeds: {},
  articles: [],
  staticMapPlaceholders: {},
});

const mapActionsToReducer = {
  [constants.CLEAR_CURRENT_AREA]: () => {
    return assign({}, initState(), {
      area: {},
      geoJson: new GeoJson(),
    });
  },
  [constants.LOAD_AREA_SUCCESS]: (state, action) => {
    const geoJson = new GeoJson();
    const newArea = action.payload.area;

    // Reset the state with new area, preserve geoip and facts and factsByBeds.
    return assign({}, initState(), {
      area: assign({}, newArea),
      articles: [].concat(state.articles),
      facts: assign({}, state.facts),
      factsByBeds: assign({}, state.factsByBeds),
      geoJson: assign({}, geoJson),
      staticMapPlaceholders: assign({}, state.staticMapPlaceholders),
      priceHistogram: assign({}, state.priceHistogram),
      ratingsAndReviews: assign({}, state.ratingsAndReviews),
      totalListings: state.totalListings,
    });
  },
  [constants.LOAD_WITHIN_AREAS]: (state, action) => {
    const { within } = action.payload;

    return assign({}, state, {
      within: merge({}, state.within, within), // ...... merge? ****** when do we clear?
    });
  },
  [constants.LOAD_FACTS]: (state, action) => {
    const { facts } = action.payload;

    return assign({}, state, {
      facts: assign({}, facts),
    });
  },
  [constants.LOAD_FACTS_BY_BEDS]: (state, action) => {
    const { factsByBeds } = action.payload;

    return assign({}, state, {
      factsByBeds: assign({}, factsByBeds),
    });
  },
  [constants.LOAD_ARTICLES]: (state, action) => {
    const { articles } = action.payload;

    return assign({}, state, {
      articles: [].concat(articles),
    });
  },
  [constants.LOAD_BLOG_POSTS]: (state, action) => {
    const { blogPosts } = action.payload;

    return assign({}, state, {
      blogPosts: assign({}, blogPosts),
    });
  },
  [constants.FETCH_AREA_MIN_MAX_PRICE]: (state, action) => {
    return assign({}, state, {
      areaMinPrice: action.payload.areaMinPrice || null,
      areaMaxPrice: action.payload.areaMaxPrice || null,
    });
  },
  [constants.LOAD_AREA_PRICE_HISTOGRAM]: (state, action) => {
    const { priceHistogram } = action.payload;

    return assign({}, state, {
      priceHistogram: assign({}, priceHistogram),
    });
  },
  [constants.LOAD_HTML_SITEMAP]: (state, action) => {
    const { sitemap } = action.payload;
    return assign({}, state, {
      sitemap: assign({}, sitemap),
    });
  },
  [constants.SAVE_STATIC_MAP_PLACEHOLDERS]: (state, action) => {
    return assign({}, state, {
      staticMapPlaceholders: assign({}, action.payload),
    });
  },
};

const area = reduxUtils.createReducer(mapActionsToReducer, initState());

export default area;

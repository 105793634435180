const AreaBoundaryCache = new Map();

interface GeoJson {
  id: number;
}

const cacheActions = {
  add(geoJson: GeoJson = {} as GeoJson) {
    const { id: areaId } = geoJson;

    if (!AreaBoundaryCache.get(areaId)) {
      AreaBoundaryCache.set(areaId, geoJson);
    }
  },

  get(areaId: number) {
    return AreaBoundaryCache.get(areaId);
  },

  size() {
    return AreaBoundaryCache.size;
  },
};

export default cacheActions;

import { css } from 'styled-components';

export const fontSize: { [key: string]: string } = {
  '$font-size-tiny': '12px',
  '$line-height-tiny': '16px',

  '$font-size-sm': '14px',
  '$line-height-sm': '20px',

  '$font-size-md': '16px',
  '$line-height-md': '24px',

  '$font-size-lg': '20px',
  '$line-height-lg': '24px',

  '$font-size-xl': '24px',
  '$line-height-xl': '28px',

  '$font-size-hero': '40px',
  '$line-height-hero': '48px',
};

const tinyText = `
    font-size: ${fontSize['$font-size-tiny']};
    line-height: ${fontSize['$line-height-tiny']};
`;

const smText = `
    font-size: ${fontSize['$font-size-sm']};
    line-height: ${fontSize['$line-height-sm']};
`;

const mdText = `
    font-size: ${fontSize['$font-size-md']};
    line-height: ${fontSize['$line-height-md']};
`;

const lgText = `
    font-size: ${fontSize['$font-size-lg']};
    line-height: ${fontSize['$line-height-lg']};
`;

const xlText = `
    font-size: ${fontSize['$font-size-xl']};
    line-height: ${fontSize['$line-height-xl']};
`;

const heroText = `
    font-size: ${fontSize['$font-size-hero']};
    line-height: ${fontSize['$line-height-hero']};
`;

export const font = {
  tiny: css`
    ${tinyText}
  `,
  sm: css`
    ${smText}
  `,
  md: css`
    ${mdText}
  `,
  lg: css`
    ${lgText}
  `,
  xl: css`
    ${xlText}
  `,
  hero: css`
    ${heroText}
  `,
};

export const navbar = {
  'nav-btn-width': '70px',
  'global-nav-bar-height': '48px',
  'filter-nav-bar-height': '48px',
  'hdp-nav-bar-height-md': '42px',
  'hdp-nav-bar-height-xl': '48px',
};

export const variables = {
  'sidebar-width': '100%',
  'sidebar-width-md': '400px',
  'sidebar-width-md-value-only': 400,
  'sidebar-width-lg': '635px',
  'sidebar-width-xl': '635px',
  'sidebar-width-xl-value-only': 635,

  'sidebar-width-md-bigger-listing-cards': '452px', // HPWEB-6082: Width of sidebar for new listing card experiment.
  'sidebar-width-xl-bigger-listing-cards': '880px', // HPWEB-6082: Width of sidebar for new listing card experiment.

  'contact-button-width-sm': 280,
  'contact-button-width-md': 360,
  'contact-button-width-xl': 420,
};

export const listingPreview = {
  'listing-preview-height': '200px',
};

export const headerHeights = {
  'mobile-header-filter-height': '90px',
  'desktop-header-filter-height': '104px',

  'mobile-header-height': '42px',
  'desktop-header-height': '56px',
};

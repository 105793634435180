// Auto generated file, do not manually modify!
// com.hotpads.marlin.web.MarlinJsRefererMetricsCollectionHandler

import { getClient } from '../../client';
import { httpApiRequest } from '@zg-rentals/ts-client-http-client';

import type {
  MarlinJsCountBatchRequestDto,
  MarlinJsGaugeBatchRequestDto,
  MarlinJsMeasurementBatchRequestDto,
  PublishingResponseDto,
} from '../..';

export const MarlinJsRefererMetricsCollectionHandler_addCounts_Path = '/api/referer/collect/addCounts';

export const MarlinJsRefererMetricsCollectionHandler_addCounts = (
  postData: MarlinJsCountBatchRequestDto,
  client = getClient(),
) => {
  return httpApiRequest<never, MarlinJsCountBatchRequestDto, PublishingResponseDto>(
    {
      endpoint: MarlinJsRefererMetricsCollectionHandler_addCounts_Path,
      method: 'POST',
      contextPath: '/marlin',
      postData: postData,
    },
    client,
  );
};

export const MarlinJsRefererMetricsCollectionHandler_addGauges_Path = '/api/referer/collect/addGauges';

export const MarlinJsRefererMetricsCollectionHandler_addGauges = (
  postData: MarlinJsGaugeBatchRequestDto,
  client = getClient(),
) => {
  return httpApiRequest<never, MarlinJsGaugeBatchRequestDto, PublishingResponseDto>(
    {
      endpoint: MarlinJsRefererMetricsCollectionHandler_addGauges_Path,
      method: 'POST',
      contextPath: '/marlin',
      postData: postData,
    },
    client,
  );
};

export const MarlinJsRefererMetricsCollectionHandler_addMeasurements_Path = '/api/referer/collect/addMeasurements';

export const MarlinJsRefererMetricsCollectionHandler_addMeasurements = (
  postData: MarlinJsMeasurementBatchRequestDto,
  client = getClient(),
) => {
  return httpApiRequest<never, MarlinJsMeasurementBatchRequestDto, PublishingResponseDto>(
    {
      endpoint: MarlinJsRefererMetricsCollectionHandler_addMeasurements_Path,
      method: 'POST',
      contextPath: '/marlin',
      postData: postData,
    },
    client,
  );
};

/*
    Ideally, this function should live inside areaUtils.js,
    but since we import areaUtils.js into the Area.js model,
    we would end up having a circular dependency.
*/

import isArray from 'lodash/isArray';
import type { AreaResponse } from 'app/shared/models/Area';
import Area from 'app/shared/models/Area';

const processAreasResponse = (apiResults: Array<AreaResponse> = []) => {
  if (isArray(apiResults)) {
    return apiResults.map((area) => {
      return new Area(area);
    });
  } else {
    return [];
  }
};

export default processAreasResponse;

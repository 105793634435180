const SeoConstants = {
  allowedUrlParams: [
    // filter
    'amenities',
    'applicationsOk',
    'baths',
    'beds',
    'includeVaguePricing',
    'incomeRestricted',
    'keywords',
    'laundry',
    'listingTypes',
    'maxCreated',
    'militaryHousing',
    'pets',
    'photos',
    'price',
    'promo',
    'propertyTypes',
    'seniorHousing',
    'sqft',
    'studentHousing',

    // sorting
    'orderBy',

    // pagination
    'page',
  ],
};

export default SeoConstants;

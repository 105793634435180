// @ts-nocheck
/* eslint-enable */
/**
 * Google Analytics analytics.js library wrapper
 * developers.google.com/analytics/devguides/collection/analyticsjs/
 * Adapted from github.com/react-ga/react-ga
 */
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import isNumber from 'lodash/isNumber';
import { getGlobalLogger } from '@zg-rentals/logger-browser';

const logger = getGlobalLogger('universalanalytics/ga');

/* eslint-disable camelcase */

// TODO: Use lodash instead of typeof and Object.

const boolAsString = (bool) => {
  return bool ? 'true' : 'false';
};

const windowGa = (...args) => {
  if (typeof window === 'undefined') {
    return false;
  }

  if (!window.ga) {
    logger.warn('window.ga was not defined. Call initialize?');
    return;
  }
  return window.ga(...args);
};

function gaCommand(...args) {
  if (typeof window === 'undefined') {
    return false;
  }

  const command = args[0];
  if (!isString(command)) {
    logger.warn('ga command must be a string');
    return;
  }

  // Note: Boolean values in localStorage stored as strings.
  if (
    !__DEV__ ||
    (__DEV__ && window.debugAnalytics) ||
    (__DEV__ && localStorage.getItem('debugAnalytics') === 'true')
  ) {
    windowGa(...args);
  }
}

/**
 * Returns the original GA object.
 */
export function ga(...args) {
  if (typeof window === 'undefined') {
    return false;
  }

  if (args.length > 0) {
    windowGa(...args);
  }

  return window.ga;
}

/**
 * Sets a field/value pair or a group of field/value pairs on the tracker.
 * @see developers.google.com/analytics/devguides/collection/analyticsjs/tracker-object-reference#set
 * @param {Object} fieldsObject - a field/value pair or a group of field/value pairs on the tracker
 */
export function set(fieldsObject) {
  if (!isObject(fieldsObject) || isEmpty(fieldsObject)) {
    logger.warn('set():  `fieldsObject` is required and expected be an Object');
    return;
  }

  // Note: Dimension properties passed to Google Analytics must be a string.
  Object.keys(fieldsObject).forEach((key) => {
    if (typeof fieldsObject[key] === 'boolean') {
      fieldsObject[key] = boolAsString(fieldsObject[key]);
    }
  });

  // Filter out value types other than strings or numbers
  fieldsObject = pickBy(fieldsObject, (value) => {
    return isString(value) || isNumber(value);
  });

  gaCommand('set', fieldsObject);
}

/**
 * Clone of the low level `ga.send` method
 * @see developers.google.com/analytics/devguides/collection/analyticsjs/tracker-object-reference#send
 * WARNING: No validations will be applied to this
 * @param  {Object} fieldObject - field object for tracking different analytics
 */
export function send(fieldObject) {
  gaCommand('send', fieldObject);
}

/**
 * Basic GA pageview tracking
 * @param  {String} path - the current page page e.g. '/about'
 * @param {String} title - (optional) the page title e. g. 'My Website'
 */
export function pageview(path, title) {
  if (!path || path === '') {
    logger.warn('pageview(): path is required and cannot be an empty string');
    return;
  }

  const extraFields = {};
  if (title) {
    extraFields.title = title;
  }

  if (typeof ga === 'function') {
    gaCommand('send', {
      hitType: 'pageview',
      page: path,
      ...extraFields,
    });
  }
}

/**
 * Event Tracking
 * developers.google.com/analytics/devguides/collection/analyticsjs/events
 * @param args.category {String} required
 * @param args.action {String} required
 * @param args.label {String} optional
 * @param args.value {Int} optional
 * @param args.nonInteraction {boolean} optional
 * @param args.transport {string} optional
 */
export function event({ category, action, label, value, nonInteraction, transport, ...args } = {}) {
  if (typeof ga === 'function') {
    if (!category || !action) {
      logger.warn('args.category AND args.action are required in event()');
      return;
    }

    const fieldObject = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
    };

    if (label) {
      fieldObject.eventLabel = label;
    }

    if (typeof value !== 'undefined') {
      if (typeof value !== 'number') {
        logger.warn('Expected `args.value` arg to be a Number.');
      }
      // TODO: For now allow it because some events in hotpads-web use a string here
      fieldObject.eventValue = value;
    }

    if (typeof nonInteraction !== 'undefined') {
      if (typeof nonInteraction !== 'boolean') {
        logger.warn('`args.nonInteraction` must be a boolean.');
      } else {
        fieldObject.nonInteraction = nonInteraction;
      }
    }

    if (typeof transport !== 'undefined') {
      if (typeof transport !== 'string') {
        logger.warn('`args.transport` must be a string.');
      } else {
        if (['beacon', 'xhr', 'image'].indexOf(transport) === -1) {
          logger.warn('`args.transport` must be either one of these values: `beacon`, `xhr` or `image`');
        }

        fieldObject.transport = transport;
      }
    }

    Object.keys(args)
      .filter((key) => key.substr(0, 'dimension'.length) === 'dimension')
      .forEach((key) => {
        fieldObject[key] = args[key];
      });

    Object.keys(args)
      .filter((key) => key.substr(0, 'metric'.length) === 'metric')
      .forEach((key) => {
        fieldObject[key] = args[key];
      });

    send(fieldObject);
  }
}

export default {
  ga,
  set,
  send,
  pageview,
  event,
};

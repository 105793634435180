import { isTest } from '@zg-rentals/environment-utils';

type AppInfo = {
  appName: string;
  buildNumber: number;
  appStartTimeMs?: number;
  buildMs?: number;
  gitCommit?: string;
  gitBranch?: string;
};

let _appInfo: AppInfo;

export function getAppInfo() {
  if (!_appInfo) {
    if (!isTest()) {
      // eslint-disable-next-line no-console
      console.error(
        '--------------------------------------------------------------------------------\n' +
          'app-info: app info not initialized!. You should add a bootstrap to your app\n' +
          '--------------------------------------------------------------------------------',
      );
    }
    _appInfo = {
      appName: 'unknown',
      buildNumber: -1,
    };
  }
  return _appInfo;
}
export function setAppInfo(appInfo: AppInfo) {
  _appInfo = appInfo;
}

// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';
import { padding } from 'app/shared/styles/_spacing';

export const NotificationContainer = styled.div`
  ${padding.top._1x}
  ${padding.bottom._1x}
    position: fixed;
  color: ${colors['$hpx-white']};
  font-weight: bold;
  text-align: left;
  top: 0px;
  z-index: ${zindex['$z-index-max']};
  width: 100vw;
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  @media ${viewports['md-and-up']} {
    width: 420px;
    margin-left: -210px;
    left: 50%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  ${(props) =>
    props.notificationStyle &&
    props.notificationStyle === 'primary' &&
    `
        background: ${colors['$hpx-teal-500']};
    `}

  ${(props) =>
    props.notificationStyle &&
    props.notificationStyle === 'alert' &&
    `
        background: ${colors['$hpx-red-500']};
    `}

    ${(props) =>
    props.notificationStyle &&
    props.notificationStyle === 'accent' &&
    `
        background: ${colors['$hpx-grey-600']};
    `}
`;

export const NotificationContent = styled.div`
  position: relative;
  padding: 0px 44px;
  line-height: 24px;
`;

export const NotificaionClose = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -8px;
  padding: 6px 16px 10px 8px;
`;

export const NotificationCloseSvg = styled.img`
  height: 16px;
  width: 16px;
`;

/**
 * Create a _module instance_ of type `T`. A module instance is effectively a
 * singleton (with respect to module scope). In other words, an application that
 * get the module instance and imports a different package that gets the
 * same module instance, will only produce a single instance in memory. Mutations
 * to that instance from one package are observed in other packages.
 *
 * **Important**: Some Javascript apps implicitly have more than one module scope!
 *
 * - A Server-Side Rendered React application will almost certainly have one
 *  module scope on the client side, and one module scope on the server side
 * - A Next.js app likely has one module scope for the instrumentation entrypoint
 *  and another module scope for pages
 *
 * @param initialValue initialize the module instance
 * @returns a `get` function and `set` function
 */
export function makeModuleInstance<T>(initialValue: T) {
  let instance = initialValue;

  return {
    get: () => instance,

    set(newInstance: T) {
      instance = newInstance;
      return instance;
    },
  };
}

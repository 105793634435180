import get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';

import { filterUtils_hasActiveFilters } from 'app/shared/utils/filterUtils';

import phabletDetect from 'app/client/utils/phablet-detect';
import { listingHasTourUrls } from 'app/shared/utils/listingUtils';
import { setdim } from '@zillow/universal-analytics';
import type { Dimension } from 'app/types';

// TODO: "store" should be built out as a proper type based on our Redux store.

/**
 * @example: returns 'c'
 * propertyAsString({ a: {b: 'c'} }, 'a.b')
 */
function propertyAsString(object: string, path: string) {
  return toString(get(object, path));
}

const MAX_DIMENSIONS = 40;
const emptyDimensions: Dimension = {};
for (let index = 1; index <= MAX_DIMENSIONS; index++) {
  emptyDimensions[`dimension${index}`] = '';
}

export function setListingDimensions(store: any) {
  let listing = get(store, 'currentListingDetails.currentListing');

  if (isEmpty(listing)) {
    return;
  }

  if (listing.units) {
    listing = listing.units[0];
  }

  const {
    active,
    address = {},
    aliasEncoded,
    details = {},
    featuredPropertyProductId = 0,
    listedBy,
    listingType = '',
    maloneLotIdEncoded: maloneLotId,
    propertyType = '',
    ratingsAndReviews,
    rentalApplicationStatus,
    specialOffers,
  } = listing;

  const isPaid = toString(details.trusted);
  const listingActive = toString(active);
  const demoted = toString(details.demoted);
  const hasSpecialOffers = toString(specialOffers.length > 0);
  let listingRating, responseRateLevel, responseRate, responseTime, responseTimeLevel;
  const has3dTour = listingHasTourUrls(listing);
  if (ratingsAndReviews && ratingsAndReviews.reviews.length > 0 && ratingsAndReviews.reviewsSummary) {
    listingRating = toString(ratingsAndReviews.reviewsSummary.averageStarLevel);
  }
  if (isFinite(listedBy.responseRateLevel)) {
    responseRateLevel = toString(listedBy.responseRateLevel);
  }
  if (isFinite(listedBy.responseRate)) {
    responseRate = toString(Math.ceil(listedBy.responseRate));
  }
  if (isFinite(listedBy.responseTime)) {
    responseTime = toString(listedBy.responseTime / (60000 * 60)); // ms --> hrs
  }
  if (isFinite(listedBy.responseTimeLevel)) {
    responseTimeLevel = toString(listedBy.responseTimeLevel);
  }

  const dimensionsObject = {
    dimension1: 'true', // for-rent url GA dimension (typeof bool = string for GA)
    dimension2: 'false', // for-sale url GA dimension (typeof bool = string for GA)
    dimension3: listingType,
    dimension4: propertyType,
    dimension5: aliasEncoded,
    dimension6: isPaid,
    dimension7: listingActive,
    dimension8: maloneLotId,
    // TODO: Remove this if blank
    dimension12: address.neighborhood,
    dimension13: address.city,
    dimension14: address.state,
    dimension15: address.county,
    dimension16: address.zip,
    // If HDP is paid, send productId. If it's NOT paid, leave dimension18 BLANK
    dimension18: isPaid ? featuredPropertyProductId : '',
    dimension19: responseRateLevel,
    dimension20: responseRate,
    dimension21: responseTime,
    dimension22: responseTimeLevel,
    // Remove if not available
    dimension23: listingRating,
    dimension25: demoted,
    dimension27: rentalApplicationStatus,
    dimension32: hasSpecialOffers,
    dimension37: has3dTour,
  };

  setdim(dimensionsObject);
}

export function setBaseDimensions(store: any) {
  if (typeof window === 'undefined') {
    return;
  }

  const filter = get(store, 'filter');
  const homeHub = get(store, 'homehub.googleAnalyticsPageView');
  const hasActiveFilter = filterUtils_hasActiveFilters(filter) ? 'true' : 'false';

  const fullURL = window.location.href;
  const mql = window.matchMedia('(orientation: portrait)');
  const deviceOrientation = mql.matches ? 'portrait' : 'landscape';
  const { deviceType: device, customVariableSiteType: siteType } = phabletDetect(window.navigator.userAgent);
  const { user } = store;

  const { info: userInfo, loggedIn, userToken } = user;

  let signedIn;
  const isAdminOrRep = userInfo.roles.indexOf('admin') > -1 || userInfo.roles.indexOf('rep') > -1;
  const isUser = userInfo.roles.indexOf('user') > -1;

  if (userInfo.roles) {
    if (isAdminOrRep) {
      signedIn = 'admin/rep';
    } else if (isUser) {
      signedIn = 'user';
    }
  }

  if (!loggedIn) {
    signedIn = 'anonymous';
  }

  const dimensionsObject = {
    ...emptyDimensions,
    dimension9: siteType,
    dimension10: device,
    dimension11: deviceOrientation,
    dimension17: 'false',
    dimension29: fullURL,
    dimension30: signedIn,
    dimension31: userToken,
    dimension34: hasActiveFilter,
    dimension35: homeHub,
  };

  setdim(dimensionsObject);
}

export function setAreaDimensions(store: any) {
  if (isEmpty(store.area.area)) {
    return;
  }

  const searchExperience = propertyAsString(store, 'view.searchExperience');
  const ratingsAndReviews = propertyAsString(store, 'area.ratingsAndReviews');
  const totalListings = propertyAsString(store, 'listings.totalListings');

  const { neighborhood, city, state, county, zip } = store.area.area;

  // TODO: This is null
  const areaRating = propertyAsString(ratingsAndReviews, 'reviewsSummary.totalNumberOfReviews');

  const dimensionsObject = {
    dimension1: 'true', // for-rent url GA dimension (typeof bool = string for GA)
    dimension2: 'false', // for-sale url GA dimension (typeof bool = string for GA)
    dimension12: neighborhood,
    dimension13: city,
    dimension14: state,
    dimension15: county,
    dimension16: zip,
    dimension17: 'true',
    dimension24: totalListings,
    // Leave out if missing
    dimension26: areaRating,
    // This gets carried over to HDP or whereever
    dimension28: searchExperience,
  };

  setdim(dimensionsObject);
}

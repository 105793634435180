// @ts-nocheck
/* eslint-enable */
import LRU from 'app/shared/cache/lruCache';
import genericUtils from 'app/shared/utils/genericUtils';

interface DotData {
  [key: string]: any;
}

const dotMapCache = new LRU(5);

const cacheActions = {
  add(query: string = '', dotData: DotData = {}): void {
    const hashKey = genericUtils.hash(query);

    if (!dotMapCache.read(hashKey)) {
      dotMapCache.write(hashKey, dotData);
    }
  },

  get(query: string): DotData | undefined {
    const hashKey = genericUtils.hash(query);

    return dotMapCache.read(hashKey) as DotData | undefined;
  },

  size(): number {
    return dotMapCache.size;
  },

  clear(): void {
    return dotMapCache.clear();
  },
};

export default cacheActions;

// @ts-nocheck
/* eslint-enable */
import queryUtils from 'app/shared/utils/queryUtils';
import RequesterActions from 'app/shared/flux/actions/RequesterActions';
import { logError } from '@zg-rentals/log-error';

// an axiosError, server responded with non-2xx status code
function isApiResponseError(err) {
  return Boolean(err.response);
}

// an axiosError, request was made but no response received
function isApiRequestError(err) {
  return Boolean(err.request);
}

const ErrorActions = {
  errorHandler({ error, errorClass, errorLocation, req, criticalType = null }) {
    return (dispatch, getState) => {
      const state = getState();
      const traceId = __SERVER__ ? state.location.ssrEntry.traceId : null;
      const { response, message, config } = error;

      if (isApiResponseError(error)) {
        const { status, headers } = response;

        let xEidResponseHeaders;

        if (__SERVER__ && headers && headers['x-eid']) {
          xEidResponseHeaders = headers['x-eid'];
          dispatch(RequesterActions.pushxEid(xEidResponseHeaders));
        }

        logError({
          error,
          type: 'ApiError',
          context: {
            details: 'server responded with status code out of 2xx range',
            errorLocation,
            errorClass,
            traceId,
            url: config.url,
            params: config.params,
            fullUrl: `${config.url}${queryUtils.stringify(config.params)}`,
            criticalType,
            status,
            message,
          },
        });
      } else if (isApiRequestError(error)) {
        // todo: we can use pontoon counters instead for req related failures
        if (message && message.toLowerCase() !== 'request aborted') {
          logError({
            error,
            errorType: 'ApiError_RequestAborted',
            context: {
              details: 'request made, no response received',
              errorLocation,
              errorClass,
              traceId,
              url: config.url,
              params: config.params,
              fullUrl: `${config.url}${queryUtils.stringify(config.params)}`,
              criticalType,
              message,
            },
          });
        }
      } else {
        logError({
          error,
          errorType: 'ApiError',
          context: {
            errorLocation,
            errorClass,
            message,
          },
        });
      }
    };
  },
};

export default ErrorActions;

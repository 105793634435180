import reduce from 'lodash/reduce';

type Params = { [key: string]: any };

const sanitizingUtils_sanitizeUndefinedAndNullValues = <T extends Params>(javaParams: T): Partial<T> => {
  return reduce(
    javaParams,
    (result, val, key) => {
      if (val !== undefined && val !== null) {
        // @ts-expect-error Unsure of the types here.
        (result as T & Params)[key] = val;
      }
      return result;
    },
    {} as Partial<T>,
  );
};

export { sanitizingUtils_sanitizeUndefinedAndNullValues };

import get from 'lodash/get';

const isMethodAvailable = (methodName: string): boolean => {
  return typeof window !== 'undefined' && get(window, ['performance', methodName], false) !== false;
};

// window.performance shim to ensure we only call perf api methods on supported browsers
// add additional performance API methods as needed using above helper to determine method's existence in browser
const performanceShim = {
  mark: (...methodArgs: Array<any>): void => {
    if (isMethodAvailable('mark')) {
      // eslint-disable-next-line no-unused-vars
      (window.performance.mark as (...args: Array<any>) => void).apply(window.performance, methodArgs);
    }
  },
};

export default performanceShim;

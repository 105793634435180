// @ts-nocheck
/* eslint-enable */
const stringHelper = {
  currentYear() {
    return new Date().getFullYear();
  },
  pluralize(num, singular, plural) {
    return num + ' ' + (num === 1 ? singular : plural);
  },
  getUrlQueryParams() {
    const query = window.location.search.substring(1);

    if (!query) {
      return false;
    }
    const queryString = {};
    const vars = query.split('&');
    let i;
    let pair;

    for (i = 0; i < vars.length; i++) {
      pair = vars[i].split('=');
      if (typeof queryString[pair[0]] === 'undefined') {
        queryString[pair[0]] = decodeURIComponent(pair[1]);
      } else if (typeof queryString[pair[0]] === 'string') {
        queryString[pair[0]] = [queryString[decodeURIComponent(pair[0])], decodeURIComponent(pair[1])];
      } else {
        queryString[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }
    return queryString;
  },
  getUrlPathname(url) {
    const urlParts = url.replace('http://', '').replace('https://', '').split(/[/?#]/);
    return urlParts[0];
  },
  getCookieByName(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  },
  titleCase(string = '') {
    return string
      .toLowerCase()
      .split('')
      .map((char, i) => {
        if (i === 0) {
          return char.toUpperCase();
        }

        return char;
      })
      .join('');
  },
};

export default stringHelper;

// @ts-nocheck
/* eslint-enable */
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import omit from 'lodash/omit';
import includes from 'lodash/includes';

const LinkController = forwardRef((props, ref) => {
  const { rel, to = '', target, escape, removeNoOpener = false, removeNoReferrer = false } = props;
  const isFullUrl = to && includes(to, '://'); // handles tel://, http://, https:// etc
  const isMailTo = to && includes(to, 'mailto:'); // handles email links
  const isSMSTo = to && includes(to, 'sms:'); // handles sms links
  const isHashUrl = to && to[0] === '#';
  const isKnownExternal =
    to.split('/')[1] === 'rental-manager' ||
    to.split('/')[1] === 'listing-tools' ||
    to.split('/')[1] === 'renter-hub' ||
    to.split('/')[1] === 'sales' ||
    to.split('/')[1] === 'datarouter' ||
    to.split('/')[1] === 'admin' ||
    to.split('/')[1] === 'rep';
  const useAnchor = isFullUrl || isHashUrl || isMailTo || isSMSTo || isKnownExternal || target || escape;

  const relArray = [];

  if (!removeNoOpener) {
    relArray.push('noopener');
  }

  if (!removeNoReferrer) {
    relArray.push('noreferrer');
  }

  if (rel === 'nofollow') {
    relArray.push('nofollow');
  }

  const rest = omit(props, ['dispatch', 'escape', 'linkType', 'disabled', 'removeNoOpener', 'removeNoReferrer']);

  if (!to) {
    return <a {...rest} ref={ref} />;
  } else if (useAnchor) {
    return <a {...rest} ref={ref} href={to} rel={relArray.join(' ')} />;
  } else {
    return <Link {...rest} ref={ref} to={to} />;
  }
});

export default LinkController;

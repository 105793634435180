// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import merge from 'lodash/merge';

import constants from 'app/shared/constants/ConstantsBundle';
import CrimeScoreCollection from 'app/shared/models/CrimeScoreCollection';
import InstantTourForm from 'app/shared/models/InstantTourForm';
import AvailableToursForListing from 'app/shared/models/AvailableToursForListing';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import reduxUtils from 'app/shared/utils/reduxUtils';
import { updateUserItemTypes } from 'app/shared/utils/listingsReducerUtils';

const initState = () => ({
  activeMarkerMaloneLotId: null,
  crimeScores: new CrimeScoreCollection(),
  userCanReplyToReviews: false,

  // used to keep track of HDPs position in list view
  indexInList: null,

  // this current listing is outside the scope of the current list. e.g. hdp -> related listing (not in current byCoords list)
  existsOutsideOfList: false,

  // store the last listing in the list so we can go back to it.
  // this is necessary, since the list will change once we do a byCoords call (visit an HDP outside of our current list)
  lastVisitedListingFromListUri: '',

  currentListing: null,

  // Keep track of a user's progress in instant touring for a listing.
  instantTourForm: new InstantTourForm(),

  // Store the available tour dates for a listing.
  availableToursForListing: new AvailableToursForListing(),
});

const mapActionsToReducer = {
  [constants.LOAD_LISTING_CRIME_SCORES]: (state, action) => {
    const { crimeScores } = action.payload;

    return assign({}, state, { crimeScores });
  },
  [constants.SET_CURRENT_LISTING_INDEX]: (state, action) => {
    const index = action.payload;

    return assign({}, state, { indexInList: index });
  },
  [constants.SET_USER_REVIEW_REPLY_PERMISSION_BOOL]: (state, action) => {
    return assign({}, state, {
      userCanReplyToReviews: action.payload,
    });
  },
  [constants.SET_CURRENT_LISTING_OUTSIDE_OF_LIST]: (state, action) => {
    return assign({}, state, {
      existsOutsideOfList: action.payload,
    });
  },
  [constants.SET_LAST_VISITED_LISTING_FROM_LIST]: (state, action) => {
    return assign({}, state, {
      lastVisitedListingFromListUri: action.payload,
    });
  },
  [constants.CLEAR_CURRENT_LISTING]: (state) => {
    return assign({}, state, {
      currentListing: null,
    });
  },
  [constants.SET_CURRENT_LISTING]: (state, action) => {
    const currentListing = action.currentListing;
    return assign({}, state, {
      activeMarkerMaloneLotId:
        currentListing && currentListing.maloneLotIdEncoded
          ? currentListing.maloneLotIdEncoded
          : state.activeMarkerMaloneLotId,
      currentListing,
    });
  },
  [constants.SET_ACTIVE_MARKER_MALONE_LOT_ID]: (state, action) => {
    return assign({}, state, {
      activeMarkerMaloneLotId: action.activeMarkerMaloneLotId,
    });
  },
  [constants.FETCH_LISTING_UPDATE]: (state, action) => {
    const listingUpdates = action.payload.listingUpdates;
    let currentListing = {};

    if (listingUpdates.aliasEncoded === state.currentListing.aliasEncoded) {
      currentListing = assign({}, state.currentListing);
      if (listingUpdates.contact) {
        currentListing.contact = listingUpdates.contact;
      }
      if (listingUpdates.popularity) {
        currentListing.popularity = listingUpdates.popularity;
      }
      if (listingUpdates.listedBy) {
        currentListing.listedBy = listingUpdates.listedBy;
      }
    }

    return assign({}, state, {
      currentListing,
    });
  },
  // TODO: add unit tests for hp tour reducers.
  [constants.UPDATE_INSTANT_TOUR_FORM]: (state, action) => {
    const currentInstantTourForm = assign({}, state.instantTourForm);
    const updatedInstantTourFormFields = action.payload;
    const updatedInstantTourForm = { ...currentInstantTourForm, ...updatedInstantTourFormFields };

    return assign({}, state, {
      instantTourForm: updatedInstantTourForm,
    });
  },
  [constants.CLEAR_INSTANT_TOUR_FORM]: (state) => {
    return assign({}, state, {
      instantTourForm: new InstantTourForm(),
    });
  },
  [constants.UPDATE_AVAILABLE_TOURS_FOR_LISTING]: (state, action) => {
    const {
      availableDatesWithOrWithoutTimes = [],
      dateOnly = false,
      scheduledDateWithOrWithoutTime = '',
    } = action.payload;

    return assign({}, state, {
      availableToursForListing: new AvailableToursForListing({
        availableDatesWithOrWithoutTimes,
        dateOnly,
        scheduledDateWithOrWithoutTime,
      }),
    });
  },
  [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (state, action) => {
    const listing = action.payload.listing;
    let { currentListing } = state;

    if (!listing || !listing.maloneLotIdEncoded || !listing.geo) {
      return state;
    }

    const type = action.payload.type; // favorite, viewed, hidden, inquiry, etc.
    const addOrRemove = action.payload.action; // add, remove
    // Update locally in currentListing
    if (currentListing && listing.maloneLotIdEncoded === currentListing.maloneLotIdEncoded) {
      currentListing = merge({}, currentListing); // must clone before mutating!
      currentListing.userItemTypes = updateUserItemTypes(currentListing.userItemTypes, addOrRemove, type);

      if (listing.belongsToMultipleUnitBuilding) {
        let matchingUnitInCurrentListing = find(currentListing.units, (unit) => {
          return unit.aliasEncoded === listing.aliasEncoded;
        });

        if (matchingUnitInCurrentListing) {
          matchingUnitInCurrentListing = merge({}, matchingUnitInCurrentListing);
          matchingUnitInCurrentListing.userItemTypes = updateUserItemTypes(
            matchingUnitInCurrentListing.userItemTypes,
            addOrRemove,
            type,
          );

          const indexOfMatchingUnit = findIndex(currentListing.units, (unit) => {
            return unit.aliasEncoded === listing.aliasEncoded;
          });
          currentListing.units.splice(indexOfMatchingUnit, 1, matchingUnitInCurrentListing);
        }
      }

      return assign({}, state, {
        currentListing,
      });
    } else {
      return state;
    }
  },
};

const currentListingDetails = reduxUtils.createReducer(mapActionsToReducer, initState());

export default currentListingDetails;

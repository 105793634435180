// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';

export const queryUtilsSanitize = (obj = {}) => {
  return import(/* webpackChunkName: "sanitizer" */ 'sanitizer').then(({ default: sanitizer }) => {
    const newQueryObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        const queryValue = obj[key];

        newQueryObj[encodeURIComponent(key)] = encodeURIComponent(sanitizer.sanitize(queryValue));
      }
    }

    return newQueryObj;
  });
};

const queryUtils = {
  isEncoded(uri) {
    uri = uri || '';

    try {
      decodeURIComponent(uri);
      return uri !== decodeURIComponent(uri);
    } catch (e) {
      if (e instanceof URIError) {
        return false;
      }
    }
  },
  fullyDecodeURI(uri) {
    while (queryUtils.isEncoded(uri)) {
      try {
        uri = decodeURIComponent(uri);
      } catch (e) {
        if (e instanceof URIError) {
          uri = encodeURI(uri);
        }
      }
    }
    return uri;
  },
  stringify(obj = {}, opts = {}) {
    const str = [];

    if (opts.includePageParam) {
      obj.page = obj.page ?? 1;
    }

    forEach(obj, (v, k) => {
      if (v || v === false) {
        k = queryUtils.fullyDecodeURI(k);
        v = queryUtils.fullyDecodeURI(v);
        str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    });

    if (str.length > 0) {
      return '?' + str.sort().join('&');
    } else {
      return '';
    }
  },
  parse(string = '') {
    if (typeof string !== 'string' || string === '') {
      return {};
    }
    if (string.charAt(0) === '?') {
      string = string.substr(1);
    }
    const queryObj = {};
    const pieces = string.split('&');
    pieces.forEach((piece) => {
      const splitPiece = piece.split('=');
      const name = splitPiece[0];
      let value = splitPiece[1];
      if (value === 'true') {
        value = true;
      }
      if (value === 'false') {
        value = false;
      }
      queryObj[name] = value;
    });

    return queryObj;
  },
  removeQueryString(url = '') {
    return url.split('?')[0];
  },
  removeNonMapQueries(url = '') {
    const pathquery = url.split('?');
    const mapQueries = [];
    const queries = pathquery[1] ? pathquery[1].split('&') : [];
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i].toLowerCase();
      if (query.includes('border') || query.includes('lat') || query.includes('lon') || query.includes('z')) {
        mapQueries.push(query);
      }
    }

    if (mapQueries.length > 0) {
      return pathquery[0] + '?' + mapQueries.join('&');
    } else {
      return pathquery[0];
    }
  },
  combineMapQueryParams(urlWithMap, url) {
    if (!urlWithMap) {
      return url;
    }
    const pathquery = url.split('?');
    const filterQuery = pathquery[1] ? pathquery[1] : '';
    if (urlWithMap.includes('?') && filterQuery) {
      return urlWithMap + '&' + filterQuery;
    } else {
      return urlWithMap + (filterQuery ? '?' + filterQuery : '');
    }
  },
  addOrUpdateUrlParam({ url, name, value }) {
    // adapted from https://stackoverflow.com/a/6899293/7669090
    const href = url || window.location.href;
    let result;
    let regex = new RegExp('[&\\?]' + name + '=');
    if (regex.test(href)) {
      regex = new RegExp('([&\\?])' + name + '=\\d+');
      result = href.replace(regex, '$1' + name + '=' + value);
    } else {
      if (href.indexOf('?') > -1) {
        result = href + '&' + name + '=' + value;
      } else {
        result = href + '?' + name + '=' + value;
      }
    }
    return result;
  },
  isMapBorderActive(queryObj = {}) {
    /**
     * If the map border is INACTIVE, the "border" query param is explicitly
     * set to `false`. The "border" query param being sent to `null` or `undefined`
     * have no effect on the map border being present or not.
     */
    const mapHasBorder = queryObj.border !== false;

    return mapHasBorder;
  },
};

export default queryUtils;

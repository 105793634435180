// @ts-nocheck
/* eslint-enable */
/**
 * Adapated from Uday Vunnam's article regarding LRU cache.
 *
 * Originally, a standard JS object was used for the cache; this has
 * been upted to utilize a JS Map object, which keeps insertion order.
 *
 * @see: https://dev.to/udayvunnam/implementing-lru-cache-in-javascript-3c8g
 */
import 'regenerator-runtime/runtime';

class Node {
  constructor(key, value, next = null, prev = null) {
    this.key = key;
    this.value = value;
    this.next = next;
    this.prev = prev;
  }
}

class LRU {
  //set default limit of 10 if limit is not passed.
  constructor(limit = 10) {
    this.size = 0;
    this.limit = limit;
    this.head = null;
    this.tail = null;
    this.cache = new Map();
  }

  // Write Node to head of LinkedList
  // update cache with Node key and Node reference
  write(key, value) {
    this.ensureLimit();

    if (!this.head) {
      this.head = this.tail = new Node(key, value);
    } else {
      const node = new Node(key, value, this.head);
      this.head.prev = node;
      this.head = node;
    }

    //Update the cache map
    this.cache.set(key, this.head);
    this.size++;
  }

  // Read from cache map and make that node as new Head of LinkedList
  read(key) {
    if (this.cache.get(key)) {
      const value = this.cache.get(key).value;

      // node removed from it's position and cache
      this.remove(key);
      // write node again to the head of LinkedList to make it most recently used
      this.write(key, value);

      return value;
    }

    return null;
  }

  ensureLimit() {
    if (this.size === this.limit) {
      this.remove(this.tail.key);
    }
  }

  remove(key) {
    const node = this.cache.get(key);

    if (node.prev !== null) {
      node.prev.next = node.next;
    } else {
      this.head = node.next;
    }

    if (node.next !== null) {
      node.next.prev = node.prev;
    } else {
      this.tail = node.prev;
    }

    this.cache.delete(key);
    this.size--;
  }

  clear() {
    this.head = null;
    this.tail = null;
    this.size = 0;
    this.cache = new Map();
  }

  // Invokes the callback function with every node of the chain and the index of the node.
  forEach(fn) {
    let node = this.head;
    let counter = 0;
    while (node) {
      fn(node, counter);
      node = node.next;
      counter++;
    }
  }

  // To iterate over LRU with a 'for...of' loop
  *[Symbol.iterator]() {
    let node = this.head;
    while (node) {
      yield node;
      node = node.next;
    }
  }
}

export default LRU;

// PopularitySummary.ts
import DateAgo from 'app/shared/models/DateAgo';

interface PopularitySummaryParams {
  from?: {
    date?: number;
    ago?: string;
  };
  favorites?: number;
  viewed?: number;
  inquiries?: number;
}

class PopularitySummary {
  from: DateAgo;
  favorites: number;
  viewed: number;
  inquiries: number;

  constructor({ from = {}, favorites = 0, viewed = 0, inquiries = 0 }: PopularitySummaryParams = {}) {
    this.from = new DateAgo(from);
    this.favorites = Number(favorites);
    this.viewed = Number(viewed);
    this.inquiries = Number(inquiries);
  }
}

export default PopularitySummary;

import React, { Component } from 'react';
import * as S from './styles';

interface SectionProps {
  className?: string;
  children: React.ReactNode;
}

class Section extends Component<SectionProps> {
  constructor(props: SectionProps) {
    super(props);
  }

  render() {
    const { className } = this.props;

    return <S.Section className={className}>{this.props.children}</S.Section>;
  }
}

export default Section;

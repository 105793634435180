import reduce from 'lodash/reduce';

const Constants = [
  'VIEW_SEARCH_EXPERIENCE',

  // ip address | gps → {lat, lon}
  'GEOLOCATION_USER_POINT',

  // {lat, lon} → [models/Area, ...]
  'GEOLOCATION_USER_AREA',

  'FETCH_LISTINGS_BY_AREA',
  'SERVER_SIDE_APP_STORE_INIT_STATE',
  'ADD_REQUEST_TO_SSR_TRACE',
  'GMAP_API_READY',

  'NOTIFICATION_DISPLAY_BOOL',
  'UPDATE_MAP_BOUNDS',
  'SHOW_POPUP_MODAL',
  'HIDE_POPUP_MODAL',
  'TOGGLE_OVERLAY',
  'MAP_OVERLAY_CHANGED',
  'MAP_TYPE_CHANGED',
  'SET_APP_STORE_BOOL',
  'SET_LISTING_ENGINE_STORE_BOOL',
  'SET_DEVICE',

  'UPDATE_FILTER',
  'UPDATE_LES_FILTER',
  'UPDATE_LES_FILTER_HARD',
  'RESET_MAP_LISTING_CACHE',
  'CLEAR_LISTING_CACHE',

  'SET_PREVIEW_LISTING',

  'SET_FAVORITE_LISTINGS',
  'SET_HIDDEN_LISTINGS',
  'SET_INQUIRED_LISTINGS',
  'SET_VIEWED_LISTINGS',
  'SET_NUM_DEFAULT_FILTER_LISTINGS',
  'INSERT_INTO_BUILDING_CACHE',
  'FETCH_NUMBER_OF_LISTINGS',
  'FETCH_LISTINGS_SUCCESS',
  'SET_CURRENT_LISTING',
  'FETCH_LISTING_UPDATE',
  'CLEAR_PREVIEW_LISTING',
  'CLEAR_CURRENT_LISTING',
  'SET_ACTIVE_MARKER_MALONE_LOT_ID',
  'SET_NEARBY_LISTINGS',
  'FETCH_AREA_MIN_MAX_PRICE',
  'UPDATE_UNREAD_CONVERSATION_COUNT',
  'UPDATE_LISTING_CONVERSATION',

  'REMOVE_FAVORITE',
  'ADD_FAVORITE',
  'USER_ITEM_OPTIMISTIC_TOGGLE',

  'UPDATE_USER_INFO',
  'UPDATE_USER_CREDS',
  'UPDATE_SESSION_TOKEN',
  'UPDATE_CSRF_TOKEN',

  'LOGIN_SUCCESS',
  'LOGIN_DESTROY',

  'UPDATE_AD_TRACKING',

  'UPDATE_SAVED_SEARCHES',
  'UPDATE_RECENT_SEARCHES',
  'USER_LOAD_POINTS',
  'USER_UPDATE_ACTIVE_POINT',
  'USER_UPDATE_EMAIL_OPT_IN',

  'SET_NOTIFICATION_SETTINGS',
  'SET_AREA_PAGE_SCROLL',

  //AnalyticsStore
  'LOAD_AB_TEST_EXPERIMENTS',

  //ExternalScripts
  'TWITTER_INIT_SUCCESS',
  'FACEBOOK_INIT_SUCCESS',
  'SIGN_IN_WITH_GOOGLE_INIT_SUCCESS',
  'USER_LOAD_SUBSCRIPTIONS',
  'GADS_INIT_SUCCESS',
  'PREBID_INIT',

  'UPDATE_CURRENT_PAGE',
  'UPDATE_CURRENT_PAGE_DETAILS',

  'CLEAR_CURRENT_AREA',
  'LOAD_AREA_SUCCESS',
  'LOAD_WITHIN_AREAS',
  'LOAD_FACTS',
  'LOAD_FACTS_BY_BEDS',
  'LOAD_ARTICLES',
  'LOAD_SEO_FOOTER_LINKS',
  'LOAD_BLOG_POSTS',
  'LOAD_AREA_PRICE_HISTOGRAM',
  'LOAD_HTML_SITEMAP',

  'SET_AD_SLOT',
  'REFRESH_AD',

  'HAMBURGER_ACTIVE',
  'HAMBURGER_INACTIVE',

  'EMIT_CHANGE',
  'SET_INQUIRY_DATA_CACHE',
  'SET_SHARE_DATA_CACHE',

  'PUSH_XEID',

  'RESET_FETCH_LISTING_STATE',
  'FETCH_LISTING_FAIL',
  'FETCH_LISTING_SUCCESS',

  'FETCH_SIMILAR_LISTINGS_SUCCESS',
  'FETCH_SIMILAR_LISTINGS_RESET',

  'FETCH_LISTINGS_PORTFOLIO_SUCCESS',
  'FETCH_LISTINGS_PORTFOLIO_RESET',

  'FETCH_RELATED_RENTALS_SUCCESS',
  'FETCH_RELATED_RENTALS_RESET',

  'START_LOAD_AREA',

  'UPDATE_CLUSTER_SETTINGS',

  'SET_CURRENT_SEARCH',

  'SET_SERVER_SIDE_COOKIES',
  'SET_SERVER_SIDE_SET_COOKIE_ARRAY',

  'SET_FILTER_CARD_SCROLL',
  'CLEAR_DEFAULT_LISTING_COUNT',

  'LOAD_AREA_CRIME_SCORES',
  'LOAD_LISTING_CRIME_SCORES',

  'UPDATE_RENTER_PROFILE',

  'CLEAR_INSTANT_TOUR_FORM',
  'UPDATE_INSTANT_TOUR_FORM',
  'UPDATE_AVAILABLE_TOURS_FOR_LISTING',
  'UPDATE_SCHEDULED_TOURS_FOR_USER',

  'HOMEHUB_COLLECTIONS_LOADED',
  'HOMEHUB_CONTINUE_WITH',
  'HOMEHUB_EXPAND_SEARCH',
  'HOMEHUB_RESET',

  'SET_CURRENT_LISTING_INDEX',
  'SET_CURRENT_LISTING_OUTSIDE_OF_LIST',
  'SET_USER_REVIEW_REPLY_PERMISSION_BOOL',
  'SET_LAST_VISITED_LISTING_FROM_LIST',

  // HPWEB-5993: CTA A/B test
  'UPDATE_CTA_BUTTON_CONTEXT',

  // HPWEB-5795
  'SET_BOUNTY_BRAIN_TEST_GROUP',

  // HPWEB-5993: CTA A/B test
  'UPDATE_CTA_BUTTON_CONTEXT',

  'UPDATE_IP_FROM_JAVA',

  // MWEB FILTER
  'UPDATE_MWEB_FILTER',

  // Static Map
  'SAVE_STATIC_MAP_PLACEHOLDERS',
];

const constantObject = reduce<string, Record<string, string>>(
  Constants,
  (accumulator, value) => {
    accumulator[value] = value;
    return accumulator;
  },
  {},
);

export default constantObject;

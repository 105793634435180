import { ContextError } from '@zg-rentals/log-error';

export class DatarouterError extends ContextError {
  constructor(
    public readonly message: string,
    public readonly responseStatus: number,
    errorDetails?: unknown,
  ) {
    super('DatarouterError', { message, errorDetails, responseStatus });
  }

  public static is(error: unknown): error is DatarouterError {
    return error instanceof DatarouterError;
  }

  static getDisplayMessage(error: unknown, fallback = 'An error has occurred, please try again later'): string {
    return DatarouterError.is(error) ? error.message : fallback;
  }
}

// @ts-nocheck
/* eslint-enable */
// Lodash
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import map from 'lodash/map';

// Misc / Utils
import constants from 'app/shared/constants/ConstantsBundle';
import routeUtils from 'app/shared/utils/routeUtils';
import searchSlugManifest from 'app/shared/utils/searchSlugManifest';

export const LONGTAIL_SLUG_MAP = {
  'apartments-for-rent': {
    'orderBy=lowPrice': 'affordable-apartments-for-rent',
    'orderBy=highPrice': 'luxury-apartments-for-rent',
    'beds=0': 'studio-apartments-for-rent',
    'furnished=true': 'furnished-apartments-for-rent',
    'beds=1': '1-bedroom-apartments-for-rent',
    'beds=2': '2-bedroom-apartments-for-rent',
    'beds=3': '3-bedroom-apartments-for-rent',
    'pets=cats-dogs': 'pet-friendly-apartments-for-rent',
    'amenities=parking': 'apartments-for-rent-with-parking',
    'laundry=inUnit-shared': 'apartments-for-rent-with-laundry',
    'amenities=fitnessCenter': 'apartments-for-rent-with-gym',
    'listingTypes=room': 'rooms-for-rent',
    'isListedByOwner=true': 'for-rent-by-owner',
  },
  'houses-for-rent': {
    'orderBy=lowPrice': 'affordable-houses-for-rent',
    'orderBy=highPrice': 'luxury-houses-for-rent',
    'furnished=true': 'furnished-houses-for-rent',
    'beds=4': '4-plus-bedroom-houses-for-rent',
    'beds=3': '3-bedroom-houses-for-rent',
    'beds=2': '2-bedroom-houses-for-rent',
    'beds=1': '1-bedroom-houses-for-rent',
    'pets=cats-dogs': 'pet-friendly-houses-for-rent',
    'isListedByOwner=true': 'for-rent-by-owner',
  },
};

const searchSlugUtils = {
  active() {
    return searchSlugManifest.activeSearchSlugs;
  },
  deprecatedSlugs() {
    return searchSlugManifest.deprecatedSlugs;
  },
  getCorrectedSearchSlugUrl(pathname, params) {
    const resourceId = routeUtils.getResourceIdFromUrl(pathname);
    const { propertyTypes, listingTypes, ...restOfParams } = params;

    const CORRECTED_URL_MAP = {
      house: routeUtils.buildPathWithQuery(`/${resourceId}/houses-for-rent`, restOfParams),
      condo: routeUtils.buildPathWithQuery(`/${resourceId}/condos-for-rent`, restOfParams),
      townhouse: routeUtils.buildPathWithQuery(`/${resourceId}/townhomes-for-rent`, restOfParams),
    };

    return CORRECTED_URL_MAP[propertyTypes];
  },
  getDescriptionFromSearchSlug(searchSlug = 'apartments-for-rent') {
    // apartments-for-rent-with-laundry -> Apartments for Rent with Laundry

    // special case
    if (searchSlug === '4-plus-bedroom-houses-for-rent') {
      return '4+ Bedroom Houses for Rent';
    }

    const conjunctions = ['for', 'and', 'with', 'or'];
    return map(searchSlug.split('-'), (word) => {
      if (conjunctions.indexOf(word) > -1) {
        return word;
      } else {
        return word[0].toUpperCase() + word.slice(1);
      }
    }).join(' ');
  },
  getPropertyTypeFromSlug(searchSlug) {
    // infer property type that user is searching for based on current searchSlug
    const RegEx = /(apartments|houses|condos|townhomes)|(-for-rent)/gi;

    return searchSlug.match(RegEx) ? searchSlug.match(RegEx)[0] : null;
  },
  getSearchSlugFromUrl(pathname = '') {
    // format of pathname is '/{areaResourceId}/{searchSlug}`
    pathname = pathname.toLowerCase();
    return last(pathname.split('/'));
  },
  hasSearchSlugPropertyTypeMismatch(pathname, params) {
    const isNearMeUrl = routeUtils.isNearMeUrl(pathname);
    const isPadOrBuildingUrl = routeUtils.isPadOrBuildingUrl(pathname);
    const searchSlugPropertyType = searchSlugUtils.getPropertyTypeFromSlug(pathname);
    const paramPropertyType = params.propertyTypes;
    const emptyPathOrParams = isNil(searchSlugPropertyType) || isNil(paramPropertyType);
    // We only care if we have one property type and if its condo, house, townhouse
    const isApplicablePropertyType =
      paramPropertyType === 'condo' || paramPropertyType === 'house' || paramPropertyType === 'townhouse';

    // Ignore /near-me routes and pad/building urls or empty path or params
    if (isNearMeUrl || isPadOrBuildingUrl || emptyPathOrParams) {
      return false;
    }

    if (isApplicablePropertyType) {
      const SLUG_PROPERTY_TYPE_MAP = {
        houses: 'house',
        condos: 'condo',
        townhomes: 'townhouse',
      };

      // The actual check between param property type and the current search slug
      if (SLUG_PROPERTY_TYPE_MAP[searchSlugPropertyType] !== paramPropertyType) {
        return true;
      }
    }

    return false;
  },
  isAffordableOrLuxSearchSlug(searchSlug) {
    const affordableOrLuxuryRegEx = new RegExp(constants.SRP_MAX_PAGE_LIMIT_KEYWORDS, 'i');

    return searchSlug && affordableOrLuxuryRegEx.test(searchSlug);
  },
  nearMeToSearchSlugMap(pathname) {
    return constants.NEAR_ME_ROUTES[pathname].slug;
  },
  prepareSlugDescription(searchSlug = 'apartments-for-rent') {
    // special case
    if (searchSlug === '4-plus-bedroom-houses-for-rent') {
      return '4+ Bedroom Houses for Rent';
    }

    const conjunctions = ['for', 'and', 'with', 'or'];
    return searchSlug
      .split('-')
      .map((word, idx) => {
        if (idx === 0 && word === 'for') {
          return 'For';
        } else if (conjunctions.indexOf(word) > -1) {
          return word;
        } else {
          return word[0].toUpperCase() + word.slice(1);
        }
      })
      .join(' ');
  },
  preparePropertyType(searchSlug) {
    const SPECIAL_SLUGS = {
      'for-rent-by-owner': 'Listings',
      'senior-housing-for-rent': 'Senior housing',
      'rooms-for-rent': 'Rooms',
    };

    // Special cases
    if (SPECIAL_SLUGS[searchSlug]) {
      return SPECIAL_SLUGS[searchSlug];
    }

    // infer property type that user is searching for based on current searchSlug
    const RegEx = /(apartments|houses|condos|townhomes)|(-for-rent)/gi;

    return searchSlug.match(RegEx) ? searchSlug.match(RegEx)[0] : null;
  },
};

export default searchSlugUtils;

import type { MetricFnInterface, MetricOptions } from '@zg-rentals/monitor-base';
import type { Logger } from '@zg-rentals/logger-browser';
import { MonitorReporter } from '@zg-rentals/monitor-base';
import type { MarlinOptions } from '@zg-rentals/marlin-browser';
import { MarlinBrowser } from '@zg-rentals/marlin-browser';

export class BrowserMonitorMarlinReporter extends MonitorReporter {
  marlin: MarlinBrowser;
  proxyPath?: string;
  metricDetails: { serviceName: string; serverName: 'browser' };

  constructor({ logger, sampleRate, marlinOpts }: { logger?: Logger; sampleRate?: number; marlinOpts: MarlinOptions }) {
    super({
      reporterName: 'BrowserMarlinReporter',
      logger,
      sampleRate,
    });

    if (!marlinOpts.appName) {
      throw new Error('Cannot instantiate BrowserMonitorMarlinReporter without appName.');
    }

    this.logger = logger;
    this.metricDetails = { serviceName: marlinOpts.appName, serverName: 'browser' };
    this.marlin = new MarlinBrowser(marlinOpts);
  }

  onInitialize(logger?: Logger) {
    this.logger = this.logger || logger;
  }

  shouldSave({ sampleRate }: MetricOptions = {}) {
    const random = Math.random();
    return random <= this.sampleRate && (typeof sampleRate !== 'number' || random <= sampleRate);
  }

  async count({ name, amount = 1, options }: MetricFnInterface) {
    if (this.shouldSave(options)) {
      await this.marlin
        .sendCounts({
          ...this.metricDetails,
          countStats: {
            [Date.now()]: {
              [name]: { sum: amount },
            },
          },
        })
        .catch((e) => this.onError({ error: e }));
    }
  }

  async gauges({ name, amount, options }: MetricFnInterface): Promise<void> {
    if (this.shouldSave(options)) {
      await this.marlin
        .sendGauges({
          ...this.metricDetails,
          gauges: {
            [Date.now()]: {
              [name]: {
                count: 1,
                sum: amount,
                min: amount,
                max: amount,
              },
            },
          },
        })
        .catch((e) => this.onError({ error: e }));
    }
  }

  async measurementWithGauges({ name, amount, options }: MetricFnInterface): Promise<void> {
    if (this.shouldSave(options)) {
      await this.marlin
        .sendMeasurementWithGauges({
          ...this.metricDetails,
          measurements: {
            [name]: [{ timeMs: Date.now(), value: amount }],
          },
        })
        .catch((e) => this.onError({ error: e }));
    }
  }

  async onError({ error }: { error: Error }) {
    this.logger?.error(error);
  }
}

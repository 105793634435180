import type { Logger } from '@zg-rentals/logger-browser';
import type { Monitor } from '@zg-rentals/monitor-base';
import type { RumInitConfiguration } from '@datadog/browser-rum';
import { MonitorPlugin } from '@zg-rentals/monitor-base';
import { datadogRum } from '@datadog/browser-rum';
import { isbot } from 'isbot';

export type DatadogPluginConfig = {
  logger?: Logger;
  datadogOptions: RumInitConfiguration;
};

export class DatadogPlugin extends MonitorPlugin {
  constructor({ logger, datadogOptions }: DatadogPluginConfig) {
    super('datadogPlugin', logger);

    this.validateRumInitConfig(datadogOptions);

    if (this.isDatadogInitialized()) {
      this.logger?.error('Failed to initialize DatadogPlugin - datadogRum is already initialized.');
    } else {
      this.initDatadog(datadogOptions);
    }
  }

  isDatadogInitialized() {
    // @ts-ignore
    const rumContext = window.DD_RUM?.getInternalContext();
    return !!rumContext?.applicationId;
  }

  initDatadog(datadogOptions: RumInitConfiguration) {
    datadogRum.init({
      sessionSampleRate: 20,
      sessionReplaySampleRate: 0,
      trackUserInteractions: false,
      allowedTracingUrls: [window.location.origin],
      site: 'datadoghq.com',
      startSessionReplayRecordingManually: true,
      ...datadogOptions,
      beforeSend: (event, context) => {
        if (typeof window !== 'undefined' && isbot(window.navigator.userAgent)) {
          return false;
        }

        if (datadogOptions.beforeSend) {
          return datadogOptions.beforeSend(event, context);
        }

        return true;
      },
    });
  }

  onInitialize(monitor: Monitor, logger?: Logger) {
    return super.onInitialize(monitor, logger);
  }

  validateRumInitConfig(config: RumInitConfiguration) {
    // only handle errors if on the client
    if (typeof window === 'undefined') return;

    // only these fields will fail silently if they are not the correct type
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters
    const silentFailingFieldTypes = {
      env: 'string',
      service: 'string',
      trackViewsManually: 'boolean',
      version: 'string',
    } as Record<keyof RumInitConfiguration, string>;

    const validationErrors: Array<{ field: string; expectedType: string; receivedType: string }> = [];
    (Object.keys(silentFailingFieldTypes) as Array<keyof RumInitConfiguration>).forEach((field) => {
      const expectedType = silentFailingFieldTypes[field];

      if (typeof config[field] !== 'undefined' && typeof config[field] !== expectedType) {
        validationErrors.push({ field, expectedType, receivedType: typeof config[field] });
      }
    });

    if (!validationErrors.length) return;

    const errorMessage = `Invalid type(s) provided to \`DatadogPlugin\`\n${JSON.stringify(validationErrors, null, 2)}`;
    const hostname = window.location.hostname;
    const isLocalhost = hostname === 'localhost' || /^[0-9.]+$/.test(hostname);

    if (isLocalhost) {
      throw new Error(errorMessage);
    } else {
      // prevent app from crashing in non-localhost environments but still log the error so devs can debug in any env
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }
  }
}

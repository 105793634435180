import type { Logger } from '@zg-rentals/logger-base';
import type { Monitor } from './baseMonitor';
import type { StatController } from './statController';

export abstract class MonitorPlugin {
  public monitor?: Monitor;

  onInitialize(monitor: Monitor, logger?: Logger): void | StatController {
    this.monitor = monitor;
    this.logger ||= logger;
  }

  constructor(
    public readonly pluginName: string,
    public logger?: Logger,
  ) {}
}

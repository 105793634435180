import { bootstrap } from '@zg-rentals/rp-bootstrap-browser';

const rpBootstrap = bootstrap({
  appName: 'hotpads-web',
  buildNumber: Number(__BUILD_NUMBER__ ?? -1),
  loggerOptions: {
    logPath: '/log',
    name: 'hotpads-web',
    level: 'info',
  },
  monitorOptions: {
    datadogPluginConfig: {
      datadogOptions: {
        applicationId: 'c1472ba7-3d5d-44d4-b19d-7ab3649680b1',
        clientToken: 'pub1aa8dca06cfed3ccee4832929875e69a',
        // overriding app name to not conflict w/ legacy app
        service: 'hotpads-web-rjs',
      },
    },
  },
});

export { rpBootstrap };

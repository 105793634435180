// @ts-nocheck
/* eslint-enable */
import isString from 'lodash/isString';

const genericUtils = {
  // create an array of incremented strings ['1', '1.5', '2']
  createRangeArray(step, start, end, specialAppend) {
    const arr = [];

    // 8plus endings have to be converted to a lower number + specialAppend;
    if (end === '8plus') {
      end = '7';
      specialAppend = '8plus';
    }

    if (step <= 1) {
      for (let i = start; i <= end; i++) {
        if (step === 1) {
          arr.push(String(Number(i)));
        } else if (step < 1) {
          for (let y = 0; y < 1; y += step) {
            arr.push(String(Number(i) + Number(y)));
          }
        }
      }
    } else {
      for (let i = start; i <= end; i += step) {
        arr.push(String(i));
      }
    }

    if (specialAppend) {
      arr.push(specialAppend);
    }

    return arr;
  },
  isLooseTrue(el) {
    if (isString(el)) {
      return el.toLowerCase() === 'true';
    } else {
      return Boolean(el);
    }
  },
  getTrueFalseNull(val) {
    if (val === true) {
      return true;
    } else if (val === false) {
      return false;
    } else {
      return null;
    }
  },
  //simple nonsecure hash, from Daniel Bernstein of qmail fame
  hash(str) {
    const end = str.length;
    let hash = 5381;
    for (let i = 0; i < end; ++i) {
      hash = (hash << 5) + hash + str.charCodeAt(i);
    }
    return hash;
  },
  /**
   * Used for expired listings survey
   * Credit: https://stackoverflow.com/a/53758827/7234944
   */
  seededShuffle(array, seed) {
    const random = (num) => {
      const x = Math.sin(num++) * 10000;
      return x - Math.floor(x);
    };

    let currentIndex = array.length;
    let tempValue;
    let seededRandomIndex;

    while (currentIndex) {
      seededRandomIndex = Math.floor(random(seed) * currentIndex--);

      tempValue = array[currentIndex];
      array[currentIndex] = array[seededRandomIndex];
      array[seededRandomIndex] = tempValue;
      ++seed;
    }

    return array;
  },
};

export default genericUtils;

import CrimeScore from 'app/shared/models/CrimeScore';

interface CrimeScoreCollectionProps {
  localCrime?: object;
  cityCrime?: object;
  nearbyCrime?: Array<object>;
  localCrimeRank?: string;
  radius?: string;
}

class CrimeScoreCollection {
  localCrime: CrimeScore;
  cityCrime: CrimeScore;
  nearbyCrime: Array<CrimeScore>;
  localCrimeRank: string;
  radius: string;

  constructor({
    localCrime = {},
    cityCrime = {},
    nearbyCrime = [],
    localCrimeRank = '',
    radius = '',
  }: CrimeScoreCollectionProps = {}) {
    this.localCrime = new CrimeScore(localCrime);
    this.cityCrime = new CrimeScore(cityCrime);
    this.nearbyCrime = nearbyCrime.map((nearbyItem) => new CrimeScore(nearbyItem));
    this.localCrimeRank = String(localCrimeRank).toLowerCase();
    this.radius = String(radius);
  }
}

export default CrimeScoreCollection;

// @ts-nocheck
/* eslint-enable */
import Promise from 'promise';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';

import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';

const AdActions = {
  adTags({ aliasEncoded, minLat, minLon, maxLat, maxLon }) {
    return function (dispatch) {
      if (aliasEncoded || (minLat && minLon && maxLat && maxLon)) {
        return dispatch(api.analytics.adTags({ aliasEncoded, minLat, minLon, maxLat, maxLon })).then((result) => {
          const attributes = {};

          if (result.data) {
            forEach(result.data, (obj) => {
              const key = keys(obj)[0];

              attributes[key] = obj[key];
            });
          } else {
            return;
          }

          return {
            attributes,
          };
        });
      } else {
        return Promise.resolve({
          warning: 'No alias encoding or lat/lon provided to adTags',
        });
      }
    };
  },
  loadGoogleAdApi() {
    return (dispatch) => {
      const loadGoogleAds = () => {
        let gads;
        if (typeof window !== 'undefined' && (!window.googletag || !window.googletag.pubads)) {
          window.googletag = {
            cmd: [],
          };

          gads = document.createElement('script');
          gads.async = true;
          gads.type = 'text/javascript';
          gads.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
          document.documentElement.firstChild.appendChild(gads);
          gads.onload = gads.onreadystatechange = function () {
            window.googletag.cmd.push(() => {
              if (window.googletag.pubads) {
                window.googletag.pubads().enableSingleRequest();
                window.googletag.pubads().enableAsyncRendering();
                window.googletag.enableServices();
                //oneTrustOnInit will set this variable to decide if we can run personalized ads
                if (!window.allowPersonalizedAds) {
                  window.googletag.pubads().setRequestNonPersonalizedAds(1);
                  window.googletag.pubads().setPrivacySettings({ restrictDataProcessing: true });
                }
                if (!__DEV__) {
                  window.googletag.pubads().collapseEmptyDivs();
                }
              }
            });

            dispatch({
              type: constants.GADS_INIT_SUCCESS,
            });
          };
        }
      };

      //wait for OneTrust to be loaded before loading ads
      let numberOfTimesWaiting = 0; //don't wait more than 40 times (1000ms)
      function waitForOneTrust() {
        if (numberOfTimesWaiting++ > 40 || !window.oneTrustLoaded) {
          setTimeout(waitForOneTrust, 25);
        } else {
          loadGoogleAds();
        }
      }

      waitForOneTrust();
    };
  },
  prebidInitLoaded(bool) {
    return (dispatch) => {
      dispatch({
        type: constants.PREBID_INIT,
        payload: {
          loaded: bool,
        },
      });
    };
  },
  refreshAd(force) {
    return (dispatch) => {
      dispatch({
        type: constants.REFRESH_AD,
        payload: {
          force,
        },
      });
    };
  },
  setAdSlot(slot, data) {
    return function (dispatch) {
      dispatch({
        type: constants.SET_AD_SLOT,
        payload: {
          data,
          slot,
        },
      });
    };
  },
};

export default AdActions;

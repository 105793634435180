import isEmpty from 'lodash/isEmpty';

interface Boundary {
  type: string;
  coordinates: Array<Array<Array<number>>>;
}

interface Area {
  id?: any;
}

interface GeoJsonParams {
  area?: Area;
  boundary?: Boundary;
  defaultBoundingBox?: boolean;
}

class GeoJson {
  id: any | null;
  geometry: { type: string; coordinates: any } | null;
  type: string | null;
  defaultBoundingBox: boolean;

  constructor({ area = {}, boundary, defaultBoundingBox = false }: GeoJsonParams = {}) {
    this.id = null;
    this.geometry = null;
    this.type = null;
    this.defaultBoundingBox = Boolean(defaultBoundingBox);

    if (!isEmpty(boundary)) {
      this.geometry = {
        type: boundary.type,
        coordinates: boundary.coordinates,
      };
      this.id = area.id;
      this.type = 'Feature';
    }
  }
}

export default GeoJson;

import numberUtils from 'app/shared/utils/numberUtils';

interface FloorplanUnitProps {
  availabilityDate?: string;
  baths?: string;
  beds?: string;
  high?: string;
  low?: string;
  modelId?: string;
  name?: string;
  sqft?: string;
}

class FloorplanUnit {
  modelId: string;
  name: string;
  availabilityDate: string;
  beds: number;
  baths: number;
  sqft: number;
  low: number;
  high: number;
  priceRangeDisplay: Array<string>;

  constructor({
    availabilityDate = '',
    baths = '',
    beds = '',
    high = '',
    low = '',
    modelId = '',
    name = '',
    sqft = '',
  }: FloorplanUnitProps = {}) {
    this.modelId = modelId;
    this.name = name;
    this.availabilityDate = availabilityDate;
    this.beds = Number(beds);
    this.baths = Number(baths);
    this.sqft = Number(sqft);
    this.low = Number(low);
    this.high = Number(high);
    this.priceRangeDisplay = [numberUtils.compact(low), numberUtils.compact(high)];
  }
}

export default FloorplanUnit;

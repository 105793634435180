// @ts-nocheck
/* eslint-enable */
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import api from 'app/shared/utils/api';
import AppActions from 'app/shared/flux/actions/AppActions';
import BuildingV2 from 'app/shared/models/BuildingV2';
import constants from 'app/shared/constants/ConstantsBundle';

import { analyticsEvent } from 'app/client/universal-analytics';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/useritems');

const UserItemActions = {
  getUserItems(type, fromIndex, pageSize, orderBy, includeActiveListingsOnly = false) {
    return function (dispatch) {
      let userItemPromise;
      if (!orderBy || orderBy === '' || orderBy === 'default') {
        userItemPromise = dispatch(api.user.item.get(type, fromIndex, pageSize, '', includeActiveListingsOnly));
      } else {
        userItemPromise = dispatch(api.user.item.get(type, fromIndex, pageSize, orderBy, includeActiveListingsOnly));
      }
      return userItemPromise.then((result) => {
        if (result && result.data && result.data.buildings) {
          const listings = [];
          result.data.buildings.forEach((building) => {
            const bldg = new BuildingV2(building);
            bldg.units.forEach((unit) => {
              if (unit && unit.maloneLotIdEncoded) {
                listings.push(unit);
              }
            });
          });

          if (type === constants.FAVORITE) {
            dispatch({
              type: constants.SET_FAVORITE_LISTINGS,
              payload: {
                listings,
              },
            });
          } else if (type === constants.INQUIRY) {
            dispatch({
              type: constants.SET_INQUIRED_LISTINGS,
              payload: {
                listings,
              },
            });
          } else if (type === constants.HIDDEN) {
            dispatch({
              type: constants.SET_HIDDEN_LISTINGS,
              payload: {
                listings,
              },
            });
          } else if (type === constants.VIEWED) {
            dispatch({
              type: constants.SET_VIEWED_LISTINGS,
              payload: {
                listings,
              },
            });
          } else if (type === constants.FAVORITE_AND_INQUIRY) {
            const favorites = filter(listings, (listing) => includes(listing.userItemTypes, constants.FAVORITE));
            const inquiries = filter(listings, (listing) => includes(listing.userItemTypes, constants.INQUIRY));

            if (favorites.length) {
              dispatch({
                type: constants.SET_FAVORITE_LISTINGS,
                payload: {
                  listings: favorites,
                },
              });
            }

            if (inquiries.length) {
              dispatch({
                type: constants.SET_INQUIRED_LISTINGS,
                payload: {
                  listings: inquiries,
                },
              });
            }
          }
        }

        return result;
      });
    };
  },
  addUserItem(type, listing) {
    return function (dispatch, getState) {
      let aliasEncoded;
      const state = getState();

      if (!state.user.loggedIn && type === constants.FAVORITE) {
        dispatch(analyticsEvent(gaEvents.USER_ACTION_SIGN_IN));
        dispatch(
          AppActions.showPopupModal('PromptLogin', {
            caption: 'Sign in or register to keep track of your favorite listings!',
            action: type,
            listing,
            shouldNotRedirect: true,
          }),
        );
      } else if (!state.user.loggedIn && type === constants.HIDDEN) {
        dispatch(analyticsEvent(gaEvents.USER_ACTION_SIGN_IN));
        dispatch(
          AppActions.showPopupModal('PromptLogin', {
            caption: 'Sign in or register to keep track of your hidden listings!',
            action: type,
            listing,
            shouldNotRedirect: true,
          }),
        );
      } else {
        dispatch({
          type: constants.USER_ITEM_OPTIMISTIC_TOGGLE,
          payload: {
            type,
            listing,
            action: 'add',
          },
        });

        if (listing.isBuilding) {
          aliasEncoded = listing.units[0].aliasEncoded;
        } else {
          aliasEncoded = listing.aliasEncoded;
        }

        return dispatch(api.user.item.add(type, aliasEncoded))
          .then((result) => {
            if (type === constants.FAVORITE) {
              dispatch(analyticsEvent(gaEvents.HDP_FAVORITED));
              dispatch(AppActions.sendEventToApi('addedToAccountFavorites', aliasEncoded));
              dispatch(UserItemActions.addFavorite(listing));
            } else if (type === constants.HIDDEN) {
              dispatch(analyticsEvent(gaEvents.HDP_HIDDEN));
            }
            return result;
          })
          .catch((err) => {
            logError({ error: err, errorType: 'UserItemActionsError', context: 'Error in addUserItem' });
            // with the way requester is set up now, we need to have catches on our api calls.
            // otherwise requester will throw on api fail,
            // there's a chance nothing catches it, and js breaks on the page
          });
      }
    };
  },
  removeUserItem(type, listing) {
    return function (dispatch) {
      dispatch({
        type: constants.USER_ITEM_OPTIMISTIC_TOGGLE,
        payload: {
          type,
          listing,
          action: 'remove',
        },
      });
      return dispatch(api.user.item.remove(type, listing.aliasEncoded))
        .then((result) => {
          if (type === constants.FAVORITE) {
            dispatch(analyticsEvent(gaEvents.HDP_UNFAVORITED));
            dispatch(UserItemActions.removeFavorite(listing));
          } else if (type === constants.HIDDEN) {
            dispatch(analyticsEvent(gaEvents.HDP_UNHIDDEN));
          }
          return result;
        })
        .catch((err) => {
          logError({ error: err, context: 'Error in removeUserItem', errorType: 'UserItemActionsError' });
        });
    };
  },
  toggleUserItem(type, listing) {
    return function (dispatch) {
      if (includes((listing || {}).userItemTypes, type)) {
        return dispatch(UserItemActions.removeUserItem(type, listing));
      } else {
        return dispatch(UserItemActions.addUserItem(type, listing));
      }
    };
  },
  addFavorite(listing) {
    return function (dispatch, getState) {
      const existingFavorites = getState().listings.listingGroups.favorite;
      const listings = uniqBy([...existingFavorites, listing], 'aliasEncoded');
      dispatch({
        type: constants.SET_FAVORITE_LISTINGS,
        payload: {
          listings,
        },
      });
    };
  },
  removeFavorite(listing) {
    return function (dispatch, getState) {
      const listings = getState().listings.listingGroups.favorite;
      const newListings = reject(listings, ['aliasEncoded', listing.aliasEncoded]);
      dispatch({
        type: constants.SET_FAVORITE_LISTINGS,
        payload: {
          listings: newListings,
        },
      });
    };
  },
};

export default UserItemActions;

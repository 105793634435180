// @ts-nocheck
/* eslint-enable */
import { logError } from '@zg-rentals/log-error';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('utils/login');

const loginUtils = {
  getEmailFromFB(response) {
    return new Promise((resolve, reject) => {
      window.FB.api('/me?fields=id,name,email', (userData) => {
        if (typeof userData !== 'undefined') {
          response.userData = userData;
          resolve(response);
        } else {
          reject(new Error('window.FB.api failed'));
        }
      });
    }).catch((err) => {
      logError({ error: err, context: 'FB Email Validate Error' });
    });
  },
  isCanadianUser(countryCode, email = '') {
    const emailParts = email.split('.');
    const topLevelDomain = emailParts[emailParts.length - 1];
    if (countryCode === 'ca' || topLevelDomain === 'ca') {
      return true;
    }
    return false;
  },
};

export default loginUtils;

// @ts-nocheck
/* eslint-enable */
// https://developers.google.com/analytics/devguides/collection/analyticsjs/
export default function (options) {
  const gaAddress = options && options.gaAddress ? options.gaAddress : 'https://www.google-analytics.com/analytics.js';
  const propertyName = options && options.propertyName ? options.propertyName : 'ga';

  (function (i, s, o, g, r, a, m) {
    // eslint-disable-next-line no-unused-expressions, no-sequences
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;

    if (options.onetrustEnabled) {
      a.className = 'optanon-category-3'; //OneTrust cookie category
      a.type = 'text/plain';
    } else {
      a.type = 'text/javascript';
    }

    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', gaAddress, propertyName);
}

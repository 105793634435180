// @ts-nocheck
/* eslint-enable */
import UnitV2 from 'app/shared/models/UnitV2';
import Photo from 'app/shared/models/Photo';
import ModelCollection from 'app/shared/models/ModelCollection';
import assign from 'lodash/assign';
import isFinite from 'lodash/isFinite';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

const buildingUtils = {
  createPricingSummary(
    pricing,
    pricingSummary = {
      Studio: {},
      1: {},
      2: {},
      3: {},
      ThreePlus: {},
      summary: {},
    },
  ) {
    const newSummary = assign({}, pricingSummary);
    if (pricing.Studio) {
      if (!newSummary.Studio.priceLow || newSummary.Studio.priceLow > pricing.Studio.priceLow) {
        newSummary.Studio.priceLow = pricing.Studio.priceLow;
      }
      if (!newSummary.Studio.priceHigh || newSummary.Studio.priceHigh < pricing.Studio.priceHigh) {
        newSummary.Studio.priceHigh = pricing.Studio.priceHigh;
      }
      if (!isFinite(newSummary.summary.bedsLow) || newSummary.summary.bedsLow > 0) {
        newSummary.summary.bedsLow = 0;
      }
      if (!isFinite(newSummary.summary.bedsHigh) || newSummary.summary.bedsHigh < 0) {
        newSummary.summary.bedsHigh = 0;
      }
    }
    if (pricing[1]) {
      if (!newSummary[1].priceLow || newSummary[1].priceLow > pricing[1].priceLow) {
        newSummary[1].priceLow = pricing[1].priceLow;
      }
      if (!newSummary[1].priceHigh || newSummary[1].priceHigh < pricing[1].priceHigh) {
        newSummary[1].priceHigh = pricing[1].priceHigh;
      }
      if (!isFinite(newSummary.summary.bedsLow) || newSummary.summary.bedsLow > 1) {
        newSummary.summary.bedsLow = 1;
      }
      if (!isFinite(newSummary.summary.bedsHigh) || newSummary.summary.bedsHigh < 1) {
        newSummary.summary.bedsHigh = 1;
      }
    }
    if (pricing[2]) {
      if (!newSummary[2].priceLow || newSummary[2].priceLow > pricing[2].priceLow) {
        newSummary[2].priceLow = pricing[2].priceLow;
      }
      if (!newSummary[2].priceHigh || newSummary[2].priceHigh < pricing[2].priceHigh) {
        newSummary[2].priceHigh = pricing[2].priceHigh;
      }
      if (!isFinite(newSummary.summary.bedsLow) || newSummary.summary.bedsLow > 2) {
        newSummary.summary.bedsLow = 2;
      }
      if (!isFinite(newSummary.summary.bedsHigh) || newSummary.summary.bedsHigh < 2) {
        newSummary.summary.bedsHigh = 2;
      }
    }
    if (pricing[3]) {
      if (!newSummary[3].priceLow || newSummary[3].priceLow > pricing[3].priceLow) {
        newSummary[3].priceLow = pricing[3].priceLow;
      }
      if (!newSummary[3].priceHigh || newSummary[3].priceHigh < pricing[3].priceHigh) {
        newSummary[3].priceHigh = pricing[3].priceHigh;
      }
      if (!isFinite(newSummary.summary.bedsLow) || newSummary.summary.bedsLow > 3) {
        newSummary.summary.bedsLow = 3;
      }
      if (!isFinite(newSummary.summary.bedsHigh) || newSummary.summary.bedsHigh < 3) {
        newSummary.summary.bedsHigh = 3;
      }
    }

    if (pricing.ThreePlus) {
      if (!newSummary.ThreePlus.priceLow || newSummary.ThreePlus.priceLow > pricing.ThreePlus.priceLow) {
        newSummary.ThreePlus.priceLow = pricing.ThreePlus.priceLow;
      }
      if (!newSummary.ThreePlus.priceHigh || newSummary.ThreePlus.priceHigh < pricing.ThreePlus.priceHigh) {
        newSummary.ThreePlus.priceHigh = pricing.ThreePlus.priceHigh;
      }
      if (!isFinite(newSummary.summary.bedsLow) || newSummary.summary.bedsLow > pricing.ThreePlus.bedsLow) {
        newSummary.summary.bedsLow = pricing.ThreePlus.bedsLow;
      }
      if (!isFinite(newSummary.summary.bedsHigh) || newSummary.summary.bedsHigh < pricing.ThreePlus.bedsHigh) {
        newSummary.summary.bedsHigh = pricing.ThreePlus.bedsHigh;
      }
    }

    return newSummary;
  },
  processApiDataForBuildingV2({
    areas = {},
    availableUnits = {},
    geo = {},
    listings = [],
    lotIdReviews = {},
    schools = [],
  }) {
    let buildingActivated = 0;
    let buildingUserItemTypes = [];
    const buildingPhotos = [];
    const buildingLargePhotos = [];
    const buildingThumbPhotos = [];
    let isActive = true;
    const pricingSummary = {};
    const modelCollection = ModelCollection();

    const rentalUnits = availableUnits.rental || [];
    const hasMultipleUnits = rentalUnits.length > 1;

    const units = map(listings, (listing) => {
      const unit = new UnitV2(listing, {
        areas,
        geo,
        belongsToMultipleUnitBuilding: hasMultipleUnits,
        forRentUnitsInBuilding: rentalUnits,
        lotIdReviews,
        schools,
      });

      buildingActivated = unit.activated > buildingActivated ? unit.activated : buildingActivated;
      buildingUserItemTypes = buildingUserItemTypes.concat(unit.userItemTypes);

      if (listing && listing.active === false) {
        isActive = false;
      }

      if (unit.photos[0]) {
        buildingPhotos.push(
          new Photo({
            url: unit.photos[0].url,
            caption: `Photo of Unit ${unit.unit}`,
          }),
        );
      }
      if (unit.largePhotos[0]) {
        buildingLargePhotos.push(
          new Photo({
            url: unit.largePhotos[0].url,
            caption: `Photo of Unit ${unit.unit}`,
          }),
        );
      }
      if (unit.thumbPhotos[0]) {
        buildingThumbPhotos.push(
          new Photo({
            url: unit.thumbPhotos[0].url,
            caption: `Photo of Unit ${unit.unit}`,
          }),
        );
      }

      /**
       * UnitV2 uses `floorplans` param
       * SummaryV2 uses `models` param
       */
      modelCollection.add(listing.floorplans, listing.listingType, listing.propertyType);

      if (unit.pricing) {
        const listingType = unit.listingType === 'corporate' ? 'rental' : unit.listingType;
        pricingSummary[listingType] = buildingUtils.createPricingSummary(unit.pricing, pricingSummary[listingType]);
      }

      return unit;
    });

    return {
      units,
      rentalUnits,
      pricingSummary,
      isActive,
      buildingPhotos,
      buildingLargePhotos,
      buildingThumbPhotos,
      buildingActivated,
      buildingUserItemTypes: uniq(buildingUserItemTypes),
      listingMinMaxPriceBeds: modelCollection.summarize(),
    };
  },
  processApiDataForSummaryV2(listings = [], filter = {}) {
    let buildingUserItemTypes = [];
    let buildingActivated = 0;
    let buildingUpdated = 0;
    let isActive = true;
    const modelCollection = ModelCollection();

    listings.forEach((listing) => {
      if (!listing.modelSummary) {
        return false;
      }

      buildingActivated = listing.activated > buildingActivated ? listing.activated : buildingActivated;
      buildingUpdated = listing.updated > buildingUpdated ? listing.updated : buildingUpdated;
      buildingUserItemTypes = buildingUserItemTypes.concat(listing.userItemTypes);

      if (listing && listing.active === false) {
        isActive = false;
      }

      /*
       * HPWEB-5871: support test of bath counts in listing card
       * */
      if (listing.models.length === 1) {
        listing.models[0].baths = listing.modelSummary.baths;
      }

      /**
       * SummaryV2 uses `models` param
       * UnitV2 uses `floorplans` param
       */
      modelCollection.add(listing.models, listing.listingType, listing.propertyType);
    });

    return {
      isActive,
      buildingActivated,
      buildingUpdated,
      buildingUserItemTypes: uniq(buildingUserItemTypes),
      listingMinMaxPriceBeds: modelCollection.summarize(filter),
    };
  },
  getBuildingDisplayName(units) {
    let displayName = units[0].address.street;
    units.forEach((unit) => {
      if (unit.address.hideStreet) {
        displayName = unit.displayName;
      }
    });
    return displayName;
  },
  getBuildingPageTitle({ city, state, street, zip }) {
    return `${street}, ${city}, ${state} ${zip}`;
  },
};

export default buildingUtils;

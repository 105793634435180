// @ts-nocheck
/* eslint-enable */
import get from 'lodash/get';
import googleAnalytics from './ga';
import * as zgAnalytics from '../zg-analytics';

export const analyticsEvent = (
  gaEventObj,
  additionalParams = {
    category: '',
    action: '',
    label: '',
    value: '',
    nonInteraction: false,
  },
) => {
  return (dispatch, getState) => {
    if (typeof window === 'undefined' || !window.ga) {
      return;
    }

    // Escape hatch for Clickstream New Lane
    // Where we don't send a legacy analytics object.
    if ('newLaneEvent' in gaEventObj) {
      return;
    }

    const category = additionalParams.category || gaEventObj.category;
    const action = additionalParams.action || gaEventObj.action;
    const value = additionalParams.value || gaEventObj.value; // TODO: Needs to be typeof === integer
    const nonInteraction = additionalParams.nonInteraction || gaEventObj.nonInteraction;
    const pathname = get(getState(), 'location.current.pathname');
    const page = pathname;
    let label = additionalParams.label || gaEventObj.label;
    label = label || getState().location.current.name;

    const fieldObject = {
      action,
      category,
      label,
      page,
      value,
      nonInteraction,
    };

    googleAnalytics.event(fieldObject);

    // Since zAnalytics uses the same API as googleAnalyics.event,
    // we allow zAnalytics to piggyback on any event tracking call
    // fired off throughout web app.
    zgAnalytics.event(fieldObject);
  };
};

import numberUtils from 'app/shared/utils/numberUtils';

class Coordinates {
  lat: number;
  lon: number;

  constructor({ lat = 0, lon = 0 }: { lat?: number; lon?: number } = {}) {
    this.lat = numberUtils.trimDecimal(lat);
    this.lon = numberUtils.trimDecimal(lon);
  }
}

export default Coordinates;

interface InstantTourProviderProps {
  dateOnly?: boolean;
  providerName?: string;
  supportsCancel?: boolean;
}

interface InstantTourProvidersProps {
  inPersonProvider?: InstantTourProviderProps;
  liveVideoProvider?: InstantTourProviderProps;
  selfGuidedProvider?: InstantTourProviderProps;
}

class InstantTourProvider {
  dateOnly?: boolean;
  providerName?: string;
  supportsCancel?: boolean;

  constructor({ dateOnly, providerName, supportsCancel }: InstantTourProviderProps = {}) {
    this.dateOnly = dateOnly;
    this.providerName = providerName;
    this.supportsCancel = supportsCancel;
  }
}

class InstantTourProviders {
  inPersonProvider: InstantTourProvider | null;
  liveVideoProvider: InstantTourProvider | null;
  selfGuidedProvider: InstantTourProvider | null;

  constructor({ inPersonProvider, liveVideoProvider, selfGuidedProvider }: InstantTourProvidersProps = {}) {
    this.inPersonProvider = inPersonProvider ? new InstantTourProvider(inPersonProvider) : null;
    this.liveVideoProvider = liveVideoProvider ? new InstantTourProvider(liveVideoProvider) : null;
    this.selfGuidedProvider = selfGuidedProvider ? new InstantTourProvider(selfGuidedProvider) : null;
  }
}

export default InstantTourProviders;

// @ts-nocheck
/* eslint-enable */
import { Component } from 'react';
import { batch, connect } from 'react-redux';
import cx from 'classnames';
import Helmet from 'react-helmet';
import { Route, Switch, withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import AdActions from 'app/shared/flux/actions/AdActions';
import AppActions from 'app/shared/flux/actions/AppActions';
import AuthActions from 'app/shared/flux/actions/AuthActions';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Components
import AppleLoginRedirectPage from 'app/shared/pages/AppleRedirectPage';
import HeaderNav from 'app/shared/modules/navigation/header/HeaderNav';
import NotFoundPage from 'app/shared/pages/NotFoundPage';
import Notification from 'app/shared/modules/Notification';
import OfflineNotification from 'app/shared/modules/OfflineNotification';
import PopupModal from 'app/shared/modules/popups/PopupModal';

// popup modals used throughout app
import PromptLogin from 'app/shared/modules/auth/PromptLogin';
import PromptFeedback from 'app/shared/modules/PromptFeedback';

// Misc / Utils
import * as S from './styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { setOptOutCookie } from 'app/shared/utils/adTrackingUtils';
import { adUtils_routeShouldLoadGtm } from 'app/shared/utils/adUtils';
import browserUtils from 'app/client/utils/browserUtils';
import clientUtils from 'app/client/utils/clientUtils';
import constants from 'app/shared/constants/ConstantsBundle';
import pathsManifest from 'app/shared/utils/pathsManifest';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import TrackingPixels from 'app/client/TrackingPixels';
import {
  CcpaOptOutPage,
  FreepassRedirectPage,
  FrontPage,
  HeaderFooterTemplate,
  MobileChoicePage,
  SearchResultsPage,
  SplitMapTemplate,
} from 'app/shared/LoadablePages';
import UserItemActions from '../../flux/actions/UserItemActions';
import UserActions from 'app/shared/flux/actions/UserActions';
import { getGlobalLogger } from '@zg-rentals/logger-base';

// PandaCSS
import 'app/styled-system/styles.css';
import './panda.css';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('templates/appTemplate');

class AppTemplate extends Component {
  componentDidMount() {
    const { dispatch, loggedIn, nid, hasOptedOutAdTracking, pathname } = this.props;
    const device = browserUtils.getDevice();

    batch(() => {
      dispatch(AppActions.setDevice(device));
      dispatch(AppActions.setAppStoreBool('clientLoaded', true));
    });

    if (!loggedIn) {
      dispatch(AuthActions.login.info());
    }

    if (loggedIn) {
      dispatch(UserActions.renterProfile.get());
    }

    if (hasOptedOutAdTracking) {
      setOptOutCookie().catch((err) => logError({ error: err, context: 'Error setting OptOut cookie in AppTemplate' }));
    }

    if (nid) {
      dispatch(
        NotificationActions.notifications.track({
          notificationId: nid,
          type: 'visited',
        }),
      );
    }

    let trackAdblockNotDetected;
    const trackAdblockDetected = () => {
      dispatch(analyticsEvent(gaEvents.ABLOCK_DETECTED));
      window.removeEventListener('adblockDetectedEvent', trackAdblockDetected);
      window.removeEventListener('adblockNotDetectedEvent', trackAdblockNotDetected);
    };
    trackAdblockNotDetected = () => {
      dispatch(analyticsEvent(gaEvents.ABLOCK_NOT_DETECTED));
      window.removeEventListener('adblockDetectedEvent', trackAdblockDetected);
      window.removeEventListener('adblockNotDetectedEvent', trackAdblockNotDetected);
    };
    window.addEventListener('adblockDetectedEvent', trackAdblockDetected);
    window.addEventListener('adblockNotDetectedEvent', trackAdblockNotDetected);

    // Track users who are still on the page after 35, 60, and 90 seconds.
    setTimeout(() => {
      dispatch(analyticsEvent(gaEvents.USER_MILESTONE_35));
    }, constants.USER_MILESTONE_35);
    setTimeout(() => {
      dispatch(analyticsEvent(gaEvents.USER_MILESTONE_60));
    }, constants.USER_MILESTONE_60);
    setTimeout(() => {
      dispatch(analyticsEvent(gaEvents.USER_MILESTONE_90));
    }, constants.USER_MILESTONE_90);

    clientUtils.mountDomListeners(this.setDeviceAndWidth, this.updateOnlineStatus);

    if (adUtils_routeShouldLoadGtm(pathname)) {
      dispatch(AdActions.loadGoogleAdApi());
    }

    // setInterval to update cluster settings every 10 min
    window.setInterval(() => {
      logger?.debug(`Fetching new cluster settings`);
      dispatch(AppActions.getClusterSettings());
    }, 600000);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.hamburgerState !== nextProps.hamburgerState) {
      return true;
    } else if (this.props.isMobile !== nextProps.isMobile) {
      return true;
    } else if (this.props.isOnline !== nextProps.isOnline) {
      return true;
    } else if (this.props.loggedIn !== nextProps.loggedIn) {
      return true;
    } else if (this.props.nid !== nextProps.nid) {
      return true;
    } else if (this.props.pathname !== nextProps.pathname) {
      return true;
    } else if (this.props.popupModal.visible !== nextProps.popupModal.visible) {
      return true;
    } else if (this.props.showNotification.visible !== nextProps.showNotification.visible) {
      return true;
    } else if (this.props.xEids.length !== nextProps.xEids.length) {
      return true;
    } else if (this.props.hasOptedOutAdTracking !== nextProps.hasOptedOutAdTracking) {
      return true;
    } else if (this.props.sessionToken !== nextProps.sessionToken) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const { dispatch, loggedIn, hasOptedOutAdTracking, sessionToken, pathname } = this.props;
    // the sessionToken changes after 30 mins of inactivity or when a user opts out of ad cookies
    // at the begining of a new session, check the user's latest opt out status (HPWEB-5709)
    if (loggedIn && sessionToken !== prevProps.sessionToken) {
      dispatch(AuthActions.login.info());
    }

    // if user opts out during an active session
    if (hasOptedOutAdTracking && !prevProps.hasOptedOutAdTracking) {
      setOptOutCookie().catch((err) => logError({ error: err, context: 'Error setting OptOut cookie in AppTemplate' }));
    }

    if (adUtils_routeShouldLoadGtm(pathname)) {
      dispatch(AdActions.loadGoogleAdApi());
    }
  }

  componentWillUnmount() {
    clientUtils.removeDomListeners(this.setDeviceAndWidth, this.updateOnlineStatus);
  }

  serverRouteWillMount(reactContext) {
    const { state, store, trace, ip } = reactContext;
    const { query } = state;
    const dispatch = store.dispatch;

    if (includes(query.ref, 'browserNotification')) {
      const bnQuery = query.ref.split('-');

      dispatch(
        NotificationActions.notifications.track({
          notificationId: bnQuery[1],
          type: 'visited',
        }),
      );
    }

    return [
      store.dispatch(UserItemActions.getUserItems('inquiry')),
      store.dispatch(UserSearchActions.getRecentSearches()),
      store.dispatch(AppActions.getGeoIp({ ip, trace })).catch((error) => {
        store.dispatch(ErrorActions.errorHandler({ error, location: 'AppTemplate#serverRouteWillMount' }));
      }),
    ];
  }
  setDeviceAndWidth = debounce(() => {
    const { dispatch } = this.props;
    const device = browserUtils.getDevice();

    dispatch(AppActions.setDevice(device));
  }, 100);

  updateOnlineStatus = () => {
    const { dispatch, isOnline } = this.props;

    if (navigator.onLine) {
      const comingBackOnline = !isOnline;
      if (comingBackOnline) {
        dispatch(AppActions.setAppStoreBool('isOnline', true));
      }
    } else {
      dispatch(AppActions.setAppStoreBool('isOnline', false));
    }
  };

  handleHideModal = yieldCallback(() => {
    this.props.dispatch(AppActions.hidePopupModal());
  });

  selectPopupModalComponent = (component) => {
    switch (component) {
      case 'PromptFeedback':
        return <PromptFeedback />;
      case 'PromptLogin':
        return <PromptLogin />;
      default:
        logger?.warn('AppTemplate#selectPopupModalComponent: No valid component provided.');
        return;
    }
  };

  render() {
    const { popupModal, xEids, isOnline, showNotification, pathname, isMobile } = this.props;
    const metaDescriptionContent =
      'HotPads is the place to find your place. Map-based apartment and home rental search engine.';

    const metas = [
      { name: 'description', content: metaDescriptionContent },
      { name: 'x-eids', content: xEids },
    ];
    const isForSaleHDP = includes(pathname, 'for-sale') && !routeUtils.isAreaUrl(pathname);

    if (!routeUtils.isPadOrBuildingUrl(pathname)) {
      metas.push({ property: 'og:image', content: '/logoOfficial_vertical_200.png' });
      metas.push({ property: 'og:width', content: '200' });
      metas.push({ property: 'og:height', content: '200' });
    }

    const showHeaderNav =
      !includes(pathname, pathsManifest.MobileChoicePage) &&
      !includes(pathname, pathsManifest.AppleLoginRedirectPage) &&
      !includes(pathname, pathsManifest.CcpaOptOutPage);

    return (
      <>
        <S.AppTemplate
          className={cx('AppTemplate', {
            'AppTemplate-hamburger-active': this.props.hamburgerState,
          })}
          id="app-container"
          role="main"
        >
          <Helmet meta={metas} title="Go ahead. Rent around. | HotPads" />
          {!isOnline && <OfflineNotification />}
          {showHeaderNav && <HeaderNav />}
          <Switch>
            <Route component={FrontPage} path="/" exact />
            <Route component={MobileChoicePage} path={pathsManifest.MobileChoicePage} />
            <Route component={AppleLoginRedirectPage} path={pathsManifest.AppleLoginRedirectPage} />
            <Route component={FreepassRedirectPage} path="/fp*" />
            <Route component={CcpaOptOutPage} path={pathsManifest.CcpaOptOutPage} />
            {isForSaleHDP && <Route component={NotFoundPage} path="*" />}
            <Route
              path="/"
              // eslint-disable-next-line react/jsx-no-bind
              render={(props) => {
                const isAreaUrl = routeUtils.isAreaUrl(pathname);
                const isNearMeUrl = routeUtils.isNearMeUrl(pathname);
                const isPadOrBuildingUrl = routeUtils.isPadOrBuildingUrl(pathname);
                const shouldRenderSearchResultPage = (isAreaUrl || isNearMeUrl) && isMobile;
                const shouldRenderSplitMapTemplate = isAreaUrl || isNearMeUrl || isPadOrBuildingUrl;
                if (shouldRenderSplitMapTemplate) {
                  if (shouldRenderSearchResultPage) {
                    return <SearchResultsPage {...props} />;
                  }

                  return <SplitMapTemplate {...props} />;
                }
                return <HeaderFooterTemplate {...props} />;
              }}
            />
          </Switch>
          {popupModal.visible && (
            <PopupModal onHidePopup={this.handleHideModal} paddingBottom="0" position="top">
              <S.AppTemplateModalContent>
                {this.selectPopupModalComponent(popupModal.component)}
              </S.AppTemplateModalContent>
            </PopupModal>
          )}
          <div role="alert" aria-live="assertive">
            {showNotification.visible && (
              <Notification
                notificationStyle={showNotification.notificationStyle}
                visibleTime={showNotification.visibleTime}
              >
                {showNotification.content}
              </Notification>
            )}
          </div>
          <div id="fb-root" />
          <TrackingPixels />
        </S.AppTemplate>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hamburgerState: state.app.hamburger,
    hasOptedOutAdTracking: state.user.hasOptedOutAdTracking,
    isMobile: state.app.device.screenWidth === 'sm',
    isOnline: state.app.isOnline,
    isInitialSsrPage: state.app.isInitialSsrPage,
    loggedIn: state.user.loggedIn,
    nid: queryUtils.parse(ownProps.location.search).nid,
    pathname: ownProps.location.pathname,
    popupModal: state.app.popupModal,
    showNotification: state.app.showNotification,
    sessionToken: state.user.sessionToken,
    xEids: state.app.xEids,
  };
};

export default withRouter(connect(mapStateToProps, null, null, { pure: false })(AppTemplate));

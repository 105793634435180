// @ts-nocheck
/* eslint-enable */
import snippet from './analytics';
import stringHelper from 'app/shared/utils/stringHelper';
import { initialize as initializeZgAnalytics } from 'app/client/zg-analytics';

/*eslint-disable camelcase */

export function initialize({ debug, trace = false } = {}) {
  let gaAddress;
  if (typeof window === 'undefined') {
    return false;
  }

  if (debug === true || __DEV__) {
    gaAddress = 'https://www.google-analytics.com/analytics_debug.js';
  }

  //Look for OneTrust cookie (OptanonConsent) to determine how to include GA
  //If no OneTrust cookie (user's first visit), load GA normally
  //If the OneTrust cookie does exist (user has been to HP before), load GA through OneTrust so we respect
  //the user's cookie settings. (by adding the appropriate classname and content type to the javascript include.)
  let onetrustEnabled;
  try {
    const cookie = stringHelper.getCookieByName('OptanonConsent');
    if (typeof cookie == 'undefined') {
      onetrustEnabled = false;
    } else {
      onetrustEnabled = true;
    }
  } catch (error) {
    onetrustEnabled = false;
  }

  snippet({
    gaAddress,
    onetrustEnabled,
  });

  if (typeof window.ga !== 'function') {
    return false;
  }

  // Enabling trace debugging will output more verbose information to the console.
  if (trace) {
    window.ga_debug = { trace: true };
  }

  // Initialize DSP
  const { store } = window || {};
  initializeZgAnalytics({
    userToken: store?.getState()?.user?.userToken,
  });
}

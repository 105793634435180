// @ts-nocheck
/* eslint-enable */
import map from 'lodash/map';
import { listingUtils_getCondensedAmenities } from 'app/shared/utils/listingUtils';
import PetPolicy from 'app/shared/models/PetPolicy';
import { RoomDetails } from 'app/shared/models/RoomForRent';

type Amenity = string;
type PetPolicyType = 'CAT' | 'DOG';
type PetPolicyMap = Record<string, PetPolicy | undefined>;

interface HighlightedAmenity {
  persisted: string;
  display: string;
  subtypes: Array<{ display: string }>;
}

interface AmenitiesParams {
  hasAvailableInSelectUnits?: boolean;
  amenities?: Array<Amenity>;
  modelAmenities?: Array<Amenity>;
  highlightedAmenities?: Array<HighlightedAmenity>;
  petPolicies?: Array<{ petType: PetPolicyType; allowed: boolean; additionalFee: number }> | null;
}

interface RoomForRentParams {
  roomIsFurnished?: boolean;
  hasPrivateBath?: boolean;
}

// Helper function to get amenities with room for rent specifics
const getAmenitiesWithRoomForRent = (
  amenities: Array<Amenity>,
  roomIsFurnished: boolean,
  hasPrivateBath: boolean,
): Array<Amenity> => {
  const roomForRentAmenities: Array<Amenity> = [];
  if (roomIsFurnished) {
    roomForRentAmenities.push('Room is furnished');
  }
  if (hasPrivateBath) {
    roomForRentAmenities.push('Room has private bath');
  }
  return [...roomForRentAmenities, ...amenities];
};

class Amenities {
  hasAvailableInSelectUnits: boolean;
  amenities: Array<Amenity>;
  modelAmenities: Array<Amenity>;
  highlights: Array<{ id: string; display: string; types: Array<string> }>;
  condensedAmenities: Record<string, any>;
  petPolicies: PetPolicyMap | null;

  constructor(
    {
      hasAvailableInSelectUnits = false,
      amenities = [],
      modelAmenities = [],
      highlightedAmenities = [],
      petPolicies = null,
    }: AmenitiesParams = {},
    { roomIsFurnished = false, hasPrivateBath = false }: RoomForRentParams = new RoomDetails(),
  ) {
    this.hasAvailableInSelectUnits = Boolean(hasAvailableInSelectUnits);
    this.amenities = map(getAmenitiesWithRoomForRent(amenities, roomIsFurnished, hasPrivateBath), (propertyAmenity) =>
      String(propertyAmenity),
    );
    this.modelAmenities = map(modelAmenities, (modelAmenity) => String(modelAmenity));
    this.highlights = map(highlightedAmenities, (category) => {
      const { persisted, display, subtypes } = category;

      return {
        id: persisted,
        display,
        types: map(subtypes, (subtype) => subtype.display),
      };
    });
    this.condensedAmenities = listingUtils_getCondensedAmenities(highlightedAmenities) || {};
    this.petPolicies = petPolicies
      ? petPolicies.reduce((formattedPolicies: PetPolicyMap, policy) => {
          const currentPetType = policy.petType.toLowerCase();

          if (currentPetType === 'cat' || currentPetType === 'dog') {
            formattedPolicies[currentPetType] = new PetPolicy(policy);
          }

          return formattedPolicies;
        }, {})
      : null;
  }
}

export default Amenities;

// @ts-nocheck
/* eslint-enable */
import Promise from 'promise';

import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/notification');

const NotificationActions = {
  email: {
    getUserSubscriptions(email) {
      return function (dispatch) {
        if (email) {
          return dispatch(api.user.email.subscriptions(email)).then((result) => {
            dispatch({
              type: constants.USER_LOAD_SUBSCRIPTIONS,
              payload: result.data,
            });
            return result;
          });
        } else {
          return Promise.resolve({});
        }
      };
    },
    updateUserSubscriptions(subscriptions, email) {
      return function (dispatch) {
        return dispatch(api.user.email.unsubscribe(subscriptions, email)).then((result) => {
          dispatch({
            type: constants.USER_LOAD_SUBSCRIPTIONS,
            payload: result.data,
          });
          return result;
        });
      };
    },
    cancelSearches({ cancelHash, email, cancelSavedSearch } = {}) {
      return function (dispatch) {
        return dispatch(api.user.email.cancelSearches(cancelHash, email, cancelSavedSearch));
      };
    },
    updateSearchFrequency({ email, frequency, hash, searchId } = {}) {
      return function (dispatch) {
        return dispatch(api.user.email.updateSearchFrequency(email, frequency, hash, searchId));
      };
    },
    updateEmailOptIn(emailOptIn, emailOptInSource) {
      return function (dispatch) {
        dispatch({
          type: constants.USER_UPDATE_EMAIL_OPT_IN,
        });

        return dispatch(api.user.email.updateEmailOptIn(emailOptIn, emailOptInSource));
      };
    },
  },
  notifications: {
    track({ notificationId, type, ref } = {}) {
      return function (dispatch) {
        if (!notificationId) {
          logger?.warn('NotificationActions#track#notification No notificationId provided');
          return;
        }

        return dispatch(
          api.notifications.track({
            notificationId,
            type,
            ref: ref || 'BROWSER',
          }),
        );
      };
    },
    updateSettings(options) {
      return function (dispatch) {
        return dispatch(api.notifications.update(options));
      };
    },
    getSettings() {
      return function (dispatch) {
        return dispatch(api.notifications.getAll())
          .then((response) => {
            if (response && response.data) {
              return dispatch({
                type: constants.SET_NOTIFICATION_SETTINGS,
                payload: response.data,
              });
            }
          })
          .catch((err) => {
            logError({
              error: err,
              errorType: 'NotificationActionsError',
              context: 'Error getting user notification settings',
            });
            return false;
          });
      };
    },
  },
};

export default NotificationActions;

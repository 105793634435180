import type { Logger } from '@zg-rentals/logger-browser';
import type { Metric } from 'web-vitals';
import type { Monitor, StatController } from '@zg-rentals/monitor-base';
import { MonitorPlugin } from '@zg-rentals/monitor-base';
import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

export class WebVitalsPlugin extends MonitorPlugin {
  constructor(logger?: Logger) {
    super('WebVitalsPlugin', logger);
  }

  onInitialize(monitor: Monitor, logger?: Logger): void | StatController {
    const statController = super.onInitialize(monitor, logger);

    if (typeof window !== 'undefined') {
      const record = (label: string, good: number, needsImprovement: number) => (metric: Metric) => {
        monitor.measurementWithGauges({
          name: `WebVital ${label}`,
          amount: metric.value,
        });

        if (metric.value <= good) {
          monitor.count({ name: `WebVital ${label} good` });
        } else if (metric.value > good && metric.value <= needsImprovement) {
          monitor.count({ name: `WebVital ${label} needs_improvement` });
        } else {
          monitor.count({ name: `WebVital ${label} poor` });
        }
      };

      onCLS(record('cumulative_layout_shift', 0.1, 0.25));

      onINP(record('interaction_to_next_paint', 200, 500));

      onLCP(record('largest_contentful_paint', 2500, 4000));

      onFCP(record('first_contentful_paint', 1800, 3000));

      onTTFB(record('time_to_first_byte', 800, 1800));
    }

    return statController;
  }
}

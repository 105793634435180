interface RecencyTimeProps {
  displayDiff?: string;
  secondsDiff?: number;
  minutesDiff?: number;
  hoursDiff?: number;
  daysDiff?: number;
  weeksDiff?: number;
  monthsDiff?: number;
}

class RecencyTime {
  displayDiff: string;
  secondsDiff: number;
  minutesDiff: number;
  hoursDiff: number;
  daysDiff: number;
  weeksDiff: number;
  monthsDiff: number;

  constructor({
    displayDiff = '',
    secondsDiff = 0,
    minutesDiff = 0,
    hoursDiff = 0,
    daysDiff = 0,
    weeksDiff = 0,
    monthsDiff = 0,
  }: RecencyTimeProps = {}) {
    this.displayDiff = String(displayDiff);
    this.secondsDiff = Number(secondsDiff);
    this.minutesDiff = Number(minutesDiff);
    this.hoursDiff = Number(hoursDiff);
    this.daysDiff = Number(daysDiff);
    this.weeksDiff = Number(weeksDiff);
    this.monthsDiff = Number(monthsDiff);
  }
}

export default RecencyTime;

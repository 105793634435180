// @ts-nocheck
/* eslint-enable */
import { combineReducers } from 'redux';
import analytics from './analytics';
import app from './app';
import area from './area';
import currentListingDetails from './currentListingDetails';
import DEPRECATED_filter from './DEPRECATED_filter';
import filter from './filter';
import listings from './listings';
import user from './user';
import location from './location';
import ads from './ads';
import fetchListing from './fetchListing';
import geolocation from './geolocation';
import view from './view';
import seo from './seo';
import homehub from './homehub';

const rootReducer = () =>
  combineReducers({
    ads,
    analytics,
    app,
    area,
    currentListingDetails,
    fetchListing,
    DEPRECATED_filter,
    filter,
    geolocation,
    listings,
    location,
    user,
    view,
    seo,
    homehub,
  });

export default rootReducer;

import styled, { css } from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { fontSize } from 'app/shared/styles/_fontsize';

const TextTiny = css`
  font-size: ${fontSize['$font-size-tiny']};
  line-height: ${fontSize['$line-height-tiny']};
`;

const TextSm = css`
  font-size: ${fontSize['$font-size-sm']};
  line-height: ${fontSize['$line-height-sm']};
`;

const TextMd = css`
  font-size: ${fontSize['$font-size-md']};
  line-height: ${fontSize['$line-height-md']};
`;

const TextLg = css`
  font-size: ${fontSize['$font-size-lg']};
  line-height: ${fontSize['$line-height-lg']};
`;

const TextXl = css`
  font-size: ${fontSize['$font-size-xl']};
  line-height: ${fontSize['$line-height-xl']};
`;

export const TEXT_SIZE = {
  tiny: TextTiny,
  sm: TextSm,
  md: TextMd,
  lg: TextLg,
  xl: TextXl,
};

export type TextSizeKeys = keyof typeof TEXT_SIZE;

const MD_RESPONSIVE_SIZE = {
  tiny: TextTiny,
  sm: TextSm,
  md: TextMd,
  lg: TextLg,
  xl: TextXl,
};

const LG_RESPONSIVE_SIZE = {
  tiny: TextTiny,
  sm: TextSm,
  md: TextMd,
  lg: TextLg,
  xl: TextXl,
};

const XL_RESPONSIVE_SIZE = {
  tiny: TextTiny,
  sm: TextSm,
  md: TextMd,
  lg: TextLg,
  xl: TextXl,
};

interface ResponsiveSizes {
  smAndUp?: keyof typeof TEXT_SIZE;
  mdAndUp?: keyof typeof MD_RESPONSIVE_SIZE;
  lgAndUp?: keyof typeof LG_RESPONSIVE_SIZE;
  xlAndUp?: keyof typeof XL_RESPONSIVE_SIZE;
}

interface TextProps {
  size?: keyof typeof TEXT_SIZE;
  responsive?: ResponsiveSizes;
}

export const Text = styled.span<TextProps>`
  ${(props) => props.size && TEXT_SIZE[props.size]}
  ${(props) =>
    props.responsive &&
    props.responsive.smAndUp &&
    `
        ${TEXT_SIZE[props.responsive.smAndUp]}
    `}
    ${(props) =>
    props.responsive &&
    props.responsive.mdAndUp &&
    `
        @media ${viewports['md-and-up']} {
            ${MD_RESPONSIVE_SIZE[props.responsive.mdAndUp]};
        }
    `}
    ${(props) =>
    props.responsive &&
    props.responsive.lgAndUp &&
    `
        @media ${viewports['lg-and-up']} {
            ${LG_RESPONSIVE_SIZE[props.responsive.lgAndUp]};
        }
    `}
    ${(props) =>
    props.responsive &&
    props.responsive.xlAndUp &&
    `
        @media ${viewports['xl-and-up']} {
            ${XL_RESPONSIVE_SIZE[props.responsive.xlAndUp]};
        }
    `}
`;

// @ts-nocheck
/* eslint-enable */
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';

import { createCookieString } from 'app/shared/utils/cookieParser';

function addCommonHeaders(requestHeaders, headersFromArg, { originalUri, location = {} }) {
  requestHeaders['X-Original-Uri'] = originalUri;
  requestHeaders['Content-Type'] = headersFromArg.contentType ? headersFromArg.contentType : 'application/json';
  requestHeaders.Accept = headersFromArg.accept ? headersFromArg.accept : 'application/json,text/html';
  requestHeaders['X-Build-Id'] = __BUILD_NUMBER__ || 42;
  requestHeaders['ZG-Via-Server'] = 'hotpads-web';

  if (location.current && location.current.query && location.current.query.runscope) {
    requestHeaders['X-HP-Tester'] = 'runscope';
  }
}

function addClientSpecificHeaders(requestHeaders, { requestId }) {
  requestHeaders['X-Server-Rendered'] = false;
  requestHeaders['x-original-request-id'] = requestId;
}

function addServerSpecificHeaders(requestHeaders, { ip, userAgent, serverSideCookies }) {
  requestHeaders['X-Server-Rendered'] = true;
  requestHeaders['x-client-ip'] = ip;
  requestHeaders['User-Agent'] = userAgent;
  requestHeaders.Cookie = createCookieString(serverSideCookies);
}

function addHttpMethodSpecificHeaders(
  requestHeaders,
  { httpMethod, csrfToken, userToken, logger, serverSideCookies = {} },
) {
  if (httpMethod && httpMethod.toLowerCase() !== 'get' && csrfToken) {
    requestHeaders.csrfToken = csrfToken;

    logger &&
      logger.debug(
        {
          csrfToken,
          userToken,
          serverSideCookies,
        },
        'Adding csrfToken to non-GET request',
      );
  }
}

function removeUndefinedValues(requestHeaders) {
  Object.keys(requestHeaders).forEach((key) => isUndefined(requestHeaders[key]) && delete requestHeaders[key]);
}

function getRequestHeaders(
  headersFromArg = {},
  { httpMethod, originalUri, requestId, ip, csrfToken, userToken, location, userAgent, serverSideCookies, logger } = {},
) {
  const requestHeaders = {};

  addCommonHeaders(requestHeaders, headersFromArg, { originalUri, location });
  addHttpMethodSpecificHeaders(requestHeaders, {
    httpMethod,
    csrfToken,
    userToken,
    logger,
    serverSideCookies,
  });

  if (__BROWSER__) {
    addClientSpecificHeaders(requestHeaders, {
      requestId,
    });
  } else {
    addServerSpecificHeaders(requestHeaders, {
      ip,
      userAgent,
      serverSideCookies,
    });
  }

  removeUndefinedValues(requestHeaders);

  // final ovewrite
  const mergedRequestHeaders = merge({}, requestHeaders, headersFromArg);

  logger &&
    logger.debug(
      {
        headers: mergedRequestHeaders,
        requestId,
      },
      'final headers for api request',
    );

  return mergedRequestHeaders;
}

export default getRequestHeaders;

import type { FlattenSimpleInterpolation, ThemedStyledProps } from 'styled-components';
import styled, { css } from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { noUserSelect, onHover, smoothTransition } from 'app/shared/styles/_mixins';
import LinkController from 'app/shared/modules/LinkController';

export type LinkType =
  | 'accent'
  | 'accent-light'
  | 'alert'
  | 'button-link'
  | 'default'
  | 'inherit'
  | 'inverted'
  | 'inverted-teal'
  | 'primary'
  | 'secondary'
  | 'text-color'
  | 'underline';

interface LinkerProps {
  className?: string;
  disabled?: boolean;
  linkType?: LinkType;
}

const styles = () => css<ThemedStyledProps<LinkerProps, any>>`
  cursor: pointer;
  ${smoothTransition}
  ${noUserSelect}
    text-decoration: none;

  ${onHover(
    `
        text-decoration: underline;
    ` as unknown as FlattenSimpleInterpolation,
  )}
  &:active {
    text-decoration: underline;
  }

  ${(props) =>
    props.linkType === 'button-link' &&
    css`
      display: flex;
      border: 1px solid ${colors['$hpx-teal-500']};
      padding: 6px 12px;
      border-radius: 4px;
      height: 48px;
      align-items: center;
    `};

  ${(props) =>
    props.linkType === 'default' &&
    `
        color: ${colors['$hpx-teal-500']};
        ${onHover(
          `
            color: ${colors['$hpx-teal-500']};
        ` as unknown as FlattenSimpleInterpolation,
        )}
        &:active {
            color: ${colors['$hpx-teal-500']};
        }
    `}

  ${(props) =>
    props.linkType === 'secondary' &&
    `
        text-decoration: underline;
        color: ${colors['$hpx-grey-600']};
    `}

    ${(props) =>
    props.linkType === 'accent' &&
    `
        color: ${colors['$hpx-grey-600']};
    `}

    ${(props) =>
    props.linkType === 'alert' &&
    `
        color: ${colors['$hpx-red-400']};
    `}

    ${(props) =>
    props.linkType === 'inverted' &&
    `
        font-weight: bold;
        color: ${colors['$hpx-white']};
    `}

    ${(props) =>
    props.linkType === 'inverted-teal' &&
    `
        font-weight: bold;
        color: ${colors['$hpx-teal-300']};
    `}

    ${(props) =>
    props.linkType === 'accent-light' &&
    `
        color: ${colors['$hpx-grey-500']};
    `}

    ${(props) =>
    props.linkType === 'underline' &&
    `
        color: ${colors['$hpx-blue-500']};
        text-decoration: underline;
    `}

    ${(props) =>
    props.linkType === 'text-color' &&
    `
        color: ${colors['$hpx-blue-600']};
        ${onHover(`color: ${colors['$hpx-blue-500']}` as unknown as FlattenSimpleInterpolation)}
    `}

    ${(props) =>
    props.linkType === 'inherit' &&
    `
        color: inherit;
        ${onHover(`color: inherit` as unknown as FlattenSimpleInterpolation)}
    `}

    ${(props) =>
    props.disabled &&
    `
        pointer-events: none;
    `}
`;

export const Linker = styled(LinkController)<LinkerProps>(styles);
export const TextButton = styled.button<LinkerProps>(styles);

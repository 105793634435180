// @ts-nocheck
/* eslint-enable */
// App
import React, { Fragment } from 'react';
import Row from 'app/shared/core/Row';
import SkeletonTitle from 'app/shared/modules/skeleton/SkeletonTitle';

const SkeletonSuggestedRentals = () => {
  return (
    <Fragment>
      <Row size="sm">
        <SkeletonTitle width="80%" />
      </Row>
      <Row size="sm">
        <SkeletonTitle width="50%" />
      </Row>
      <Row size="sm">
        <SkeletonTitle width="70%" />
      </Row>
      <Row size="sm">
        <SkeletonTitle width="60%" />
      </Row>
      <Row size="sm">
        <SkeletonTitle width="100%" />
      </Row>
    </Fragment>
  );
};

export default SkeletonSuggestedRentals;

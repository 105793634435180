interface ScheduledTourProps {
  tourId?: string;
  listingAlias?: string;
  schedulingStatus?: string;
  tourType?: string;
  supportsCancel?: string;
  startTime?: string;
  endTime?: string;
  zoneId?: string;
}

class ScheduledTour {
  tourId: string;
  listingAlias: string;
  schedulingStatus: string;
  tourType: string;
  supportsCancel: string;
  startTime: string;
  endTime: string;
  zoneId: string;

  constructor({
    tourId = '',
    listingAlias = '',
    schedulingStatus = '',
    tourType = '',
    supportsCancel = '',
    startTime = '',
    endTime = '',
    zoneId = '',
  }: ScheduledTourProps = {}) {
    this.tourId = tourId;
    this.listingAlias = listingAlias;
    this.schedulingStatus = schedulingStatus;
    this.tourType = tourType;
    this.supportsCancel = supportsCancel;
    this.startTime = startTime;
    this.endTime = endTime;
    this.zoneId = zoneId;
  }
}

export default ScheduledTour;

// @ts-nocheck
/* eslint-enable */
import last from 'lodash/last';
import {
  listingUtils_bathDisplay,
  listingUtils_bedDisplay,
  listingUtils_displayAllAvailableBeds,
  listingUtils_getPriceSummaryDisplay,
  listingUtils_sqftDisplay,
} from 'app/shared/utils/listingUtils';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';
import { DEFAULT } from 'app/shared/models/Filter';
/* eslint-disable no-undefined */

const LARGEST = Number.POSITIVE_INFINITY;
const SMALLEST = Number.NEGATIVE_INFINITY;

function between(value, min, max) {
  if (value === undefined) {
    return true;
  }
  return value >= min && value <= max;
}

function largest(val1, val2) {
  return val1 > val2 ? val1 : val2;
}

function smallest(val1, val2) {
  return val1 < val2 ? val1 : val2;
}

function smallestLargerThanZero(val1, val2) {
  if (val2 === 0) {
    return val1;
  }
  return smallest(val1, val2);
}

function eitherOr(val1, val2) {
  const num1 = Number(val1);
  const num2 = Number(val2);

  if (num1 >= 0) {
    return num1;
  }
  if (num2 >= 0) {
    return num2;
  }
}

function asNumber(value, defaultValue) {
  const number = Number(value);
  if (!number) {
    return defaultValue;
  }
  return number;
}

function minMaxOfList(list = []) {
  const fixed = list.map((qty) => {
    return Number(qty.replace('8plus', LARGEST));
  });

  return {
    min: fixed[0] || SMALLEST,
    max: last(fixed) || LARGEST,
  };
}

function defaultTo0(value) {
  if (!value || value === Infinity || value === -Infinity) {
    return 0;
  }
  return value;
}

function ModelCollection() {
  if (!(this instanceof ModelCollection)) {
    return new ModelCollection();
  }
  this.excludeBedAndBathDisplay = false;
  this.models = [];
}

ModelCollection.prototype.add = function (models, listingType, propertyType) {
  if (!isArray(models)) {
    return;
  }
  if (listingType === 'land' || propertyType === 'land') {
    this.excludeBedAndBathDisplay = true;
  }

  models.forEach((model) => {
    // Handle different shapes of model/floorplan
    const {
      // New model has
      numBeds,
      lowPrice,
      highPrice,
      // floorplan has
      beds,
      baths,
      low,
      high,
      sqft,
    } = model;

    this.models.push({
      lowPrice: eitherOr(low, lowPrice),
      highPrice: eitherOr(high || low, highPrice || lowPrice),
      beds: eitherOr(beds, numBeds),
      baths: eitherOr(baths),
      sqft: eitherOr(sqft),
    });
  });
};

ModelCollection.prototype.summarize = function (filter = {}) {
  let minPrice = LARGEST;
  let maxPrice = SMALLEST;
  let minBeds = LARGEST;
  let maxBeds = SMALLEST;
  let minBaths = LARGEST;
  let maxBaths = SMALLEST;
  let minSqft = LARGEST;
  let maxSqft = SMALLEST;

  const minPriceFilter = asNumber(filter?.price?.min, SMALLEST);
  const maxPriceFilter = asNumber(filter?.price?.max, LARGEST);
  const minSqftFilter = asNumber(filter?.sqft?.min, SMALLEST);
  const maxSqftFilter = asNumber(filter?.sqft?.max, LARGEST);
  const adaptedFilter = !isEmpty(filter) ? adapt_reduxToJava({ filter }) : adapt_reduxToJava({ filter: DEFAULT });
  const arrayAdaptedBeds = adaptedFilter.bedrooms.split(',');
  const arrayAdaptedBaths = adaptedFilter.bathrooms.split(',');
  const { min: minBedsFilter, max: maxBedsFilter } = minMaxOfList(arrayAdaptedBeds);
  const { min: minBathsFilter, max: maxBathsFilter } = minMaxOfList(arrayAdaptedBaths);

  function suitable(model) {
    return (
      model.lowPrice <= maxPriceFilter &&
      model.highPrice >= minPriceFilter &&
      between(model.sqft, minSqftFilter, maxSqftFilter) &&
      between(model.baths, minBathsFilter, maxBathsFilter) &&
      between(model.beds, minBedsFilter, maxBedsFilter)
    );
  }

  this.models.forEach((model) => {
    if (!suitable(model)) {
      return;
    }

    minPrice = smallestLargerThanZero(minPrice, model.lowPrice);
    maxPrice = largest(maxPrice, model.highPrice);
    minBeds = smallest(minBeds, model.beds);
    maxBeds = largest(maxBeds, model.beds);
    minBaths = smallest(minBaths, model.baths);
    maxBaths = largest(maxBaths, model.baths);
    minSqft = smallest(minSqft, model.sqft);
    maxSqft = largest(maxSqft, model.sqft);
  });

  const bedDisplay = this.excludeBedAndBathDisplay ? '' : listingUtils_bedDisplay(minBeds, maxBeds);
  const bedDisplayCompact = this.excludeBedAndBathDisplay ? '' : listingUtils_bedDisplay(minBeds, maxBeds, 'compact');
  const bedDisplaySemiCompact = this.excludeBedAndBathDisplay ? '' : listingUtils_bedDisplay(minBeds, maxBeds, '', '-');
  const bedDisplayAllAvailable = this.excludeBedAndBathDisplay ? '' : listingUtils_displayAllAvailableBeds(this.models);
  const bathDisplay = this.excludeBedAndBathDisplay ? '' : listingUtils_bathDisplay(minBaths, maxBaths);
  const bathDisplayCompact = this.excludeBedAndBathDisplay
    ? ''
    : listingUtils_bathDisplay(minBaths, maxBaths, 'compact');
  const bathDisplaySemiCompact = this.excludeBedAndBathDisplay
    ? ''
    : listingUtils_bathDisplay(minBaths, maxBaths, '', '-');
  const priceDisplay = listingUtils_getPriceSummaryDisplay(minPrice, maxPrice);
  const priceDisplayRange = listingUtils_getPriceSummaryDisplay(minPrice, maxPrice, true);
  const sqftDisplay = listingUtils_sqftDisplay(minSqft, maxSqft);

  minPrice = defaultTo0(minPrice);
  maxPrice = defaultTo0(maxPrice);
  minBeds = defaultTo0(minBeds);
  maxBeds = defaultTo0(maxBeds);
  minBaths = defaultTo0(minBaths);
  maxBaths = defaultTo0(maxBaths);
  minSqft = defaultTo0(minSqft);
  maxSqft = defaultTo0(maxSqft);

  return {
    DATA_MODEL: 'ModelCollection',
    minPrice,
    maxPrice,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minSqft,
    maxSqft,
    bedDisplay,
    bedDisplayAllAvailable,
    bathDisplay,
    priceDisplay,
    priceDisplayRange,
    sqftDisplay,
    bedDisplayCompact,
    bedDisplaySemiCompact,
    bathDisplayCompact,
    bathDisplaySemiCompact,
  };
};

export default ModelCollection;

import React from 'react';
import { colors } from 'app/shared/styles/_colors';

interface SkeletonTitlePropType {
  backgroundColor: string;
  width: string;
  height: string;
}
const SkeletonTitle = ({
  backgroundColor = colors['$hpx-grey-100'],
  width = '100%',
  height = '25px',
}: SkeletonTitlePropType) => {
  return <div className="SkeletonTitle" style={{ backgroundColor, height, width }} />;
};

export default SkeletonTitle;

// @ts-nocheck
/* eslint-enable */
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('utils/app');

const appUtils = {
  saveScrollPosition() {
    if (window) {
      this.scrollPosition = window.pageYOffset;
    }
  },
  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
    this.scrollPosition = null;
  },
  lockVerticalScrolling({ isForPortal = false } = {}) {
    // since we the body tag is not encompassed within the react app
    // it must be manually modified like this.
    if (!__BROWSER__) {
      logger?.info('Need access to the DOM to lock vertical scroll');

      return;
    }

    if (isForPortal) {
      document.body.classList.add('lock-vertical-scroll-portal');
    } else {
      document.body.classList.add('lock-vertical-scroll');
    }
  },
  unlockVerticalScrolling() {
    if (__BROWSER__) {
      document.body.classList.remove('lock-vertical-scroll');
      document.body.classList.remove('lock-vertical-scroll-portal');
    }
  },
  remapPropertyLinks(segment = {}) {
    // Remap certain property types to different types.
    /*
            Example:
            segment = {
                name: "Los Angeles Land For Sale"
                uri: "/los-angeles-ca/land-for-sale"
            }

            Remaps to:
            segment = {
                name: "Los Angeles Homes for Sale"
                uri: "/los-angeles-ca/homes-for-sale"
            }
        */

    if (!segment.uri) {
      return;
    }

    const splitSearchSlug = segment.uri.split('/');
    const searchSlug = splitSearchSlug.slice(-1)[0];

    if (searchSlug === 'duplexes-for-rent') {
      splitSearchSlug[splitSearchSlug.length - 1] = 'houses-for-rent';
      segment.uri = splitSearchSlug.join('/');
    }

    if (segment.name.indexOf('Duplexes') > -1) {
      const re = /Duplexes/g;
      segment.name = segment.name.replace(re, 'Homes');
    } else if (segment.name.indexOf('Lots and Land') > -1) {
      const re = /Lots and Land/g;
      segment.name = segment.name.replace(re, 'Homes');
    }

    return segment;
  },
};

export default appUtils;

import type { BrowserMonitor } from '@zg-rentals/monitor-browser';
import type { Span, Tags, Trace } from '@zg-rentals/trace-base';
import { Tracer } from '@zg-rentals/trace-base';

export const TRACE_COOKIE_NAME = 'rjs-trace';
export const TRACE_COOKIE_DURATION_SEC = 31536000;
export const SESSION_ID_LENGTH = 26;
export const TRACE_ID_LENGTH = 32;

export class BrowserTracer extends Tracer {
  spanStack: Set<Span> = new Set();

  sessionId = this.makeID();
  currentTrace = this.startNewTrace();

  constructor(public readonly monitor: BrowserMonitor) {
    super();
  }

  init() {
    const [priorSessionId] = this.getTraceCookie().split(':');
    if (priorSessionId) {
      this.sessionId = priorSessionId;
    }

    // Reset current trace (includes session ID which may have changed between
    // constructor and init())
    this.currentTrace = this.startNewTrace();

    this.tracePageViews();
    this.traceAjax();

    return this;
  }

  tracePageViews() {
    // Clear any existing trace ID,
    // because it represents the page load that just occurred.
    this.setTraceCookie('');
    // Before this page is unloaded, set a new trace ID -
    // this will be the trace ID for the subsequent page load.
    window.addEventListener('beforeunload', () => {
      this.setTraceCookie(this.makeID());
    });
  }

  makeTraceID() {
    let currentTimeMs: number;
    if (typeof window !== 'undefined' && window.performance?.timeOrigin) {
      // Includes microsecond digits
      currentTimeMs = window.performance.now() + window.performance.timeOrigin;
    } else {
      currentTimeMs = new Date().getTime();
    }
    const currentTimeNs = currentTimeMs * 1_000_000;
    const formattedTimestamp = currentTimeNs.toString(16).padStart(16, '0').substring(0, 16);
    return formattedTimestamp + this.makeID(16);
  }

  traceAjax() {
    this.onAjaxRequest(() => this.setTraceCookie(this.makeTraceID()));
    this.onAjaxResponse(() => this.setTraceCookie(''));
  }

  onAjaxRequest(callback: () => void) {
    const fetch = window.fetch;
    window.fetch = (...args): Promise<Response> => {
      callback();
      return fetch(...args);
    };
    const send = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
      callback();
      return send.call(this, ...args);
    };
  }

  onAjaxResponse(callback: () => void) {
    const fetch = window.fetch;
    window.fetch = (...args): Promise<Response> => {
      return fetch(...args).finally(callback);
    };
    const send = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
      this.addEventListener('load', callback);
      this.addEventListener('error', callback);
      return send.call(this, ...args);
    };
  }

  getTraceCookie() {
    const traceCookiePrefix = `${TRACE_COOKIE_NAME}=`;
    const cookieRows = document.cookie.split('; ');
    const traceCookie = cookieRows.find((row) => row.startsWith(traceCookiePrefix)) || '';
    return traceCookie.replace(traceCookiePrefix, '');
  }

  setTraceCookie(traceId = '') {
    const saveTraceFlag = window.location.search.match(/[?&]debug([^\w]|$)/i) ? '1' : '';
    document.cookie = `${TRACE_COOKIE_NAME}=${this.sessionId}:${traceId}:${saveTraceFlag}; path=/; max-age=${TRACE_COOKIE_DURATION_SEC}; samesite=strict`;
    this.startNewTrace();
  }

  // A trace is a related group of spans (units of work).
  // On the client side, a trace can describe one of two things:
  // - Spans that occurred between two network requests
  // - A network request
  startNewTrace(): Trace {
    this.currentTrace = {
      traceId: this.makeTraceID(),
      sessionId: this.sessionId,
      doSave: true,
      tags: {},
    };

    return this.currentTrace;
  }

  startSpan(name?: string, tags: Tags = {}): Span {
    const startTime = Date.now();
    const span = {
      spanId: this.makeID(),
      traceId: this.currentTrace.traceId,
      finish: (error?: Error) => {
        this.saveSpan(name, startTime, tags, error);
        this.spanStack.delete(span);
      },
      getTags: () => tags,
      setTags: (_tags: Tags) => {
        Object.assign(tags, _tags);
      },
    };
    this.spanStack.add(span);
    return span;
  }

  makeID(length = SESSION_ID_LENGTH) {
    let id = '';
    while (id.length < length) {
      id += Math.random().toString(16).slice(2);
    }
    return id.slice(0, length);
  }

  saveSpan(name: string = 'anonymous', startTime: number, tags: Record<string, unknown> = {}, error?: Error) {
    const duration = Date.now() - startTime;
    if (error) {
      this.monitor.count({
        name: `SpanError ${name}`,
        options: {
          tags: {
            ...tags,
            duration,
            error: error.message,
          },
        },
      });
    } else if (this.currentTrace.doSave) {
      this.monitor.gauges({
        name: `SpanDuration ${name}`,
        amount: duration,
        options: {
          tags,
        },
      });
    }
  }

  trace<T>(fn: (...args: Array<unknown>) => T, name = fn.name, tags?: Record<string, unknown>): T {
    const span = this.startSpan(name, tags);
    if (Tracer.isAsyncFunction(fn)) {
      return fn(span.finish) as T;
    }
    const result = fn();
    if (result instanceof Promise) {
      return result
        .then((ret) => {
          span.finish();
          return ret;
        })
        .catch((error) => {
          span.finish(error);
          throw error;
        }) as unknown as T;
    }
    span.finish();
    return result as T;
  }

  getCurrentTrace(): Trace {
    return this.currentTrace!;
  }

  getCurrentSpan(): Span | undefined {
    return Array.from(this.spanStack).pop();
  }
}

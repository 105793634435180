import ky, { HTTPError } from 'ky';
import type { KyInstance, SearchParamsOption } from 'ky';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';
import { makeModuleInstance } from '@zg-rentals/util';

export type Client = KyInstance;
export { HTTPError };

export type ClientOptions = Parameters<typeof ky.extend>[0];

export type SearchParams = SearchParamsOption;

const StartTime = Symbol('StartTime');

export const createClient = ({
  logging,
  ...options
}: ClientOptions & { logging?: { slowRequestTimeout: number } } = {}): Client =>
  ky
    .create({
      hooks: {
        beforeRequest: [
          (request, requestOptions) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (requestOptions as any)[StartTime] = Date.now();

            getGlobalLogger('http-client')?.debug(
              {
                url: request.url,
                method: request.method,
              },
              'beforeRequest',
            );
          },
        ],

        afterResponse: [
          (request, requestOptions, response) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const latencyMs = (Date.now() - (requestOptions as any)[StartTime]) as number;

            const logger = getGlobalLogger('http-client');
            if (logging?.slowRequestTimeout != null && latencyMs >= logging.slowRequestTimeout) {
              logger?.warn(
                {
                  url: request.url,
                  method: request.method,
                  status: response.status,
                  latencyMs,
                },
                'afterResponse slow request',
              );
            } else {
              logger?.debug(
                {
                  url: request.url,
                  method: request.method,
                  status: response.status,
                  latencyMs,
                },
                'afterResponse',
              );
            }
          },
        ],

        beforeError: [
          (error) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const latencyMs = (Date.now() - (error.options as any)[StartTime]) as number;

            logError({
              error,
              context: {
                url: error.request.url,
                method: error.request.method,
                status: error.response.status,
                latencyMs,
                traceparent: error.response.headers.get('traceparent'),
                eid: error.response.headers.get('x-eid'),
              },
              errorType: 'HTTPError',
            });

            return error;
          },
        ],
      },
    })
    .extend(options);

export const { get: getClient, set: setClient } = makeModuleInstance(createClient());

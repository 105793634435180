// @ts-nocheck
/* eslint-enable */
import compact from 'lodash/compact';
import InstantTourProviders from './InstantTourProviders';
import { get3dTourUrlFlag } from '../utils/listingUtils';
import { formatterV2_joinSentences } from 'app/shared/utils/formatterV2';
import { RoomForRent } from 'app/shared/models/RoomForRent';
import FeeOrDeposit from './FeeOrDeposit';

const Details = function (
  {
    studentHousing = false,
    seniorHousing = false,
    furnished = false,
    rental = false,
    demoted = false,
    allowWebsite = false,
    title = '',
    fullDescription = '',
    isMls = false,
    mlsName = '',
    disclaimer = '',
    mlsLogo = '',
    keywords = '',
    metaKeywords = '',
    paymentPerInquiry = 0,
    webSite = '',
    webSiteIsPayPerClick = false,
    webSiteOriginalDomain = '',
    leaseTerms = '',
    availabilityDate = '',
    trusted = false,
    contactTimes = '',
    numUnits = 0,
    zillowUrl = '',
    logoUrlCdn = '',
    isBrokerListing = false,
    roomForRent = null,
    instantTourProviders,
    external3dTourUrls = [],
    zillow3dTourUrls = [],
    hasZillow3dTourUrls = false,
    hasExternal3dTourUrls = false,
    feesAndDeposits = [],
  } = {},
  floorplans = [],
) {
  // Strip multiple non-breaking whitespace characters from description.
  fullDescription = fullDescription.replace(/ +/g, ' ');

  const floorplanTours = compact(
    floorplans.map(({ zillow3dTourUrl = '' }) => {
      return String(zillow3dTourUrl);
    }),
  );

  this.roomForRent = new RoomForRent(roomForRent);
  this.studentHousing = Boolean(studentHousing);
  this.seniorHousing = Boolean(seniorHousing);
  this.furnished = Boolean(furnished);
  this.rental = Boolean(rental);
  this.demoted = Boolean(demoted);
  this.allowWebsite = Boolean(allowWebsite);
  this.title = String(title); // page title
  this.fullDescription = String(fullDescription);
  this.isMls = Boolean(isMls);
  this.mlsLogo = String(mlsLogo);
  this.mlsName = String(mlsName);
  this.disclaimer = String(disclaimer);
  this.keywords = String(keywords);
  this.metaKeywords = String(metaKeywords);
  this.paymentPerInquiry = Number(paymentPerInquiry);
  this.webSite = String(webSite);
  this.webSiteIsPayPerClick = Boolean(webSiteIsPayPerClick);
  this.webSiteOriginalDomain = String(webSiteOriginalDomain);
  this.leaseTerms = String(leaseTerms);
  this.leaseTermsAndUtilitiesShareDescription = String(
    formatterV2_joinSentences([this.leaseTerms, this.roomForRent.roomDetails.utilitiesShareDescription]),
  );
  this.availabilityDate = String(availabilityDate);
  this.trusted = Boolean(trusted);
  this.contactTimes = String(contactTimes);
  this.numUnits = Number(numUnits);
  this.zillowUrl = String(zillowUrl);
  this.logoUrlCdn = String(logoUrlCdn);
  this.tourUrls = [
    ...floorplanTours,
    ...zillow3dTourUrls.map((obj) => obj.url),
    ...external3dTourUrls.map((obj) => obj.url),
  ];
  this.isBrokerListing = Boolean(isBrokerListing);
  this.instantTourProviders = instantTourProviders ? new InstantTourProviders(instantTourProviders) : null;
  this.has3dTour = get3dTourUrlFlag({ hasZillow3dTourUrls, hasExternal3dTourUrls });
  this.feesAndDeposits = feesAndDeposits?.map((feeOrDeposit) => new FeeOrDeposit(feeOrDeposit)) ?? [];
};

export default Details;

// @ts-nocheck
/* eslint-enable */
import Promise from 'promise';

import constants from 'app/shared/constants/ConstantsBundle';
import requester from 'app/shared/utils/requester';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('utils/api');
const PUT = constants.PUT;
const POST = constants.POST;
const DELETE = constants.DELETE;
const GET = constants.GET;

const api = {
  comscore: {
    pageview() {
      return requester({
        path: '/api/comscore',
        header: {
          contentType: 'text/json',
          accept: 'text/json',
          'Cache-Control': 'no-cache',
        },
      });
    },
  },
  area: {
    nearby({ type, areaResourceId, limit = 5 } = {}) {
      return requester({
        path: '/hotpads-api/api/v2/area/nearby',
        queryParams: {
          type,
          areaResourceId,
          limit,
        },
      });
    },
    within(type, areaId) {
      return requester({
        path: '/hotpads-api/api/v2/area/subAreas',
        queryParams: {
          type,
          areaId,
        },
      });
    },
    reviews(resourceId, startOffset) {
      return requester({
        path: '/hotpads-api/api/v2/review/area/fullDetailsByResourceId',
        queryParams: {
          resourceId,
          brand: 'hotpads',
          startOffset,
        },
      });
    },
    neighborhoodReviewsBreakdown(resourceId) {
      return requester({
        path: '/hotpads-api/api/v2/review/neighborhood/reviewRatingBreakdownByResourceId',
        queryParams: {
          resourceId,
          brand: 'hotpads',
        },
      });
    },
    facts(resourceId) {
      return requester({
        path: '/hotpads-api/api/v2/area/facts',
        queryParams: {
          resourceId,
        },
      });
    },
    factsByBeds(resourceId) {
      return requester({
        path: '/hotpads-api/api/v2/area/factsByBeds',
        queryParams: {
          resourceId,
        },
      });
    },
    articles(resourceId) {
      return requester({
        path: '/hotpads-api/api/v2/area/articles',
        queryParams: {
          resourceId,
        },
      });
    },
    byResourceId(resourceId) {
      return requester({
        path: '/hotpads-api/api/v2/area/byResourceId',
        queryParams: {
          resourceId,
        },
      });
    },
    autocomplete({ partial = 'san f', limit = 10, types = 'city,address,rent', lat, lon }) {
      const queryParams = {
        partial,
        limit,
        types,
      };
      if (lat && lon) {
        queryParams.lat = lat;
        queryParams.lon = lon;
      }
      return requester({
        path: '/hotpads-api/api/v2/area/autocompleteV2',
        queryParams,
      });
    },
    addressAutocomplete(partial, limit = 5) {
      return requester({
        path: '/hotpads-api/api/v2/area/addressAutocomplete',
        queryParams: {
          partial,
          limit,
        },
      });
    },
    byIp(ip) {
      return requester({
        path: '/hotpads-api/api/v2/area/byIp',
        queryParams: { ip },
      });
    },
    byLatLon(lat, lon) {
      return requester({
        path: '/hotpads-api/api/v2/area/reverseGeocode',
        queryParams: { lat, lon },
      });
    },
    bestFit(minLat, maxLat, minLon, maxLon) {
      return requester({
        path: '/hotpads-api/api/v2/area/bestfit',
        queryParams: { minLat, maxLat, minLon, maxLon },
      });
    },
    getLatLonFromString(address) {
      return requester({
        path: '/hotpads-api/api/v2/area/geoAddress',
        queryParams: {
          address,
        },
      });
    },
    signUrl(url) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/googlemaps/sign/url',
        body: {
          url,
        },
      });
    },
    getEncodedBoundary(id) {
      return requester({
        path: '/hotpads-api/api/v2/area/encodedBoundaryById',
        queryParams: {
          id,
        },
      });
    },
    searchSlugToFilter(queryParams, abortControllerSignal = null) {
      return requester({
        method: GET,
        path: '/hotpads-api/api/v2/seo/links/searchSlugToFilter',
        queryParams,
        abortControllerSignal,
      });
    },
    seoFooterByResourceId({ resourceId, searchSlug }) {
      return requester({
        path: '/hotpads-api/api/v2/seo/links/byResourceId',
        queryParams: {
          resourceId,
          keyword: searchSlug,
        },
      });
    },
    // note: never called - waiting on V3
    blogPostsByResourceId(resourceId) {
      return requester({
        path: '/hotpads-api/api/v2/area/blogPostsByResourceId',
        queryParams: {
          resourceId,
        },
      });
    },
    htmlSitemap(resourceId) {
      return requester({
        path: 'hotpads-api/api/v2/htmlSitemap',
        queryParams: {
          resourceId,
        },
      });
    },
  },
  listing: {
    fetchDotMap(queryParams) {
      return requester({
        path: '/hotpads-api/api/v2/listing/dotMap',
        queryParams,
      });
    },
    fetchByCoords(queryParams, abortControllerSignal) {
      return requester({
        path: '/hotpads-api/api/v2/listing/byCoordsV2',
        queryParams: {
          ...queryParams,
          trimResponse: true,
        },
        abortControllerSignal,
      });
    },
    fetchByAlias(alias, queryParams) {
      queryParams.alias = alias;
      queryParams.validate = false;
      return requester({
        path: '/hotpads-api/api/v2/listing/byAliasV2',
        queryParams,
      });
    },
    fetchByMaloneLotIdEncoded(maloneLotIdEncoded, queryParams) {
      queryParams.maloneLotIdEncoded = maloneLotIdEncoded;
      return requester({
        path: '/hotpads-api/api/v2/listing/byMaloneLotIdEncodedV2',
        queryParams,
      });
    },
    inquiry(body) {
      return requester({
        method: PUT,
        path: '/hotpads-api/api/v2/listing/inquiry',
        body,
      });
    },
    listingsPortfolio(aliasEncoded) {
      return requester({
        method: GET,
        path: '/hotpads-api/api/v2/listing/nearby/portfolioProperties',
        queryParams: {
          alias: aliasEncoded,
        },
      });
    },
    priceHistogram(queryParams, abortControllerSignal) {
      return requester({
        path: '/hotpads-api/api/v2/listing/priceHistogram',
        queryParams,
        abortControllerSignal,
      });
    },
    report(body) {
      return requester({
        method: PUT,
        path: '/hotpads-api/api/v2/listing/report',
        body,
      });
    },
    seoFooterByMaloneLotId(lotId, keyword) {
      return requester({
        method: GET,
        path: '/hotpads-api/api/v2/seo/links/byLotIdEncoded',
        queryParams: {
          lotId,
          keyword,
        },
      });
    },
    crimeByMaloneLotId(lotId) {
      return requester({
        method: GET,
        path: '/hotpads-api/api/v2/crime/byLotId',
        queryParams: {
          lotId,
        },
      });
    },
    conversation: {
      messageCounts() {
        return requester({
          method: GET,
          path: '/hotpads-api/api/v2/user/listing/conversation/getCachedConversationCounts',
        });
      },
      recentMessages(aliasEncoded, unreadOnly = false) {
        return requester({
          method: GET,
          path: '/hotpads-api/api/v2/user/listing/conversation/getLatestConversations',
          queryParams: {
            aliasEncoded,
            unreadOnly,
          },
        });
      },
    },
    share: {
      email(body) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/listing/shareEmail',
          body,
        });
      },
      sms(body) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/listing/shareSms',
          body,
        });
      },
    },
    similar(alias, components) {
      return requester({
        path: '/hotpads-api/api/v2/listing/similars',
        queryParams: {
          alias,
          components,
        },
      });
    },
    similarByMaloneLotIdEncoded(queryParams) {
      return requester({
        path: '/hotpads-api/api/v2/listing/similar/byMaloneLotIdEncodedV2',
        queryParams,
      });
    },
  },
  review: {
    vote({ reviewId, vote, userToken, reviewType }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/user/inquiry/voteReview',
        body: {
          reviewId,
          vote,
          userToken,
          reviewType,
        },
      });
    },
    report({ reviewId, comment, userToken, reviewType }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/user/inquiry/reportReview',
        body: {
          reviewId,
          comment,
          userToken,
          reviewType,
        },
      });
    },
    saveResponse(reviewId, comment, email, date) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/user/inquiry/saveReviewResponse',
        body: {
          responses: [
            {
              reviewIdRespondedTo: reviewId,
              replyMessage: comment,
              responderEmailAddress: email,
              respondedDate: date,
            },
          ],
        },
      });
    },
    hasAuthToRespond(listingAlias, userEmail, userToken) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/user/inquiry/hasAuthToRespondForReview',
        body: {
          listingAliasBase33: listingAlias,
          userEmail,
          userToken,
        },
      });
    },
  },
  user: {
    info(queryParams) {
      return requester({
        path: '/hotpads-api/api/v2/user/info',
        queryParams,
      });
    },
    updateProfile(body) {
      return requester({
        method: PUT,
        path: '/hotpads-api/api/v2/user/info/updateV2',
        body,
      });
    },
    feedback({ userName, userEmail, category, comment }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/user/feedbackJson',
        body: {
          comment,
          userEmail,
          userName,
          category,
        },
      });
    },
    updateNetPromoterScore({ email, userToken, score, comment, date }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/user/updateNetPromoterScore',
        body: {
          email,
          userToken,
          npsScore: score,
          comment,
          sourceType: 'EMAIL',
          date,
        },
      });
    },
    properties: {
      get(email) {
        return requester({
          path: '/hotpads-api/api/v2/user/property/stats/daily',
          queryParams: {
            email,
          },
        });
      },
    },
    login: {
      apple(userJwt) {
        return requester({
          path: '/hotpads-api/api/v2/user/auth/apple',
          queryParams: {
            userJwt,
          },
        });
      },
      freepass({ email, url, dontSendWelcomeEmail = false }) {
        return requester({
          path: '/hotpads-api/api/v2/user/auth/freepass',
          queryParams: {
            email,
            url,
            dontSendWelcomeEmail,
          },
        });
      },
      secret(queryParams) {
        return requester({
          path: '/hotpads-api/api/v2/user/auth/secret',
          queryParams,
        });
      },
      emailAndPassword(email, password) {
        return requester({
          method: POST,
          path: '/hotpads-api/api/v2/user/auth/emailPassword',
          body: {
            email,
            password,
          },
        });
      },
      facebook(fbUserId, fbAccessToken) {
        return requester({
          path: '/hotpads-api/api/v2/user/auth/facebook',
          queryParams: {
            fbUserId,
            fbAccessToken,
          },
        });
      },
      google(idToken) {
        return requester({
          header: {
            contentType: 'text/plain',
          },
          method: POST,
          path: '/hotpads-api/api/v2/user/auth/googleSignIn',
          body: idToken,
        });
      },
      resetPassword(email) {
        return requester({
          method: POST,
          path: '/hotpads-api/api/v2/user/auth/sendResetPasswordEmail',
          body: {
            email,
          },
        });
      },
    },
    logout() {
      return requester({
        method: GET,
        path: '/hotpads-api/api/v2/user/auth/logout',
      });
    },
    inquiry: {
      getMessageCache() {
        return requester({
          path: '/hotpads-api/api/v2/user/inquiryMessageCache',
        });
      },
    },
    item: {
      get(type, fromIndex = 0, pageSize = 50, orderBy, includeActiveListingsOnly = false) {
        const VALID_TYPES = [
          'favorite',
          'hidden',
          'viewed',
          'inquiry',
          'shared',
          'note',
          'reported',
          'favorite,inquiry',
        ];
        const components = 'amenities,details,basic,useritem,quality,model,photos';

        if (VALID_TYPES.indexOf(type) > -1) {
          return requester({
            path: '/hotpads-api/api/v2/user/item/listV2',
            queryParams: {
              type,
              fromIndex,
              pageSize,
              components,
              orderBy,
              includeActiveListingsOnly,
            },
          });
        } else {
          logger?.warn({ VALID_TYPES }, 'Invalid parameters passed to api wrapper. Type must be one of VALID_TYPES');
        }
      },
      add(type, alias) {
        return requester({
          method: POST,
          path: '/hotpads-api/api/v2/user/item/create',
          body: {
            type,
            alias,
          },
        });
      },
      remove(type, alias) {
        return requester({
          method: DELETE,
          path: '/hotpads-api/api/v2/user/item/delete',
          queryParams: {
            type,
            alias,
          },
        });
      },
    },
    search: {
      get(searchId) {
        return requester({
          path: '/hotpads-api/api/v2/user/search',
          queryParams: {
            searchId,
          },
        });
      },
      saved: {
        get() {
          return requester({
            path: '/hotpads-api/api/v2/user/savedSearch/list',
          });
        },
        create(body) {
          return requester({
            method: POST,
            path: '/hotpads-api/api/v2/user/savedSearch/create',
            body,
            queryParams: {
              ref: 'hotpadsWeb',
            },
          });
        },
        update(body) {
          return requester({
            method: PUT,
            path: '/hotpads-api/api/v2/user/savedSearch/update',
            body,
          });
        },
        delete(searchId) {
          return requester({
            method: DELETE,
            path: '/hotpads-api/api/v2/user/savedSearch/delete',
            queryParams: {
              searchId,
            },
          });
        },
        displaySearchPromptInquiry(body) {
          return requester({
            method: POST,
            path: '/hotpads-api/api/v2/user/savedSearch/displaySearchPromptPostInquiry',
            body: { filter: body },
          });
        },
      },
      recent: {
        get() {
          return requester({
            path: '/hotpads-api/api/v2/user/recentSearch',
          });
        },
        update(recentSearch) {
          return requester({
            method: PUT,
            path: '/hotpads-api/api/v2/user/recentSearch/update',
            body: recentSearch,
          });
        },
      },
      recommended: {
        get() {
          return requester({
            path: '/hotpads-api/api/v2/user/recommendedSearch',
          });
        },
        reset(id) {
          return requester({
            method: DELETE,
            path: '/hotpads-api/api/v2/user/recommendedSearch/reset',
            queryParams: {
              searchId: id,
            },
          });
        },
        reactivate(email) {
          return requester({
            method: PUT,
            path: '/hotpads-api/api/v2/user/recommendedSearch/reactivate',
            queryParams: {
              email,
            },
          });
        },
      },
    },
    email: {
      subscriptions(email) {
        return requester({
          path: '/hotpads-api/api/v2/email/optOut/list',
          queryParams: {
            email,
          },
        });
      },

      unsubscribe(subscriptions, email) {
        subscriptions.email = email;
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/email/optOut/update',
          body: subscriptions,
        });
      },

      cancelSearches(cancelHash, email, cancelSavedSearch) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/email/cancelSearches',
          body: {
            cancelHash,
            email,
            cancelSavedSearch,
          },
        });
      },

      updateSearchFrequency(email, frequency, hash, searchId) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/user/updateSearchFrequency',
          body: {
            email,
            frequency,
            hash,
            searchId,
          },
        });
      },
      updateEmailOptIn(emailOptIn, emailOptInSource) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/user/preference/optIn/update',
          body: {
            emailOptIn,
            emailOptInSource,
          },
        });
      },
    },
    userPoint: {
      commuteTime(commuteInfoObj) {
        return requester({
          path: '/hotpads-api/api/v2/user/userPoint/commuteTime',
          queryParams: commuteInfoObj,
        });
      },
      create(userPointObj) {
        return requester({
          method: POST,
          path: '/hotpads-api/api/v2/user/userPoint/create',
          body: userPointObj,
        });
      },
      defaultLocation(resourceId) {
        return requester({
          path: '/hotpads-api/api/v2/user/userPoint/defaultCommuteLocation',
          queryParams: {
            resourceId,
          },
        });
      },
      route(routeDetailsObj) {
        return requester({
          path: '/hotpads-api/api/v2/user/userPoint/commuteRoutes',
          queryParams: {
            commuteType: 'DRIVING,CYCLING,WALKING,TRANSIT',
            originLat: routeDetailsObj.originLat,
            originLon: routeDetailsObj.originLon,
            lat0: routeDetailsObj.lat0,
            lon0: routeDetailsObj.lon0,
            commuteTimeMode: routeDetailsObj.commuteTimeMode,
          },
        });
      },
      update(userPointObj) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/user/userPoint/update',
          body: userPointObj,
        });
      },
      list() {
        return requester({
          path: '/hotpads-api/api/v2/user/userPoint/list',
        });
      },
    },
    event: {
      create(userEvent) {
        return requester({
          method: PUT,
          path: '/hotpads-api/api/v2/user/event/create',
          body: userEvent,
        });
      },
    },
    renterProfile: {
      get() {
        return requester({
          method: GET,
          path: '/hotpads-api/api/v2/user/renterProfile/get',
        });
      },
      update(queryParams) {
        return requester({
          method: GET,
          path: '/hotpads-api/api/v2/user/renterProfile/createOrUpdate',
          queryParams,
        });
      },
    },
    ip(skipClientProxy) {
      return requester({
        method: 'get',
        path: '/hotpads-api/datarouter/ipDetection',
        skipClientProxy,
      });
    },
    validatePhoneAndEmail(queryParams = {}) {
      const { listingAlias = '', email = '', phone = '' } = queryParams;
      return requester({
        path: '/hotpads-api/api/v2/listing/validatePhoneAndEmail',
        queryParams: {
          listingAlias,
          email,
          phone,
        },
      });
    },
  },
  analytics: {
    abtest: {
      getTreatments(body = {}) {
        const { experimentNames = [], allowNewUsersOnly = false } = body;
        return requester({
          method: POST,
          path: '/hotpads-api/api/v2/experiment/getTreatments',
          body: {
            experiments: experimentNames,
            allowNewUsersOnly,
          },
        });
      },
    },
    adTags({ aliasEncoded, minLat, minLon, maxLat, maxLon }) {
      return requester({
        path: '/hotpads-api/api/v2/analytics/adtags',
        queryParams: {
          alias: aliasEncoded,
          minLon,
          maxLon,
          minLat,
          maxLat,
        },
      });
    },
  },
  redirect: {
    get(url) {
      return requester({
        path: '/hotpads-api/api/v2/redirect',
        queryParams: {
          uri: url,
        },
      });
    },
  },
  event: {
    trigger(body) {
      return requester({
        method: PUT,
        path: '/hotpads-api/api/v2/event/trigger',
        body,
      });
    },
    refTrack(ref) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/event/ref/track',
        body: {
          ref,
        },
      });
    },
  },
  notifications: {
    // https://developer.mozilla.org/en-US/docs/Web/API/PushSubscription/endpoint
    register(subscription, userToken, userAgent) {
      if (!userToken) {
        const errMsg = `No userToken when registering deviceId ${subscription}`;

        logger?.warn(errMsg);

        return Promise.resolve({
          success: false,
          error: errMsg,
        });
      }

      const notificationDestination = {
        key: {
          app: 'HOTPADS_BROWSER_GCM',
          deviceId: JSON.stringify(subscription),
          token: userToken,
        },
        deviceName: userAgent,
        active: true,
      };

      logger?.info(
        'requesting notifications with deviceId: %s userAgent: %s',
        notificationDestination.key.deviceId,
        userAgent,
      );

      return requester({
        method: PUT,
        path: '/hotpads-api/api/v2/notifications/register',
        body: notificationDestination,
      });
    },
    unregister(deviceId, userToken) {
      const notificationDestination = {
        key: {
          app: 'HOTPADS_BROWSER_GCM',
          deviceId,
          token: userToken,
        },
        active: false,
      };

      return requester({
        method: PUT,
        path: '/hotpads-api/api/v2/notifications/register',
        body: notificationDestination,
      });
    },
    update(body) {
      logger?.info(body, 'updating notification settings:');

      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/notifications/settings/update',
        body,
      });
    },
    getAll() {
      return requester({
        path: '/hotpads-api/api/v2/notifications/settings/getAll',
      });
    },
    track(queryParams) {
      return requester({
        path: '/hotpads-api/api/v2/registerNotificationEvent',
        queryParams,
      });
      // /hotpads-api/api/v2/registerNotificationEvent
      // id
      // ref
      // type:
      // DISPLAYED (Notification was displayed to user)
      // OPENED (User clicked the notification)
      // VISITED (User clicked notification and went to HDP)
      // CONVERTED (User clicked notification, went to HDP, and contacted the listing)
      // DISMISSED (User dismissed the notification)
    },
  },
  hpTours: {
    cancel(tourId) {
      return requester({
        path: '/hotpads-api/api/v2/hpTours/cancel',
        queryParams: {
          tourId,
        },
      });
    },
    latestAvailableTimes({ listingAlias, feedModelId = '', tourType = '' }) {
      return requester({
        path: '/hotpads-api/api/v2/hpTours/latestAvailableTimes',
        queryParams: {
          listingAlias,
          feedModelId,
          tourType,
        },
      });
    },
    recentAvailableTimes({ listingAlias, feedModelId = '', tourType = '' }) {
      return requester({
        path: '/hotpads-api/api/v2/hpTours/recentAvailableTimes',
        queryParams: {
          listingAlias,
          feedModelId,
          tourType,
        },
      });
    },
    requestTour({
      listingAlias = '',
      feedModelId = '',
      tourType = '',
      email = '',
      phone = '',
      firstName = '',
      lastName = '',
      requestedTime = '',
      tourId = '',
      smsConsent = false,
    }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/hpTours/request',
        body: {
          listingAlias,
          feedModelId,
          tourType,
          email,
          phone,
          firstName,
          lastName,
          requestedTime,
          tourId,
          smsConsent,
        },
      });
    },
    rescheduleTour({
      listingAlias = '',
      feedModelId = '',
      tourType = '',
      email = '',
      phone = '',
      firstName = '',
      lastName = '',
      requestedTime = '',
      tourId = '',
      smsConsent = false,
    }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v2/hpTours/reschedule',
        body: {
          listingAlias,
          feedModelId,
          tourType,
          email,
          phone,
          firstName,
          lastName,
          requestedTime,
          tourId,
          smsConsent,
        },
      });
    },
    scheduledToursForListing({ listingAlias, feedModelId = '' }) {
      return requester({
        path: '/hotpads-api/api/v2/hpTours/scheduledToursForListing',
        queryParams: {
          listingAlias,
          feedModelId,
        },
      });
    },
    scheduledToursForUser() {
      return requester({
        path: '/hotpads-api/api/v2/hpTours/scheduledToursForUser',
      });
    },
  },
  hpToursV3: {
    cancel(tourId = '') {
      return requester({
        path: '/hotpads-api/api/v3/hpTours/cancel',
        queryParams: {
          tourId,
        },
      });
    },
    latestAvailableTimes({ listingAlias, feedModelId = '', tourType = '' }) {
      return requester({
        path: '/hotpads-api/api/v3/hpTours/latestAvailableTimes',
        queryParams: {
          listingAlias,
          feedModelId,
          tourType,
        },
      });
    },
    recentAvailableTimes({ listingAlias, feedModelId = '', tourType = '' }) {
      return requester({
        path: '/hotpads-api/api/v3/hpTours/recentAvailableTimes',
        queryParams: {
          listingAlias,
          feedModelId,
          tourType,
        },
      });
    },
    requestTour({
      listingAlias = '',
      feedModelId = '',
      tourType = '',
      email = '',
      phone = '',
      firstName = '',
      lastName = '',
      requestedTime = '',
      tourId = '',
      smsConsent = false,
      rentalSubmitId = '',
    }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v3/hpTours/request',
        body: {
          listingAlias,
          feedModelId,
          tourType,
          email,
          phone,
          firstName,
          lastName,
          requestedTime,
          tourId,
          smsConsent,
          rentalSubmitId,
        },
      });
    },
    rescheduleTour({
      listingAlias = '',
      feedModelId = '',
      tourType = '',
      email = '',
      phone = '',
      firstName = '',
      lastName = '',
      requestedTime = '',
      tourId = '',
      smsConsent = false,
    }) {
      return requester({
        method: POST,
        path: '/hotpads-api/api/v3/hpTours/reschedule',
        body: {
          listingAlias,
          feedModelId,
          tourType,
          email,
          phone,
          firstName,
          lastName,
          requestedTime,
          tourId,
          smsConsent,
        },
      });
    },
    scheduledToursForListing({ listingAlias, feedModelId = '' }) {
      return requester({
        path: '/hotpads-api/api/v3/hpTours/scheduledToursForListing',
        queryParams: {
          listingAlias,
          feedModelId,
        },
      });
    },
    scheduledToursForUser() {
      return requester({
        path: '/hotpads-api/api/v3/hpTours/scheduledToursForUser',
      });
    },
  },
  homeHub() {
    return requester({
      path: '/hotpads-api/api/v2/homeHub',
    });
  },
  clusterSettings() {
    return requester({
      path: '/hotpads-api/api/v2/config/nodeSettings',
    });
  },
  ccpaSignature(redirectUrl) {
    return requester({
      path: '/hotpads-api/api/v2/zillowCcpaAuth/makeSignature',
      queryParams: {
        redirectUrl,
      },
    });
  },
};

export default api;

import DateAgo from 'app/shared/models/DateAgo';

interface UserItemObj {
  viewed?: object;
  inquiry?: object;
  hidden?: object;
  favorite?: object;
}

class UserItemDates {
  viewed: DateAgo;
  inquiry: DateAgo;
  hidden: DateAgo;
  favorite: DateAgo;

  constructor(userItemObj: UserItemObj = {}) {
    this.viewed = new DateAgo(userItemObj.viewed || {});
    this.inquiry = new DateAgo(userItemObj.inquiry || {});
    this.hidden = new DateAgo(userItemObj.hidden || {});
    this.favorite = new DateAgo(userItemObj.favorite || {});
  }
}

export default UserItemDates;

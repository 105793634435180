// @ts-nocheck
/* eslint-enable */
import phabletDetect from 'app/client/utils/phablet-detect';

const browserUtils = {
  legend: {
    sm: 767,
    md: 991,
    lg: 1199,
    lgDesktopSRPFilter: 1279,
  },
  getDevice() {
    if (typeof window !== 'undefined') {
      const device = phabletDetect(window.navigator.userAgent);

      device.screenWidth = this.screen.width();
      device.screenWidthForDesktopSRPFilter = this.screen.width({ isCalcLgDesktopSRPFilter: true });
      device.isMobile = device.screenWidth === 'sm';

      return device;
    } else {
      return {};
    }
  },
  screen: {
    width({ isCalcLgDesktopSRPFilter = false } = {}) {
      let width;

      if (typeof window !== 'undefined') {
        width =
          window.innerWidth && document.documentElement.clientWidth
            ? Math.min(window.innerWidth, document.documentElement.clientWidth)
            : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (width <= this.legend.sm) {
          return 'sm';
        } else if (width <= this.legend.md) {
          return 'md';
        } else if (isCalcLgDesktopSRPFilter && width <= this.legend.lgDesktopSRPFilter) {
          return 'lg';
        } else if (!isCalcLgDesktopSRPFilter && width <= this.legend.lg) {
          return 'lg';
        } else {
          return 'xl';
        }
      } else {
        return false;
      }
    },
  },
};

browserUtils.screen.width = browserUtils.screen.width.bind(browserUtils);

export default browserUtils;

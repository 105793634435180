// Auto generated file, do not manually modify!
// com.hotpads.marlin.web.MarlinJsMetricsCollectionHandler

import { getClient } from '../../client';
import { httpApiRequest } from '@zg-rentals/ts-client-http-client';

import type {
  MarlinJsCountBatchRequestDto,
  MarlinJsGaugeBatchRequestDto,
  MarlinJsMeasurementBatchRequestDto,
  PublishingResponseDto,
} from '../..';

export const MarlinJsMetricsCollectionHandler_addCounts_Path = '/api/collect/addCounts';

export const MarlinJsMetricsCollectionHandler_addCounts = (
  postData: MarlinJsCountBatchRequestDto,
  client = getClient(),
) => {
  return httpApiRequest<never, MarlinJsCountBatchRequestDto, PublishingResponseDto>(
    {
      endpoint: MarlinJsMetricsCollectionHandler_addCounts_Path,
      method: 'POST',
      contextPath: '/marlin',
      postData: postData,
    },
    client,
  );
};

export const MarlinJsMetricsCollectionHandler_addGauges_Path = '/api/collect/addGauges';

export const MarlinJsMetricsCollectionHandler_addGauges = (
  postData: MarlinJsGaugeBatchRequestDto,
  client = getClient(),
) => {
  return httpApiRequest<never, MarlinJsGaugeBatchRequestDto, PublishingResponseDto>(
    {
      endpoint: MarlinJsMetricsCollectionHandler_addGauges_Path,
      method: 'POST',
      contextPath: '/marlin',
      postData: postData,
    },
    client,
  );
};

export const MarlinJsMetricsCollectionHandler_addMeasurements_Path = '/api/collect/addMeasurements';

export const MarlinJsMetricsCollectionHandler_addMeasurements = (
  postData: MarlinJsMeasurementBatchRequestDto,
  client = getClient(),
) => {
  return httpApiRequest<never, MarlinJsMeasurementBatchRequestDto, PublishingResponseDto>(
    {
      endpoint: MarlinJsMetricsCollectionHandler_addMeasurements_Path,
      method: 'POST',
      contextPath: '/marlin',
      postData: postData,
    },
    client,
  );
};

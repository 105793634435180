import type { Logger } from '@zg-rentals/logger-base';
import type { StatController } from './statController';

export interface MetricFnInterface {
  name: string;
  amount: number;
  options?: Record<string, unknown>;
}

export interface MonitorReporterInterface {
  reporterName: string;
  logger?: Logger;
  sampleRate?: number;
}

export abstract class MonitorReporter {
  readonly reporterName: string;
  logger?: Logger;
  sampleRate: number;

  abstract onInitialize(logger?: Logger): void | StatController;
  abstract count(args: MetricFnInterface): Promise<void>;
  abstract gauges(args: MetricFnInterface): Promise<void>;
  abstract measurementWithGauges(args: MetricFnInterface): Promise<void>;
  abstract onError<T>({ error, args }: { error: Error; args?: T }): Promise<void>;

  constructor({ reporterName, logger, sampleRate = 1 }: MonitorReporterInterface) {
    this.reporterName = reporterName;
    this.logger = logger;
    this.sampleRate = sampleRate;
  }
}

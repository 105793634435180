import type { Tracer } from '@zg-rentals/trace-base';
import type { BaseMonitor, Monitor, MonitorPlugin, MonitorReporter } from '@zg-rentals/monitor-base';
import type { Logger } from '@zg-rentals/logger-base';
import { setGlobalLogger } from '@zg-rentals/logger-base';
import { setGlobalMonitor } from '@zg-rentals/monitor-base';
import { setGlobalTracer } from '@zg-rentals/trace-base';

export type Module = 'log' | 'trace' | 'monitor';
export type OptionalModule = Extract<Module, 'trace' | 'monitor'>;

export type RpBootstrapOptions = {
  appName: string;
  buildNumber: number;
};

export abstract class RpBootstrap {
  public isInitialized = false;

  protected configureGlobals(logger: Logger, monitor: BaseMonitor<MonitorPlugin, MonitorReporter>, tracer: Tracer) {
    setGlobalLogger(logger);
    setGlobalMonitor(monitor);
    setGlobalTracer(tracer);
  }

  abstract bootstrap(options: RpBootstrapOptions): void;

  abstract getLogger(name?: string): Logger;

  abstract getMonitor(): Monitor;

  abstract getTracer(): Tracer;

  public reset() {
    this.isInitialized = false;
  }
}

export function getDefaults() {
  let appName;
  let buildNumber = -1;
  let logLevel;

  // webpack app name/build number
  if (typeof __APP_NAME__ !== 'undefined') {
    appName = __APP_NAME__;
  }
  if (typeof __BUILD_NUMBER__ !== 'undefined') {
    buildNumber = Number(__BUILD_NUMBER__);
  }
  if (typeof __LOG_LEVEL__ !== 'undefined') {
    logLevel = __LOG_LEVEL__;
  }

  if (typeof process !== 'undefined' && process.env) {
    if (process?.env?.APP_NAME) {
      appName = process.env.APP_NAME;
    }
    if (process?.env?.BUILD_NUMBER) {
      buildNumber = Number(process.env.BUILD_NUMBER);
    }
    if (process?.env?.LOG_LEVEL) {
      logLevel = process.env.LOG_LEVEL;
    }
  }

  return { appName, buildNumber, logLevel };
}

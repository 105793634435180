import type { Logger } from './LoggerBase';
import { makeModuleInstance, warningBanner } from '@zg-rentals/util';

export * from './LoggerBase';

const { get, set: setGlobalLogger } = makeModuleInstance<Logger | undefined>(undefined);

export function getGlobalLogger(name?: string) {
  return name ? get()?.child({ name }) : get();
}

export { setGlobalLogger, warningBanner };

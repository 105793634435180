// @ts-nocheck
/* eslint-enable */
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'app/shared/flux/reducers/index';

let store;

export default function configureStore(initialState = {}) {
  let finalCreateStore;

  if (__DEV__) {
    const createLogger = require('redux-logger').createLogger;
    const logger = createLogger({
      duration: true,
      collapsed: true,
    });
    finalCreateStore = compose(
      applyMiddleware(thunk),
      typeof window === 'object' ? applyMiddleware(logger) : (f) => f,
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
    )(createStore);
  } else {
    finalCreateStore = compose(applyMiddleware(thunk))(createStore);
  }

  store = finalCreateStore(rootReducer(), initialState);

  return store;
}

// Export as a named export for direct import and
// reading state from various non-React modules
export { store };

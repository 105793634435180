import get from 'lodash/get';
import { track } from '@zillow/universal-analytics';
import type { ClickstreamEvent } from 'app/types';
import type { Dispatch } from 'react';

// TODO: fix issues with "any" type (specifically, need to build out Redux state, oh boy...)

interface AdditionalParams {
  category?: string;
  action?: string;
  label?: string;
  value?: string;
  nonInteraction?: boolean;
  newLaneEvent?: ClickstreamEvent | null;
}

interface NewLaneTrackObject {
  newLaneEvent?: ClickstreamEvent;
}

export const analyticsEvent = (
  gaEventObj: any,
  additionalParams: null | AdditionalParams = {
    category: '',
    action: '',
    label: '',
    value: '',
    nonInteraction: false,
    newLaneEvent: null,
  },
) => {
  return (dispatch: Dispatch<any>, getState: any) => {
    if (typeof window === 'undefined' || !window.ga) {
      return;
    }

    // Escape hatch for *only* Clickstream New Lane events
    // where we don't send a legacy analytics object.
    if ('newLaneEvent' in gaEventObj) {
      // Only attach if newLaneEvent object if present, otherwise we get
      // errors in the console related to missing an "event_template_id"
      // for the newLaneEvent.
      const newLaneObject: NewLaneTrackObject = {};
      newLaneObject.newLaneEvent = gaEventObj.newLaneEvent;

      track({}, newLaneObject);
      return;
    }

    const category = additionalParams?.category || gaEventObj.category;
    const action = additionalParams?.action || gaEventObj.action;
    const value = additionalParams?.value || gaEventObj.value;
    const nonInteraction = additionalParams?.nonInteraction || gaEventObj.nonInteraction;
    const pathname = get(getState(), 'location.current.pathname');
    const page = pathname;
    let label = additionalParams?.label || gaEventObj.label;
    label = label || getState().location.current.name;

    const fieldObject = {
      action,
      category,
      label,
      page,
      value,
      nonInteraction,
    };

    // Only attach if newLaneEvent object if present, otherwise we get
    // errors in the console related to missing an "event_template_id"
    // for the newLaneEvent.
    const optionalObject: NewLaneTrackObject = {};
    if (additionalParams?.newLaneEvent) {
      optionalObject.newLaneEvent = additionalParams.newLaneEvent;
    }

    track(fieldObject, optionalObject);
  };
};

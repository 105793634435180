import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import assign from 'lodash/assign';

interface IState {
  seoFooterLinks: object;
}

interface IAction {
  type: string;
  payload: {
    seoFooterLinks: object;
  };
}

const initState = () => ({
  seoFooterLinks: {},
});

const mapActionsToReducer = {
  [constants.LOAD_SEO_FOOTER_LINKS]: (state: IState, action: IAction) => {
    const { seoFooterLinks } = action.payload;
    return assign({}, state, { seoFooterLinks });
  },
};

const seo = reduxUtils.createReducer(mapActionsToReducer, initState());

export default seo;

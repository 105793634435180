import getBrowserLogger from './get-browser-logger';
import { getGlobalLogger as getBaseLogger, setGlobalLogger, warningBanner } from '@zg-rentals/logger-base';
import { isTest } from '@zg-rentals/environment-utils';

export function getGlobalLogger(name?: string) {
  let logger = getBaseLogger();

  if (!logger) {
    if (!isTest()) {
      warningBanner('logger-browser not initialized! You should add a bootstrap to your app');
    }

    logger = getBrowserLogger();
    setGlobalLogger(logger);
  }

  return name ? logger.child({ name }) : logger;
}

export { setGlobalLogger };

import React from 'react';
import loadable from '@loadable/component';

const PromptFeedback = loadable(
  () => import(/* webpackChunkName: "PromptFeedback.loadable" */ 'app/shared/modules/PromptFeedback/component'),
);

class LoadablePromptFeedback extends React.Component {
  render() {
    return <PromptFeedback {...this.props} />;
  }
}

export default LoadablePromptFeedback;

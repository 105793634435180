import { logError } from '@zg-rentals/log-error';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('models');

interface BrokerProps {
  isBrokerExclusiveListing?: boolean; // Listing by an exclusive broker
  companyName?: string | null;
  companyPhoneNumber?: string | null;
  contactName?: string | null;
  agentLicenseType?: string | null;
  isBrokerListing?: boolean; // Listing by or on behalf of any licensed broker
}

class Broker {
  isBrokerExclusiveListing: boolean = false;
  companyName: string | null = null;
  companyPhoneNumber: string | null = null;
  contactName: string | null = null;
  agentLicenseType: string | null = null;
  isBrokerListing: boolean = false;

  constructor({
    isBrokerExclusiveListing = false,
    companyName = null,
    companyPhoneNumber = null,
    contactName = null,
    agentLicenseType = null,
    isBrokerListing = false,
  }: BrokerProps = {}) {
    try {
      this.isBrokerExclusiveListing = isBrokerExclusiveListing;
      this.companyName = companyName;
      this.companyPhoneNumber = companyPhoneNumber;
      this.contactName = contactName;
      this.agentLicenseType = agentLicenseType;
      this.isBrokerListing = isBrokerListing;
    } catch (err) {
      logError({ error: err, errorType: 'DataModelError', context: 'Broker' });

      // Optional: Re-throw the error for the caller to handle
      throw new Error(`Failed to initialize Broker: ${err}`);
    }
  }
}

export default Broker;

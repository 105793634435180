import React from 'react';
import loadable from '@loadable/component';

const HeaderNav = loadable(
  () => import(/* webpackChunkName: "HeaderNav.loadable" */ 'app/shared/modules/navigation/header/HeaderNavComponent'),
);

class LoadableHeaderNav extends React.Component {
  render() {
    return <HeaderNav {...this.props} />;
  }
}

export default LoadableHeaderNav;

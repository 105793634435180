// @ts-nocheck
/* eslint-enable */
import axios from 'axios';

import isString from 'lodash/isString';
import queryUtils from 'app/shared/utils/queryUtils';
import includes from 'lodash/includes';
import trimStart from 'lodash/trimStart';
import trimEnd from 'lodash/trimEnd';

import AuthActions from 'app/shared/flux/actions/AuthActions';
import getRequestHeaders from 'app/shared/utils/httpHeaders';
import RequesterActions from 'app/shared/flux/actions/RequesterActions';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('utils/requester');

if (__SERVER__) {
  const https = require('https');
  axios.defaults.httpsAgent = new https.Agent({ keepAlive: false });
}

export default function requester({
  method = 'get',
  path,
  queryParams = {},
  body = {},
  header = {},
  skipClientProxy = false,
  abortControllerSignal: signal = null,
}) {
  return function (dispatch, getState) {
    const state = getState();
    const startTime = new Date().getTime();
    const { location, user, app } = state;
    const traceId = location.ssrEntry.traceId;
    let originalUri = '';

    path = trimStart(path, '/');

    const isApiV2Call = includes(path, 'api/v2');

    let baseUrl = '';

    if (__BROWSER__ && skipClientProxy) {
      baseUrl = `${window.location.protocol}//${window.location.host}`;
    } else if (__BROWSER__) {
      baseUrl = `${window.location.protocol}//${window.location.host}/node`;
    } else {
      if (!isApiV2Call) {
        const legacyZgApi = process.env.LEGACY_ZG_API || 'https://hotpads.com';

        baseUrl = trimEnd(legacyZgApi, '/');
      } else {
        baseUrl = trimEnd(process.env.ZG_API, '/');
      }
    }

    const endpointUrl = `${baseUrl}/${path}`;

    if (location.current && location.current.pathname) {
      originalUri = location.current.pathname;
    }
    if (user.info && includes(user.info.roles, 'rep')) {
      queryParams.trace = true;
    }

    if (__SERVER__) {
      queryParams.traceId = traceId;
    }

    const requestHeaders = getRequestHeaders(header, {
      httpMethod: method,
      originalUri,
      requestId: app.requestId,
      ip: app.ip,
      csrfToken: user.csrfToken,
      userToken: user.userToken,
      location,
      userAgent: app.userAgent,
      serverSideCookies: user.serverSideCookies,
      logger,
    });

    return import(/* webpackChunkName: "qs" */ 'qs').then(({ default: qs }) => {
      // serialize arrays { a: [b , c] } and duplicate params 'a=b&a=c' as 'a=b,c' (HP-5678)
      const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'comma' });

      const requestOptions = {
        method,
        url: endpointUrl,
        params: queryParams,
        paramsSerializer,
        headers: requestHeaders,
        data: body,
        timeout: 15000,
        signal,
      };

      // Adding extra logging to help debug runscope issues - before API is called
      const isRunscopeAgent = app.userAgent && app.userAgent.includes('runscope-radar');

      if (isRunscopeAgent) {
        logger?.warn({
          method,
          url: endpointUrl,
          params: queryParams,
          headers: requestHeaders,
          data: body,
          traceId,
          requestId: app.requestId,
          msg: 'Before API is called...',
        });
      }

      return axios(requestOptions).then((response) => {
        const endTime = new Date().getTime();
        const duration = endTime - startTime;
        const url = endpointUrl + queryUtils.stringify(queryParams);

        if (isString(response.data)) {
          response.data = {};
        }
        if (response.config.url.indexOf('/api/comscore') > -1) {
          return true;
        }
        if (__DEV__) {
          logger?.warn({
            endTime,
            startTime,
            duration,
            url,
            traceId: location.ssrEntry.traceId,
          });
        }

        if (response.data.error) {
          if (isRunscopeAgent) {
            // Adding extra logging to help debug runscope issues - after API is called (error returned)
            logger?.warn({
              method,
              url: endpointUrl,
              params: queryParams,
              headers: requestHeaders,
              response: response.data,
              traceId,
              requestId: app.requestId,
              msg: 'After API is called - ERROR...',
            });
          }

          throw response.data.error;
        }

        if (isRunscopeAgent) {
          // Adding extra logging to help debug runscope issues - after API is called (success)
          logger?.warn({
            method,
            url: endpointUrl,
            params: queryParams,
            headers: requestHeaders,
            response: response.data,
            traceId,
            requestId: app.requestId,
            msg: 'After API is called...',
          });
        }

        if (__SERVER__) {
          const entry = {
            startTime,
            endTime,
            duration,
            url,
          };
          dispatch(RequesterActions.addRequestToSsrTrace(entry));
        }

        if (
          __SERVER__ &&
          response.headers &&
          // only set cookies on user info/secret
          includes(path, 'v2/user/')
        ) {
          if (response.headers['set-cookie']) {
            dispatch(RequesterActions.saveServerSideResponseCookies(response));
            dispatch(RequesterActions.saveServerSideSetCookieArray(response.headers['set-cookie']));
          }

          dispatch(RequesterActions.updateCsrfToken(response.data.creds));
        }

        if (!__SERVER__) {
          // on the server, we guarantee that a user/info call is made, and that will set the csrfToken
          // on the client, anything can update the csrfToken
          // this is to ensure that the csrfToken we return with an original request always matches the ut cookie
          dispatch(RequesterActions.updateCsrfToken(response.data.creds));

          // ensure that a user's session is invalidated if they sign out or change their password
          // in another session (HPWEB-5321)
          if (response.data.loggedIn === false) {
            // must be strictly === false
            dispatch(AuthActions.login.checkValidSession());
          }
        }

        if (response.data && response.data.creds) {
          const latestSessionToken = response.data.creds.sessionToken;
          // set/update sessionToken (HPWEB-5709)
          if (!user.sessionToken || user.sessionToken !== latestSessionToken) {
            dispatch(RequesterActions.updateSessionToken(latestSessionToken));
          }
        }

        return response.data;
      });
    });
  };
}

// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import Promise from 'promise';

import { clearPis } from 'app/client/utils/browserCookieUtils';
import * as storageUtils from 'app/shared/utils/storageUtils';
import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';
import { FilterActions_handleReduxOnlyFilterChange } from 'app/shared/flux/actions/FilterActions';
import loginUtils from 'app/shared/utils/loginUtils';
import pathsManifest from 'app/shared/utils/pathsManifest';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import stringHelper from 'app/shared/utils/stringHelper';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import UserPointActions from 'app/shared/flux/actions/UserPointActions';
import { getLsKey } from 'app/shared/utils/debugUtils';
import userUtils from 'app/shared/utils/userUtils';

const AuthActions = {
  login: {
    load({ email, dontSendWelcomeEmail }) {
      return function (dispatch, getState) {
        const state = getState();
        const currentUrl = routeUtils.buildFreepassPath(state.location.current);

        return dispatch(api.user.login.freepass({ email, url: currentUrl, dontSendWelcomeEmail })).then((result) => {
          if (result.success && result.loggedIn) {
            dispatch({
              type: constants.UPDATE_USER_CREDS,
              payload: {
                loggedIn: result.loggedIn,
              },
            });
            dispatch(AuthActions.login.success(result));
          }
          return result;
        });
      };
    },
    checkCcpaAndLogin({ email, isCcpaRedirectUrl }) {
      return function (dispatch) {
        if (isCcpaRedirectUrl) {
          return dispatch(api.user.login.emailAndPassword(email)).then((res) => {
            /**
             * For CCPA redirects ONLY, check if an account with that email exists
             *   T -> proceed to log user in
             *   F -> shortcircuit account creation
             */
            if (res.status === 'INCORRECT_PASSWORD') {
              return dispatch(AuthActions.login.load({ email }));
            } else if (res.status === 'USERNAME_NOT_FOUND') {
              return Promise.reject(`Oops we don't recognize this email address`);
            }
            return Promise.reject();
          });
        } else {
          return dispatch(AuthActions.login.load({ email }));
        }
      };
    },
    success(payload) {
      return function (dispatch, getState) {
        const state = getState();
        const updatedPayload = assign({}, payload, {
          preventEmit: true,
        });
        const loginCallback = state.app.popupModal.data;
        const countryCode = state.geolocation.area.countryCode;
        const email = payload.data ? payload.data.email : '';
        const freepass = payload.data ? payload.data.passwordStatus === 'freepass' : false;
        const hasOptedOutAdTracking = payload.data ? payload.data.hasOptedOutAdTracking : false;

        // we updated the user store already in AuthActions.login.info
        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: updatedPayload,
        });
        if (loginCallback.action === 'favorite') {
          dispatch(UserItemActions.addUserItem(loginCallback.action, loginCallback.listing));
        }

        if (hasOptedOutAdTracking) {
          dispatch({
            type: constants.UPDATE_AD_TRACKING,
            payload: {
              hasOptedOutAdTracking: true,
            },
          });
        }

        if (loginUtils.isCanadianUser(countryCode, email)) {
          if (!freepass) {
            dispatch({
              type: constants.HIDE_POPUP_MODAL,
            });
          }
        } else {
          dispatch({
            type: constants.HIDE_POPUP_MODAL,
          });
        }
        dispatch(UserPointActions.list()).then((userPoints) => {
          return dispatch(UserPointActions.setActivePoint(userPoints));
        });
      };
    },
    emailAndPassword(email, password) {
      return function (dispatch, getState) {
        const state = getState();
        const userToken = state.user.userToken;

        storageUtils.userTokenStorage.storeOldCred(userToken);

        // TODO: AuthActions.login.success is dispatched with the result of this
        //       Refactor to prevent actions calling actions
        return dispatch(api.user.login.emailAndPassword(email, password));
      };
    },
    apple(userJwt) {
      return function (dispatch, getState) {
        storageUtils.userTokenStorage.storeOldCred(getState().user.userToken);
        return dispatch(api.user.login.apple(userJwt)).then((result) => {
          if (result.success && result.loggedIn) {
            dispatch(AuthActions.login.success(result));
          }

          return result;
        });
      };
    },
    facebook({ fbUserId = 0, fbAccessToken = 0, userData = {} }) {
      return function (dispatch, getState) {
        storageUtils.userTokenStorage.storeOldCred(getState().user.userToken);
        return dispatch(api.user.login.facebook(fbUserId, fbAccessToken, userData)).then((result) => {
          if (result.success && result.loggedIn) {
            dispatch(AuthActions.login.success(result));
          }
          return result;
        });
      };
    },
    signInWithGoogle(credential) {
      return function (dispatch, getState) {
        storageUtils.userTokenStorage.storeOldCred(getState().user.userToken);
        if (getLsKey('debugGoogle')) {
          console.log(`AuthActions#signInWithGoogle`);
          console.log('getState().user.userToken', getState().user.userToken);
          console.log(`credential`, credential);
        }

        return dispatch(api.user.login.google(credential)).then((result) => {
          if (getLsKey('debugGoogle')) {
            console.log(`api.user.login.google`);
            console.log(result);
          }

          if (result.success && result.loggedIn) {
            dispatch(AuthActions.login.success(result));
            return Promise.resolve();
          }

          return Promise.reject(result.status);
        });
      };
    },
    info(uvl) {
      return function (dispatch, getState) {
        const queryParams = {};
        const loggedIn = getState().user.loggedIn;

        if (uvl && !loggedIn) {
          queryParams.uvl = uvl;
        }

        return dispatch(api.user.info(queryParams)).then((result) => {
          if (result.loggedIn) {
            dispatch(AuthActions.login.success(result));
          }

          if (result.data && result.data.hasOptedOutAdTracking) {
            dispatch({
              type: constants.UPDATE_AD_TRACKING,
              payload: {
                hasOptedOutAdTracking: true,
              },
            });
          }

          dispatch({
            type: constants.UPDATE_USER_CREDS,
            payload: {
              userToken: result.creds && result.creds.userToken,
              loggedIn: result.loggedIn,
            },
          });
          return result;
        });
      };
    },
    secret(queryParams) {
      return function (dispatch, getState) {
        storageUtils.userTokenStorage.storeOldCred(getState().user.userToken);

        return dispatch(api.user.login.secret(queryParams)).then((result) => {
          if (result.loggedIn && result.success) {
            dispatch({
              type: constants.LOGIN_SUCCESS,
              payload: result,
            });
          }

          dispatch({
            type: constants.UPDATE_USER_CREDS,
            payload: {
              userToken: result.creds && result.creds.userToken,
              loggedIn: result.loggedIn,
            },
          });

          return result;
        });
      };
    },
    destroy(redirectUrl) {
      return function (dispatch) {
        return dispatch(api.user.logout())
          .then(() => {
            // clear PIS cookies
            clearPis();

            // clear filters
            dispatch(FilterActions_handleReduxOnlyFilterChange());

            dispatch({
              type: constants.HOMEHUB_RESET,
            });

            return dispatch({
              type: constants.CLEAR_LISTING_CACHE,
            });
          })
          .then(() => {
            return dispatch({
              type: constants.LOGIN_DESTROY,
            });
          })
          .then(() => {
            window.location = redirectUrl || window.location.origin;
            return true;
          });
      };
    },
    checkValidSession() {
      return function (dispatch, getState) {
        // update the UI to show that a user's session is invalidated if they sign out or
        // change their password in another session (HPWEB-5321)
        if (!getState().user.loggedIn) {
          return true;
        }

        // clear PIS cookies
        clearPis();

        // clear filters
        dispatch(FilterActions_handleReduxOnlyFilterChange());

        dispatch({
          type: constants.HOMEHUB_RESET,
        });

        dispatch({
          type: constants.CLEAR_LISTING_CACHE,
        });

        dispatch({
          type: constants.LOGIN_DESTROY,
        });
      };
    },
    checkLoginPageRedirect(isFavoriteOrHideAttempt = false) {
      return function (dispatch) {
        const queryParams = stringHelper.getUrlQueryParams();
        const { redirect, redirectUrl } = queryParams;

        // zillow's ccpa url will include `redirectUrl` while hotpads uses `redirect`
        if (redirectUrl) {
          return dispatch(AuthActions.ccpa.goToCcpaPortal(redirectUrl));
        } else if (redirect) {
          userUtils.successLoginRedirect();
        } else if (isFavoriteOrHideAttempt) {
          // Signing in after a favorite or hide attempt should not redirect anywhere
          return;
        } else {
          userUtils.successLoginRedirect('/');
        }
      };
    },
  },
  resetPassword(email) {
    return function (dispatch) {
      return dispatch(api.user.login.resetPassword(email)).then((result) => {
        return result; // pointless unless we do something with it...
      });
    };
  },
  fb: {
    validate() {
      return function (dispatch) {
        if (typeof window !== 'undefined' && typeof window.FB !== 'undefined') {
          return new Promise((resolve, reject) => {
            window.FB.login(
              (response) => {
                if (typeof response !== 'undefined') {
                  resolve(response);
                } else {
                  reject(new Error('Unable to get your email from Facebook. Please enter it above.'));
                }
              },
              {
                scope: 'public_profile, email',
                return_scopes: true, // eslint-disable-line camelcase
              },
            );
          })
            .then((response) => {
              // if the response looks good, verify that the user's email exists
              if (response.status === 'connected' && typeof response.authResponse !== 'undefined') {
                const hasEmailPermissions = response.authResponse.grantedScopes.includes('email');

                if (hasEmailPermissions) {
                  return loginUtils.getEmailFromFB(response);
                }
              } else {
                throw new Error('Unable to get your email from Facebook. Please enter it above.');
              }
            })
            .then((response) => {
              if (
                typeof response !== 'undefined' &&
                response.status === 'connected' &&
                typeof response.authResponse !== 'undefined'
              ) {
                const fbAccessToken = response.authResponse.accessToken || 0;
                const fbUserId = response.authResponse.userID || 0;
                const hasEmailPermissions = response.authResponse.grantedScopes.includes('email');

                if (hasEmailPermissions) {
                  const hasEmail = response.userData && response.userData.email;
                  if (hasEmail) {
                    return dispatch(
                      AuthActions.login.facebook({
                        fbUserId,
                        fbAccessToken,
                        data: response.userData,
                      }),
                    );
                  } else {
                    throw new Error('Unable to get your email from Facebook. Please enter it above.');
                  }
                } else {
                  // user manually disabled email permissions
                  throw new Error("We're missing your email address. Please enter it above.");
                }
              } else if (typeof response !== 'undefined' && response.status === 'not_authorized') {
                throw new Error('Please grant permissions to hotpads.com from your facebook settings.');
              } else {
                throw new Error('Please log in to facebook to continue.');
              }
            });
        } else {
          return Promise.reject(new Error('Something went wrong. Please try again.'));
        }
      };
    },
  },
  ccpa: {
    /**
     * goToCcpaPortal
     * This function is called in two ways:
     *  1. user goes to settings page and clicks `Continue to account data management tools`
     *  2. unauthenticated user logs into the zillow privacy portal and clicks sign in with Hotpads
     *
     * @param url {string} redirect url to navigate to
     * When url is passed, the user is in flow 2 - which means:
     *  - the redirectUrl was taken from the query params (provided by Zillow's privacy portal)
     *  - the user did not explicitly sign in to hotpads, so log out after creating ccpa signature (HPWEB-5610)
     */
    goToCcpaPortal(url) {
      return function (dispatch) {
        const ccpaRedirectUrl = pathsManifest.ccpaPortal + '/hotpadsSSO';
        const signatureUrl = url || ccpaRedirectUrl;

        return dispatch(api.ccpaSignature(signatureUrl)).then((result) => {
          if (result.success) {
            const data = result.data;
            const query = {
              signature: data.signature,
              emailAddress: data.emailAddress,
              timestamp: data.timestamp,
            };

            const redirectUrl = signatureUrl + queryUtils.stringify(query);

            if (url) {
              dispatch(AuthActions.login.destroy(redirectUrl));
            } else {
              window.location = redirectUrl;
              return true;
            }
          }
        });
      };
    },
  },
};

export default AuthActions;

// @ts-nocheck
/* eslint-enable */
// Lodash
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

// Enums
import { COMMUTE_MODE_TYPES_JAVA_TO_REDUX, FILTER_ENUM } from 'app/shared/constants/FilterConstants';

export const DEFAULT = {
  search: {
    slug: 'apartments-for-rent',
    title: 'Apartments for Rent',
  },
  price: {
    min: null,
    max: null,
  },
  bedrooms: {
    anyOrStudio: true,
    oneOrMore: false,
    twoOrMore: false,
    threeOrMore: false,
    fourOrMore: false,
    isExact: false,
  },
  bathrooms: {
    any: true,
    oneOrMore: false,
    oneHalfOrMore: false,
    twoOrMore: false,
    threeOrMore: false,
    fourOrMore: false,
  },
  commute: {
    commuteLats: null,
    commuteLons: null,
    commuteMode: null,
    commuteTimeMode: null,
    commuteTime: null,
  },
  pets: {
    cats: false,
    dogs: false,
  },
  availability: {
    start: null,
    end: null,
    hideUnknownAvailabilityDate: null,
  },
  laundry: {
    inUnit: false,
    shared: false,
  },
  amenities: {
    cooling: false,
    heating: false,
    parking: false,
    gatedEntry: false,
    doorman: false,
    fitnessCenter: false,
    swimmingPool: false,
    dishwasher: false,
  },
  furnished: false,
  propertyTypes: {
    any: true,
    apartment: false,
    condo: false,
    duplex: false,
    house: false,
    townhouse: false,
  },
  sqft: {
    min: null,
    max: null,
  },
  createdWithin: {
    any: true,
    hour: false,
    day: false,
    week: false,
    month: false,
  },
  rentalTypes: {
    rental: true,
    room: true,
    sublet: true,
    corporate: true,
  },
  restrictions: {
    incomeRestricted: null,
    seniorHousing: null,
    studentHousing: null,
    militaryHousing: null,
    none: null,
  },
  keywords: '',
  additionalOpts: {
    requiresPhotos: false,
    requiresPrice: null,
    forRentByOwner: false,
    acceptsSection8: false,
    hasOffers: false,
    acceptingApplications: false,
  },
  orderBy: 'score',
  NON_USER_FACING: {
    feeds: null,
    dupeGrouping: null,
    visible: 'favorite,inquiry,new,note,notified,viewed',
  },
};

export const ALLOWED_CONSTANTS = {
  defaultListingTypes: ['rental', 'sublet', 'room', 'corporate'].sort(),
  defaultPropertyTypes: ['house', 'divided', 'condo', 'townhouse', 'medium', 'large', 'garden', 'land'].sort(),
};

const MODEL = {
  GENERAL: {
    standardTruthy: (_val, type) => {
      // NOTE: Polyfill or use Lodash for desktop migration
      const filterObj = Object.assign({}, DEFAULT[type]);

      if (!filterObj) {
        return null;
      }

      if (!_val) {
        return filterObj;
      }
      let newFilterObj = {};
      if (isString(_val)) {
        const val = _val;

        newFilterObj = val
          .split(',')
          .sort()
          .filter((b) => Object.keys(filterObj).includes(b))
          .reduce((obj, el) => {
            obj[el] = true;
            return obj;
          }, {});
      } else if (isObject(_val)) {
        newFilterObj = _val;
      }

      // NOTE: Polyfill or use Lodash for desktop migration
      return Object.assign(filterObj, newFilterObj);
    },
  },
  SPECIFIC: {
    orderBy: (str = DEFAULT.orderBy) => {
      const isAcceptedValue = Boolean(FILTER_ENUM.ORDER_BY[str]);

      if (isAcceptedValue) {
        return str;
      }

      return DEFAULT.orderBy;
    },
    search: ({ slug = DEFAULT.search.slug, title = DEFAULT.search.title }) => {
      return {
        slug,
        title,
      };
    },
    price: ({ min = DEFAULT.price.min, max = DEFAULT.price.max }) => {
      const minNumber = min !== null ? Number(min) : null;
      const maxNumber = max !== null ? Number(max) : null;

      return {
        min: minNumber,
        max: maxNumber,
      };
    },
    sqft: ({ min = DEFAULT.sqft.min, max = DEFAULT.sqft.max }) => {
      const minNumber = min !== null ? Number(min) : null;
      const maxNumber = max !== null ? Number(max) : null;

      return {
        min: minNumber,
        max: maxNumber,
      };
    },
    bedrooms: (str = null) => {
      if (!str) {
        return DEFAULT.bedrooms;
      }

      const bedroomsArray = str.split(',');
      const isAcceptedValue = FILTER_ENUM.BEDROOMS[bedroomsArray[0]];

      if (isAcceptedValue) {
        const isExact = bedroomsArray.length === 1;

        return {
          anyOrStudio: bedroomsArray[0] === '0', // Maps to Any or Studio
          oneOrMore: bedroomsArray[0] === '1', // Maps to 1 or 1+
          twoOrMore: bedroomsArray[0] === '2', // Maps to 2 or 2+
          threeOrMore: bedroomsArray[0] === '3', // Maps to 3 or 3+
          fourOrMore: bedroomsArray[0] === '4', // Maps to 4 or 4+
          isExact: isExact, // Dictates whether choices are "1" or "1+", "2" or "2+", etc
        };
      }

      return DEFAULT.bedrooms;
    },
    bathrooms: (str = null) => {
      if (!str) {
        return DEFAULT.bathrooms;
      }

      const bathroomsArray = str.split(',');
      const isAcceptedValue = FILTER_ENUM.BATHROOMS[bathroomsArray[0]];

      if (isAcceptedValue) {
        return {
          any: bathroomsArray[0] === '0', // Maps to Any
          oneOrMore: bathroomsArray[0] === '1', // Maps to 1+
          oneHalfOrMore: bathroomsArray[0] === '1.5', // Maps to 1.5+
          twoOrMore: bathroomsArray[0] === '2', // Maps to 2+
          threeOrMore: bathroomsArray[0] === '3', // Maps to 3+
          fourOrMore: bathroomsArray[0] === '4', // Maps to 4+
        };
      }

      return DEFAULT.bathrooms;
    },
    commute: ({
      lat = DEFAULT.commute.commuteLats,
      lon = DEFAULT.commute.commuteLons,
      mode = DEFAULT.commute.commuteMode,
      timeMode = DEFAULT.commute.commuteTimeMode,
      time = DEFAULT.commute.commuteTime,
    }) => {
      const convertedLat = Number(lat);
      const convertedLon = Number(lon);
      const isAcceptedLat = !isNaN(convertedLat);
      const isAcceptedLon = !isNaN(convertedLon);
      const isAcceptedMode = FILTER_ENUM.COMMUTE[mode];
      const isAcceptedTimeMode = FILTER_ENUM.COMMUTE[timeMode];
      const isAcceptedTime = time ? FILTER_ENUM.COMMUTE[time] : true; // Null is okay!

      const isValid = isAcceptedLat && isAcceptedLon && isAcceptedMode && isAcceptedTimeMode && isAcceptedTime;

      if (isValid) {
        return {
          commuteLats: convertedLat,
          commuteLons: convertedLon,
          commuteMode: COMMUTE_MODE_TYPES_JAVA_TO_REDUX[mode],
          commuteTimeMode: COMMUTE_MODE_TYPES_JAVA_TO_REDUX[timeMode],
          commuteTime: time,
        };
      }

      return DEFAULT.commute;
    },
    availability: ({
      start = DEFAULT.availability.start,
      end = DEFAULT.availability.end,
      hideUnknownAvailabilityDate = DEFAULT.availability.hideUnknownAvailabilityDate,
    }) => {
      const isValidStart = isString(start);
      const isValidEnd = isString(end);
      const isValidBool = hideUnknownAvailabilityDate === null || typeof hideUnknownAvailabilityDate === 'boolean';
      const isValid = isValidStart && isValidEnd && isValidBool;

      if (isValid) {
        return {
          start,
          end,
          hideUnknownAvailabilityDate,
        };
      }

      return DEFAULT.availability;
    },
    furnished: (str = DEFAULT.furnished) => {
      return str === 'true' || str === true;
    },
    propertyTypes: (propertyTypesStr = null) => {
      if (!propertyTypesStr) {
        return DEFAULT.propertyTypes;
      }

      const propertyTypesArray = propertyTypesStr.split(',');
      const apartmentType = Boolean(propertyTypesArray.includes('garden', 'large', 'medium'));
      const condoType = Boolean(propertyTypesArray.includes('condo'));
      const duplexType = Boolean(propertyTypesArray.includes('divided'));
      const houseType = Boolean(propertyTypesArray.includes('house'));
      const townhouseType = Boolean(propertyTypesArray.includes('townhouse'));
      const landType = Boolean(propertyTypesArray.includes('land'));
      const anyPropertyType = Boolean(
        apartmentType && condoType && duplexType && houseType && townhouseType && landType,
      );

      if (anyPropertyType) {
        return {
          any: anyPropertyType,
          apartment: false,
          condo: false,
          duplex: false,
          house: false,
          townhouse: false,
        };
      }

      return {
        any: false,
        apartment: apartmentType,
        condo: condoType,
        duplex: duplexType,
        house: houseType,
        townhouse: townhouseType,
      };
    },
    createdWithin: (str = null) => {
      if (!str) {
        return DEFAULT.createdWithin;
      }

      const createdWithinTheHour = str === FILTER_ENUM.MAX_CREATED['1'];
      const createdWithinADay = str === FILTER_ENUM.MAX_CREATED['24'];
      const createdWithinAWeek = str === FILTER_ENUM.MAX_CREATED['168'];
      const createdWithinTheMonth = str === FILTER_ENUM.MAX_CREATED['720'];

      return {
        any: false,
        hour: createdWithinTheHour,
        day: createdWithinADay,
        week: createdWithinAWeek,
        month: createdWithinTheMonth,
      };
    },
    rentalTypes: (str = null) => {
      if (!str) {
        return DEFAULT.rentalTypes;
      }

      const rentalTypesArray = str.split(',');

      const rentalType = Boolean(rentalTypesArray.includes('rental'));
      const roomType = Boolean(rentalTypesArray.includes('room'));
      const subletType = Boolean(rentalTypesArray.includes('sublet'));
      const corporateType = Boolean(rentalTypesArray.includes('corporate'));

      return {
        rental: rentalType,
        room: roomType,
        sublet: subletType,
        corporate: corporateType,
      };
    },
    restrictions: ({
      income = DEFAULT.restrictions.incomeRestricted,
      senior = DEFAULT.restrictions.seniorHousing,
      student = DEFAULT.restrictions.studentHousing,
      military = DEFAULT.restrictions.militaryHousing,
    }) => {
      const isValid =
        typeof income === 'boolean' &&
        typeof senior === 'boolean' &&
        typeof student === 'boolean' &&
        typeof military === 'boolean';

      const allFalse = income === false && senior === false && student === false && military === false;

      if (allFalse) {
        return {
          incomeRestricted: false,
          seniorHousing: false,
          studentHousing: false,
          militaryHousing: false,
          none: true,
        };
      }

      if (isValid) {
        return {
          incomeRestricted: income,
          seniorHousing: senior,
          studentHousing: student,
          militaryHousing: military,
          none: false,
        };
      }

      return DEFAULT.restrictions;
    },
    additionalOpts: ({
      requiresPhotos = DEFAULT.additionalOpts.requiresPhotos,
      requiresPrice = DEFAULT.additionalOpts.requiresPrice,
      forRentByOwner = DEFAULT.additionalOpts.forRentByOwner,
      acceptsSection8 = DEFAULT.additionalOpts.acceptsSection8,
      hasOffers = DEFAULT.additionalOpts.hasOffers,
      acceptingApplications = DEFAULT.additionalOpts.acceptingApplications,
    }) => {
      const isValidForRentByOwnerValue = typeof forRentByOwner === 'boolean';
      const isValidSection8Value = typeof acceptsSection8 === 'boolean';
      const isValidOffersValue = typeof hasOffers === 'boolean';
      const isValidAcceptingAppsValue = typeof acceptingApplications === 'boolean';

      return {
        requiresPhotos: requiresPhotos === 1 ? true : DEFAULT.additionalOpts.requiresPhotos,
        requiresPrice: requiresPrice === false ? true : DEFAULT.additionalOpts.requiresPrice, // False === checkbox checked in UI
        forRentByOwner: isValidForRentByOwnerValue ? forRentByOwner : DEFAULT.additionalOpts.forRentByOwner,
        acceptsSection8: isValidSection8Value ? acceptsSection8 : DEFAULT.additionalOpts.acceptsSection8,
        hasOffers: isValidOffersValue ? hasOffers : DEFAULT.additionalOpts.hasOffers,
        acceptingApplications: isValidAcceptingAppsValue
          ? acceptingApplications
          : DEFAULT.additionalOpts.acceptingApplications,
      };
    },
    nonUserFacing: ({
      feeds = DEFAULT.NON_USER_FACING.feeds,
      dupeGrouping = DEFAULT.NON_USER_FACING.dupeGrouping,
      visible = DEFAULT.NON_USER_FACING.visible,
    }) => {
      return {
        feeds,
        dupeGrouping,
        visible,
      };
    },
  },
};

const Filter = function (res = {}) {
  try {
    this.orderBy = MODEL.SPECIFIC.orderBy(res.orderBy);
    this.search = MODEL.SPECIFIC.search({ slug: res.searchSlug, title: res.searchTitle });
    this.price = MODEL.SPECIFIC.price({ min: res.lowPrice, max: res.highPrice });
    this.bedrooms = MODEL.SPECIFIC.bedrooms(res.bedrooms);
    this.bathrooms = MODEL.SPECIFIC.bathrooms(res.bathrooms);
    this.commute = MODEL.SPECIFIC.commute({
      timeMode: res.commuteTimeMode,
      time: res.commuteTime,
      mode: res.commuteMode,
      lat: res.commuteLats,
      lon: res.commuteLons,
    });
    this.pets = MODEL.GENERAL.standardTruthy(res.pets, 'pets');
    this.availability = MODEL.SPECIFIC.availability({
      start: res.startOfAvailabilityDate,
      end: res.endOfAvailabilityDate,
      hideUnknownAvailabilityDate: res.hideUnknownAvailabilityDate,
    });
    this.laundry = MODEL.GENERAL.standardTruthy(res.laundry, 'laundry');
    this.amenities = MODEL.GENERAL.standardTruthy(res.amenities, 'amenities');
    this.furnished = MODEL.SPECIFIC.furnished(res.furnished);
    this.propertyTypes = MODEL.SPECIFIC.propertyTypes(res.propertyTypes);
    this.sqft = MODEL.SPECIFIC.sqft({ min: res.minSqft, max: res.maxSqft });
    this.createdWithin = MODEL.SPECIFIC.createdWithin(res.maxCreated);
    this.rentalTypes = MODEL.SPECIFIC.rentalTypes(res.listingTypes);
    this.restrictions = MODEL.SPECIFIC.restrictions({
      senior: res.seniorHousing,
      student: res.studentHousing,
      military: res.militaryHousing,
      income: res.incomeRestricted,
    });
    this.keywords = res.keywords || DEFAULT.keywords;
    this.additionalOpts = MODEL.SPECIFIC.additionalOpts({
      requiresPhotos: res.minPhotos,
      requiresPrice: res.includeVaguePricing,
      forRentByOwner: res.isListedByOwner,
      acceptsSection8: res.acceptsSection8,
      hasOffers: res.hasSpecialOffers,
      acceptingApplications: res.isAcceptingRentalApplications,
    });
    this.NON_USER_FACING = MODEL.SPECIFIC.nonUserFacing({
      feeds: res.feeds,
      dupeGrouping: res.dupeGrouping,
      visible: res.visible,
    });
  } catch (error) {
    throw Error(error);
  }
};

export default Filter;

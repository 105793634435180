// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase */
import find from 'lodash/find';
import isString from 'lodash/isString';
import result from 'lodash/result';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';

import allStates from 'app/shared/utils/states.json';
import constants from 'app/shared/constants/ConstantsBundle';
import states from 'app/shared/utils/states'; // need to move this away...
import formatter from 'app/shared/utils/formatter';
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';

// 'apartments-for-rent' => 'Apartments for rent'
// 'san-francisco' => 'San Francisco'
const toUpperCaseSpaceDelimited = function (dashDelimitedString) {
  return dashDelimitedString
    .split('-')
    .map((word) => {
      return upperFirst(word);
    })
    .join(' ');
};

const areaUtils_exploreLinks = ({ area, filter }) => {
  if (!area) {
    return [];
  }
  const exploreState = area.state;
  const exploreLinks = areaUtils_getTopCities(exploreState).map((city) => {
    const resourceId = city + '-' + exploreState; // 'san-francisco' + '-' + 'ca'
    const uri = '/' + resourceId + '/' + filter.search.slug; // '/' + 'san-francisco' + '/' + 'apartments-for-rent'
    // 'San Francisco' + ' ' + 'apartments for rent'
    const name = toUpperCaseSpaceDelimited(city) + ' ' + toUpperCaseSpaceDelimited(filter.search.slug);

    return {
      uri: uri.toLowerCase(),
      name,
    };
  });

  return exploreLinks;
};
const areaUtils_isInSchoolZone = (areaType) => {
  const schoolTypes = new Set([
    'elemschdist',
    'highschool',
    'middleschool',
    'mixedschool',
    'primaryschool',
    'secschdist',
    'unifschdist',
    'university',
  ]);

  return schoolTypes.has(areaType);
};
// TODO: Soon API will be returning a `name` param that is always populated, we should use that.
const areaUtils_getCityName = (cityId) => {
  const segments = cityId.split('-');

  // use slice to accomodate multi-word city names
  // (ex. new-brunswick-nj -> New Brunswick)
  const cityArray = segments.slice(0, -1);
  const cityName = formatter.string.firstCaps(cityArray.join(' '));

  return cityName;
};
const areaUtils_getStateName = (abbreviation) => {
  const allCaps = abbreviation.toUpperCase();

  const stateName = result(
    find(states, {
      abbreviation: allCaps,
    }),
    'name',
  );

  return stateName;
};
const areaUtils_stateNameForAbbreviation = (abbr) => {
  /**
   * @param {string} abbr - State abbreviation ('AL', 'ak', etc.).
   * @returns {string} full name of the state.
   */
  let state;

  if (isString(abbr)) {
    abbr = abbr.toUpperCase();
    state = find(states, ['abbreviation', abbr]);
  }

  return result(state, 'name') || abbr;
};
const areaUtils_getFullNameFromArea = (area) => {
  const state = area.state || '';

  if (area.type === 'state') {
    // e.g. 'California'
    return areaUtils_stateNameForAbbreviation(area.state);
  }
  if (area.type === 'city') {
    // e.g. 'San Francisco, CA'
    return [area.name, state].join(', ');
  }
  if (area.type === 'neighborhood') {
    if (!area.city) {
      return [area.name, state].join(', ');
    }

    // e.g. 'Outer Mission, San Francisco, CA'
    return [area.name, area.city, state].join(', ');
  }
  if (area.type === 'county') {
    const lastAreaName = area.name.split(' ').pop();
    const areaSuffix = lastAreaName === 'County' ? '' : ' County';

    // e.g. 'San Francisco County, CA'
    return [area.name + areaSuffix, state].join(', ');
  }

  // For all other types, just use area name
  // e.g. '94107'
  // e.g. 'City College Of San Francisco'
  // e.g. 'Roosevelt Middle'
  // e.g. 'San Francisco Unified School District'

  return area.name;
};
const areaUtils_getNameFromArea = (area) => {
  const lastAreaName = area.name.split(' ').pop();

  if (area.type === 'county' && lastAreaName !== 'County') {
    return area.name + ' County';
  }

  return area.name;
};
const areaUtils_getAffordableOrLuxurySlugPageLimit = (maxPage) => {
  return maxPage > constants.SRP_MAX_PAGE_LIMIT ? constants.SRP_MAX_PAGE_LIMIT : Math.floor(maxPage / 2);
};
const areaUtils_getSrpMaxPageLimit = (maxPage, searchSlug) => {
  if (searchSlugUtils.isAffordableOrLuxSearchSlug(searchSlug)) {
    return areaUtils_getAffordableOrLuxurySlugPageLimit(maxPage);
  }

  return maxPage > constants.SRP_MAX_PAGE_LIMIT ? constants.SRP_MAX_PAGE_LIMIT : maxPage;
};

const areaUtils_getTopCities = (stateAbbreviation) => {
  let state;

  if (isString(stateAbbreviation)) {
    state = find(states, ['abbreviation', stateAbbreviation.toUpperCase()]);
  }

  if (state) {
    return state.topCities;
  } else {
    return [];
  }
};
const areaUtils_forTypeOf = {
  // Given this type:
  areacode: {
    // How to display it in grammatical context
    display: {
      specific: 'Area Code',
      plural: 'Area Codes',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  borough: {
    display: {
      specific: 'Borough',
      plural: 'Boroughs',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  city: {
    display: {
      specific: 'City',
      plural: 'Cities',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  congressional: {
    display: {
      specific: 'Congressional District',
      plural: 'Congressional Districts',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  country: {
    display: {
      specific: 'United States',
      plural: 'United States',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  county: {
    display: {
      specific: 'County',
      plural: 'Counties',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  elemschdist: {
    display: {
      specific: 'Elementary School District',
      plural: 'Elementary School Districts',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  highschool: {
    display: {
      specific: 'High School',
      plural: 'High Schools',
    },
    prepositions: {
      main: 'near',
      secondary: 'from',
    },
  },
  metroarea: {
    display: {
      specific: 'Metro Area',
      plural: 'Metro Areas',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  middleschool: {
    display: {
      specific: 'Middle School',
      plural: 'Middle Schools',
    },
    prepositions: {
      main: 'near',
      secondary: 'from',
    },
  },
  mixedschool: {
    display: {
      specific: 'Mixed School',
      plural: 'Mixed Schools',
    },
    prepositions: {
      main: 'near',
      secondary: 'from',
    },
  },
  neighborhood: {
    display: {
      specific: 'Neighborhood',
      plural: 'Neighborhoods',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  primaryschool: {
    display: {
      specific: 'Primary School',
      plural: 'Primary Schools',
    },

    prepositions: {
      main: 'near',
      secondary: 'from',
    },
  },
  secschdist: {
    display: {
      specific: 'Seconday School District',
      plural: 'Seconday School Districts',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  state: {
    // How to display it in grammatical context
    display: {
      specific: 'State',
      plural: 'States',
    },
    // Which types are related to this type
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  subdivision: {
    display: {
      specific: 'Subdivision',
      plural: 'Subdivisions',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  unifschdist: {
    display: {
      specific: 'Unified School District',
      plural: 'Unified School Districts',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
  university: {
    display: {
      specific: 'University',
      plural: 'Universities',
    },
    prepositions: {
      main: 'near',
      secondary: 'from',
    },
  },
  zip: {
    display: {
      specific: 'ZIP code',
      plural: 'ZIP codes',
    },
    prepositions: {
      main: 'in',
      secondary: 'from',
    },
  },
};
const areaUtils_validType = (type) => {
  return Boolean(areaUtils_forTypeOf[type]);
};
const areaUtils_getBestArea = (state) => {
  const currentArea = state.area.area;
  const recentArea = state.user.search.recent && state.user.search.recent.areaInfo;
  const geolocation = state.geolocation.area;
  if (!isEmpty(currentArea)) {
    return currentArea;
  } else if (!isEmpty(recentArea)) {
    return recentArea;
  } else {
    return geolocation;
  }
};
const areaUtils_validateMapForUpdatingRecentSearch = (mapData, border) => {
  if (border !== false) {
    return true;
  }
  const zoom = mapData.zoom;
  if (zoom && zoom <= 9) {
    return false;
  } else {
    return true;
  }
};
const areaUtils_stateAreaForPath = (path = '') => {
  let name = trim(path, '/ ');
  name = toLower(name);

  return find(allStates, ({ resourceId }) => {
    return name === resourceId;
  });
};

export {
  areaUtils_exploreLinks,
  areaUtils_isInSchoolZone,
  areaUtils_getCityName,
  areaUtils_getStateName,
  areaUtils_stateNameForAbbreviation,
  areaUtils_getFullNameFromArea,
  areaUtils_getNameFromArea,
  areaUtils_getSrpMaxPageLimit,
  areaUtils_getAffordableOrLuxurySlugPageLimit,
  areaUtils_validType,
  areaUtils_getTopCities,
  areaUtils_forTypeOf,
  areaUtils_getBestArea,
  areaUtils_validateMapForUpdatingRecentSearch,
  areaUtils_stateAreaForPath,
};

export const zindex = {
  '$z-index-max': 1000,
  '$z-index-over-loader': 600, // hamburger-nav-white-overlay
  '$z-index-loader': 500, // hamburger-nav-white-overlay
  '$z-index-over-overlay': 400,
  '$z-index-overlay': 300,
  '$z-index-header': 275,
  '$z-index-under-header': 270, // hamburger-nav
  '$z-index-over-grand-parent': 250, // hamburger-nav
  '$z-index-grand-parent': 200, // header-nav
  '$z-index-over-parent': 150, // streetview options
  '$z-index-parent': 100, // streetview, geolocate-btn in search
  '$z-index-step-over': 3,
  '$z-index-step': 2,
  '$z-index-base': 1,
  '$z-index-under': -1,
};

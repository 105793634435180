import type { MarlinOptions, MetricType } from './request-types';
import { createClient } from '@zg-rentals/http-client';
import { getEnvironment, injectAppNameHeader, isDev, isTest } from '@zg-rentals/environment-utils';

import type {
  MarlinJsCountBatchRequestDto,
  MarlinJsGaugeBatchRequestDto,
  MarlinJsMeasurementBatchRequestDto,
} from '@zg-rentals/ts-marlin-client';

import {
  MarlinJsMetricsCollectionHandler_addCounts,
  MarlinJsMetricsCollectionHandler_addGauges,
  MarlinJsMetricsCollectionHandler_addMeasurements,
  MarlinJsRefererMetricsCollectionHandler_addCounts,
  MarlinJsRefererMetricsCollectionHandler_addGauges,
  MarlinJsRefererMetricsCollectionHandler_addMeasurements,
  type PublishingResponseDto,
} from '@zg-rentals/ts-marlin-client';

function getHandlers(useCrossOrigin?: boolean) {
  if (useCrossOrigin) {
    return {
      addCounts: MarlinJsRefererMetricsCollectionHandler_addCounts,
      addGauges: MarlinJsRefererMetricsCollectionHandler_addGauges,
      addMeasurements: MarlinJsRefererMetricsCollectionHandler_addMeasurements,
    };
  }
  return {
    addCounts: MarlinJsMetricsCollectionHandler_addCounts,
    addGauges: MarlinJsMetricsCollectionHandler_addGauges,
    addMeasurements: MarlinJsMetricsCollectionHandler_addMeasurements,
  };
}

export async function postMarlin(
  type: MetricType,
  payload: (MarlinJsCountBatchRequestDto | MarlinJsGaugeBatchRequestDto | MarlinJsMeasurementBatchRequestDto) & {
    browserTime?: number;
  },
  options: MarlinOptions,
) {
  if (isDev() || isTest()) {
    return {};
  }

  if (typeof window !== 'undefined') {
    payload.browserTime = Date.now();
  }
  const environment = getEnvironment();
  if (environment) {
    payload.environment = environment;
  }

  const { headers, appName, api } = options;

  const apiKey = await Promise.resolve(typeof options.apiKey === 'function' ? options.apiKey() : options.apiKey);

  // can't send to marlin without api key
  if (options.apiKey != null && apiKey == null) {
    return {};
  }

  const client = createClient({
    prefixUrl: api,
    credentials: options.useCrossOrigin ? 'omit' : 'include',
    // Closer perf to window.sendBeacon since we need custom headers
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
    keepalive: true,
    headers: {
      ...injectAppNameHeader(headers, appName),
      apiKey,
    },
  });

  const handlers = getHandlers(options.useCrossOrigin);

  try {
    let response: PublishingResponseDto;
    switch (type) {
      case 'count':
        response = await handlers.addCounts(payload as MarlinJsCountBatchRequestDto, client);
        break;
      case 'gauge':
        response = await handlers.addGauges(payload as MarlinJsGaugeBatchRequestDto, client);
        break;
      case 'measure':
        response = await handlers.addMeasurements(payload as MarlinJsMeasurementBatchRequestDto, client);
        break;
    }
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
}

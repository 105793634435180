// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';
import Head from 'react-helmet';
import includes from 'lodash/includes';
import { withRouter } from 'react-router-dom';
import { pageView } from 'app/client/universal-analytics';
import Linker from 'app/shared/modules/Linker';
import pathsManifest from 'app/shared/utils/pathsManifest';
import * as S from 'app/shared/pages/NotFoundPage/styles';
import { areaUtils_exploreLinks } from 'app/shared/utils/areaUtils';
import Container from 'app/shared/core/Container';
import Section from 'app/shared/modules/Section';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';
import AutocompleteSearchInput from 'app/shared/modules/location/AutocompleteSearchInput';
import SkeletonSuggestedRentals from 'app/shared/modules/skeleton/SkeletonSuggestedRentals';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';
import notFoundUtils from 'app/shared/utils/notFoundUtils';
import routeUtils from 'app/shared/utils/routeUtils';

import animationData from 'images/animations/404animation.json';
const StyledTitle = styled(Title)`
  ${stacked._1x};
`;

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      exploreLinks: [],
    };

    this.props.dispatch(pageView());
  }
  componentDidMount() {
    const { dispatch, geolocation } = this.props;

    dispatch(UserSearchActions.getRecentSearches())
      .then((data) => {
        // Prioritize recent search; geolocation as fallback
        const area = (data && data.areaInfo) || geolocation;

        return areaUtils_exploreLinks({
          area,
          filter: this.props.filter,
        });
      })
      .then((links) => {
        this.setState({ exploreLinks: links, isLoading: false });
      });
  }
  serverRouteWillMount(reactContext) {
    const pathname = reactContext.state.pathname;
    return { changeHttpStatus: notFoundUtils.getHttpStatus(pathname) };
  }
  shouldShowForSaleHdpError = () => {
    const { pathname } = this.props;
    const isForSaleHDP = includes(pathname, 'for-sale') && !routeUtils.isAreaUrl(pathname);
    return isForSaleHDP;
  };
  render() {
    const { exploreLinks } = this.state;
    const title = this.shouldShowForSaleHdpError()
      ? 'HotPads no longer has for sale listings.'
      : 'Yikes, this page was not found.';
    const text = this.shouldShowForSaleHdpError()
      ? 'In order to continue delivering a great rental search experience, we have removed for sale listings. ' +
        "We are committed to creating a great rental search experience and hope you'll continue to be part of our journey."
      : ' Go back to browsing pads that are hot.';

    return (
      <S.NotFoundPage>
        <Head
          meta={[
            {
              name: 'description',
              content:
                'Sorry! The webpage you were trying to reach could not be found. ' +
                'Head to hotpads.com to start your search!',
            },
          ]}
          title="Page not found | HotPads"
        />
        <Container>
          <S.NotFoundPageLeft>
            <Section>
              <Title size="hero">{title}</Title>
              <Text size="lg">{text}</Text>
            </Section>
            <Section>
              <S.NotFoundPageAutocompleteWrapper>
                <AutocompleteSearchInput large />
              </S.NotFoundPageAutocompleteWrapper>
            </Section>
            <S.NotFoundPageSubColumn>
              {this.state.isLoading ? (
                <SkeletonSuggestedRentals />
              ) : (
                exploreLinks.length > 0 && (
                  <Fragment>
                    <StyledTitle size="md">Search for hot rentals in</StyledTitle>
                    <ul>
                      {exploreLinks.map((city, i) => {
                        return (
                          <li key={'city' + i}>
                            <Linker to={city.uri}>{city.name}</Linker>
                          </li>
                        );
                      })}
                    </ul>
                  </Fragment>
                )
              )}
            </S.NotFoundPageSubColumn>
            <S.NotFoundPageSubColumn>
              <StyledTitle size="md">Other things to do</StyledTitle>
              <div>
                <Linker to="mailto:info@hotpads.com">Report an issue</Linker>
              </div>
              <div>
                <Linker to={pathsManifest.FrontPage}>Go to home page</Linker>
              </div>
            </S.NotFoundPageSubColumn>
          </S.NotFoundPageLeft>
          <S.NotFoundPageHouse playTimer={1000} animationData={animationData} />
        </Container>
      </S.NotFoundPage>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    geolocation: state.geolocation.area,
    filter: state.filter,
    pathname: ownProps.location.pathname,
  };
};

export default withRouter(connect(mapStateToProps)(NotFoundPage));

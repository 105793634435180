// FeeOrDeposit.ts
import numberUtils from 'app/shared/utils/numberUtils';

interface FeeOrDepositParams {
  feeType?: string;
  fee?: number;
}

class FeeOrDeposit {
  feeType: string;
  fee: string;

  constructor({ feeType = '', fee = 0.0 }: FeeOrDepositParams = {}) {
    this.feeType = String(feeType);
    this.fee = numberUtils.asCurrency(fee);
  }
}

export default FeeOrDeposit;

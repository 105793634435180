// @ts-nocheck
/* eslint-enable */
import styled, { css, keyframes } from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';
import { viewports } from 'app/shared/styles/_breakpoints';
import { noScrollBar } from 'app/shared/styles/_mixins';
import { padding } from 'app/shared/styles/_spacing';

const fadeInAndUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(150px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`;

const SIZES = {
  xs: '360px',
  sm: '540px',
  md: '720px',
  lg: '900px',
  xl: '1080px',
  xxl: '1240px',
};

export const PopupModalOverlay = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${zindex['$z-index-over-loader']};
  background: ${colors['$hp-modal-overlay']};
  overflow-y: auto;

  ${(props) =>
    props.darkOverlay &&
    `
        background: ${colors['$hp-gray-translucent']};
    `};
`;

export const PopupModalContent = styled.div`
  ${noScrollBar};
  background: ${colors['$hpx-white']};
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;

  @media ${viewports['md-and-up']} {
    position: fixed;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    /* width: auto; */
    height: auto;
    max-height: 100%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.15);

    ${(props) =>
      props.fullHeight &&
      css`
        height: 100%;
      `};

    ${(props) =>
      props.size &&
      props.size === 'auto' &&
      css`
        width: auto;
      `};

    ${(props) =>
      props.size &&
      css`
        max-width: ${SIZES[props.size]};
      `};

    ${(props) =>
      props.isFullScreen &&
      css`
        border-radius: 0;
        width: 100vw;
        height: 100vh;
      `};

    ${(props) =>
      props.position &&
      props.position === 'top' &&
      css`
        top: 35%;
      `};
  }

  ${(props) =>
    props.slideUp &&
    css`
      ${noScrollBar};
      top: calc(100vh - 340px);
      left: 2.5%;
      width: 95%;
      position: fixed;
      box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.15);

      animation-name: ${fadeInAndUp};
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
      animation-duration: 0.3s;

      @media ${viewports['md-and-up']} {
        animation: none;
        top: 50%;
        left: 50%;
        width: 350px;
      }
    `};

  ${(props) =>
    props.paddingTop &&
    props.paddingTop !== '0' &&
    !props.floatingCloseButton &&
    css`
      ${padding.top[`_${props.paddingTop}`]};
    `};

  ${(props) =>
    props.paddingBottom &&
    props.paddingBottom !== '0' &&
    css`
      ${padding.bottom[`_${props.paddingBottom}`]};
    `};

  ${(props) =>
    props.paddingInline &&
    css`
      ${padding.left[`_${props.paddingInline}`]};
      ${padding.right[`_${props.paddingInline}`]};
    `};
`;

export const PopupModalCloseButton = styled.button`
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;

  &:focus {
    outline-offset: -1px;
  }

  ${(props) =>
    props.isFullScreen &&
    css`
      top: 4px;

      @media ${viewports['md-and-up']} {
        top: 12px;
        right: 12px;
      }
    `};

  ${(props) =>
    props.floatingCloseButton &&
    css`
      position: absolute;
      right: -48px;
      z-index: ${zindex['$z-index-max']};
    `};
`;

export const ScrollableContent = styled.section`
  height: 100%;
  overflow: auto;
`;

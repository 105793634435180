interface DeviceInfo {
  deviceType: string;
  customVariableSiteType: string;
}

/**
 * Detects the type of device based on the user agent string.
 *
 * @param {string} ua - The user agent string to analyze.
 * @returns {DeviceInfo} An object containing `deviceType` and `customVariableSiteType`.
 */
const phabletDetect = function (ua: string): DeviceInfo {
  let deviceType = 'desktop';
  let customVariableSiteType = 'desktopWeb';

  // if mobile phone or android
  if (ua.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|MeeGo/i)) {
    // this checks the ua for the mobile string which is absent in android tablets
    if (ua.match(/Mobile/i)) {
      deviceType = 'mobile';
      customVariableSiteType = 'mobileWeb';
    } else {
      deviceType = 'tablet';
      customVariableSiteType = 'mobileWeb';
    }
  }

  // if ipad or kindle-ish
  if (ua.match(/iPad|Kindle|Silk|KFAPW|KFARWI|KFASWI|KFFOWI|KFJW|KFMEWI|KFOT|KFSAW|KFSOWI|KFTBW|KFTHW|KFTT|WFFOWI/i)) {
    deviceType = 'tablet';
    customVariableSiteType = 'mobileWeb';
  }

  return {
    deviceType,
    customVariableSiteType,
  };
};

export default phabletDetect;

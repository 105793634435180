import { page as recordPageView } from '@zillow/universal-analytics';
import get from 'lodash/get';
import { setAreaDimensions, setBaseDimensions, setListingDimensions } from './dimensions';
import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import type { ClickstreamEvent } from 'app/types';

interface NewLaneTrackObject {
  newLaneEvent?: ClickstreamEvent;
}

// Handles all page views, except listing and area pages
export function pageView({
  newLaneEvent,
}: {
  newLaneEvent?: ClickstreamEvent | null;
} = {}): ThunkAction<void, any, unknown, AnyAction> {
  return function (dispatch, getState) {
    const state = getState();
    const pathname = get(state, 'location.current.pathname');

    setBaseDimensions(state);
    const page = pathname;

    const optionalObject: NewLaneTrackObject = {};

    if (newLaneEvent) {
      optionalObject.newLaneEvent = newLaneEvent;
    }

    recordPageView(page, {}, optionalObject);
  };
}

// Area Page View
export function areaPageView({
  newLaneEvent,
}: {
  newLaneEvent?: ClickstreamEvent | null;
} = {}): ThunkAction<void, any, unknown, AnyAction> {
  return function (dispatch, getState) {
    const state = getState();
    const pathname = get(state, 'location.current.pathname');

    setBaseDimensions(state);
    setAreaDimensions(state);
    const page = pathname;

    const optionalObject: NewLaneTrackObject = {};

    if (newLaneEvent) {
      optionalObject.newLaneEvent = newLaneEvent;
    }

    recordPageView(page, {}, optionalObject);
  };
}

// Listing Page View
export function listingPageView({
  newLaneEvent,
}: {
  newLaneEvent?: ClickstreamEvent | null;
} = {}): ThunkAction<void, any, unknown, AnyAction> {
  return function (dispatch, getState) {
    const state = getState();
    const currentListing = get(state, 'currentListingDetails.currentListing');
    const pathname = get(state, 'location.current.pathname');

    if (!currentListing) {
      return;
    }

    setBaseDimensions(state);
    setListingDimensions(state);
    const page = pathname;

    const optionalObject: NewLaneTrackObject = {};

    if (newLaneEvent) {
      optionalObject.newLaneEvent = newLaneEvent;
    }

    recordPageView(page, {}, optionalObject);
  };
}

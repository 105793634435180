import type { BaseMonitor } from './baseMonitor';
import type { MonitorPlugin } from './plugin';
import type { MonitorReporter } from './reporter';
import { makeModuleInstance, warningBanner } from '@zg-rentals/util';

export * from './baseMonitor';
export * from './statController';
export * from './plugin';
export * from './reporter';
export * from './plugins';
export * from './reporters';

export const { get: getGlobalMonitor, set: setGlobalMonitor } = makeModuleInstance<
  BaseMonitor<MonitorPlugin, MonitorReporter> | undefined
>(undefined);

export { warningBanner };

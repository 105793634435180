// @ts-nocheck
/* eslint-enable */
import { logError } from '@zg-rentals/log-error';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('models');

function decode(string) {
  return import(/* webpackChunkName: "he" */ 'he').then(({ default: he }) => {
    return he.decode(string);
  });
}

class Review {
  constructor({
    reviewerType = '',
    reviewType = '',
    reviewStatus = '',
    reviewIdAsString = '',
    created = 0,
    starLevel = 0,
    reviewMessage = '',
    userVote = '',
    netVotes = 0,
    responseMessage = '',
    reviewResponseDate = 0,
    reviewResponseStatus = '',
    neighborhoodResourceId = '',
    neighborhoodName = '',
    cityResourceId = '',
    cityName = '',
    countyResourceId = '',
    countyName = '',
    inactiveNeighborhood = false,
  } = {}) {
    try {
      reviewStatus = reviewStatus.toUpperCase();
      this.reviewType = String(reviewType).toUpperCase();
      this.inactiveNeighborhood = Boolean(inactiveNeighborhood);
      this.reviewerType = String(reviewerType).toUpperCase();
      this.reviewStatus = String(reviewStatus);
      this.reviewId = String(reviewIdAsString);
      this.created = Number(created);
      this.starLevel = Number(starLevel);
      this.reviewMessage = String(reviewMessage);
      this.userVote = String(userVote);
      this.netUpvotes = Number(netVotes);
      this.starsOnly = String(reviewStatus) !== 'OK';
      this.responseMessage = String(responseMessage);
      this.reviewResponseDate = reviewResponseDate ? Number(reviewResponseDate) : 0;
      this.reviewResponseStatus = reviewResponseStatus ? String(reviewResponseStatus).toUpperCase() : '';
      if (this.reviewType === 'AREA') {
        this.title = neighborhoodName || cityName || countyName;
        this.resourceId = neighborhoodResourceId || cityResourceId || countyResourceId;
      } else {
        if (reviewerType === 'FUTURE_RESIDENT') {
          this.title = 'Prospective tenant';
        } else if (reviewerType === 'CURRENT_RESIDENT') {
          this.title = 'Current tenant';
        } else if (reviewerType === 'PAST_RESIDENT') {
          this.title = 'Past tenant';
        }
      }
    } catch (err) {
      logError({ error: err, errorType: 'DataModelError', context: 'Review' });
      return false;
    }
  }

  static build(opts = {}) {
    const promises = [];

    if (opts.responseMessage) {
      promises.push(decode(opts.responseMessage).then((decoded) => (opts.responseMessage = decoded)));
    }

    if (opts.reviewMessage) {
      promises.push(decode(opts.reviewMessage).then((decoded) => (opts.reviewMessage = decoded)));
    }

    return Promise.all(promises).then(() => {
      return new Review(opts);
    });
  }
}

export default Review;

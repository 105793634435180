import { areaUtils_getFullNameFromArea } from 'app/shared/utils/areaUtils';
import { filterUtils_getFilterLabels } from 'app/shared/utils/filterUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import MapData from 'app/shared/models/MapData';

import isString from 'lodash/isString';

// Define interfaces for the input types
interface Filter {
  [key: string]: any; // Adjust based on the actual filter structure
}

interface QueryObj {
  [key: string]: any; // Adjust based on the actual queryObj structure
}

interface AreaInfo {
  city?: string;
  state?: string;
  type?: string;
  searchSlug?: string; // Assuming this is also part of filter if used in path generation
  [key: string]: any; // Adjust based on the actual areaInfo structure
}

interface MapDataProps {
  // Define the properties of MapData based on its constructor
  [key: string]: any;
}

interface RecentSearchParams {
  hasBoundary?: boolean;
  resourceId?: string;
  filter?: Filter;
  queryObj?: QueryObj;
  mapData?: MapDataProps;
  areaInfo?: AreaInfo;
}

// Define the RecentSearch class
class RecentSearch {
  areaInfo: AreaInfo;
  filter: Filter;
  filterDescription: string;
  filterDescriptionV2: string;
  locationString: string;
  mapData: MapData;
  name: string;
  url: string;

  constructor({
    hasBoundary = false,
    resourceId = '',
    filter = {},
    queryObj = {},
    mapData = {},
    areaInfo = {},
  }: RecentSearchParams = {}) {
    const name = 'Resume last search';
    const filterDescription = filterUtils_getFilterLabels(filter);
    const filterDescriptionV2 = filterUtils_getFilterLabels(filter);

    const queryParams = queryUtils.stringify(queryObj);
    const path = routeUtils.buildAreaPath({
      areaResourceId: resourceId,
      searchSlug: filter.searchSlug, // Assuming searchSlug is a part of the filter
    });
    const url = path + queryParams;

    const hasAreaAndState = Boolean(areaInfo.city) && Boolean(areaInfo.state);
    const inOrNear = hasBoundary ? 'in' : 'near';
    const locationString = hasAreaAndState ? `${inOrNear} ${areaInfo.city}, ${areaInfo.state}` : '';

    if (isString(areaInfo.type)) {
      areaInfo.fullName = areaUtils_getFullNameFromArea(areaInfo);
    }

    this.areaInfo = { ...areaInfo };
    this.filter = { ...filter };
    this.filterDescription = String(filterDescription);
    this.filterDescriptionV2 = String(filterDescriptionV2);
    this.locationString = String(locationString);
    this.mapData = new MapData(mapData);
    this.name = String(name);
    this.url = String(url);
  }
}

export default RecentSearch;

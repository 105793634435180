import type { Logger } from '@zg-rentals/logger-base';
import type { MetricFnInterface } from '../../reporter';
import type { MetricOptions } from '../../baseMonitor';
import { MonitorReporter } from '../../reporter';

export type LogLevel = 'info' | 'warn' | 'error' | 'debug' | 'trace';
export type LogReporterFilter = (name: string, amount?: number) => boolean;

export class LogReporter extends MonitorReporter {
  filter?: LogReporterFilter;
  level: LogLevel;
  log?: Logger['trace'];

  constructor({
    logger,
    level = 'info',
    filter,
    sampleRate,
  }: {
    logger?: Logger;
    level?: LogLevel;
    filter?: LogReporterFilter;
    sampleRate?: number;
  } = {}) {
    super({
      logger,
      reporterName: 'LogReporter',
      sampleRate,
    });

    this.level = level;
    this.filter = filter;
    this.setLogLevel();
  }

  setLogLevel() {
    this.log = this.logger && this.logger[this.level].bind(this.logger);
  }

  onInitialize(logger?: Logger) {
    this.logger = this.logger || logger;
    this.setLogLevel();
  }

  shouldLog(name: string, amount?: number, options: MetricOptions = {}) {
    if (this.log && (!this.filter || this.filter(name, amount))) {
      const random = Math.random();
      return (
        (this.sampleRate === 1 || random <= this.sampleRate) &&
        (typeof options.sampleRate !== 'number' || random <= options.sampleRate)
      );
    }
  }

  serializeTags(tags?: Record<string, unknown>) {
    const tagStrings: Array<string> = [];
    if (tags) {
      Object.keys(tags).forEach((key) => {
        tagStrings.push(`${key}=${tags[key]}`);
      });
    }
    return tagStrings.length ? `; ${tagStrings.join(', ')}` : '';
  }

  count({ name, amount = 1, options }: { name: string; amount: number; options?: MetricOptions }) {
    if (this.shouldLog(name, amount, options)) {
      this.log!(`(count) ${name}: +${amount}${this.serializeTags(options?.tags)}`);
    }

    return Promise.resolve();
  }

  gauges({ name, amount, options }: { name: string; amount: number; options?: MetricOptions }): Promise<void> {
    if (this.shouldLog(name, amount, options)) {
      this.log!(`(gauge) ${name}: ${amount}${this.serializeTags(options?.tags)}`);
    }

    return Promise.resolve();
  }

  measurementWithGauges(args: MetricFnInterface): Promise<void> {
    return this.gauges(args);
  }

  onError({ error }: { error: Error }) {
    this.logger?.error(error, 'error from log-reporter');

    return Promise.resolve();
  }
}

// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import { findListingIndex, updateUserItemTypes } from 'app/shared/utils/listingsReducerUtils';

const initState = () => ({
  fetchListingFailed: false,
  isListingLoading: false,
  similarListings: [],
  relatedRentals: [],
});

const mapActionsToReducer = {
  [constants.RESET_FETCH_LISTING_STATE]: (state, action = {}) => {
    const { listingIsLoading } = action.payload;

    return assign({}, state, {
      fetchListingFailed: false,
      isListingLoading: listingIsLoading,
    });
  },
  [constants.FETCH_LISTING_FAIL]: (state, action = {}) => {
    const { error } = action.payload;

    return assign({}, state, {
      fetchListingFailed: error,
      isListingLoading: false,
    });
  },
  [constants.FETCH_LISTING_SUCCESS]: (state) => {
    return assign({}, state, {
      fetchListingFailed: false,
      isListingLoading: false,
    });
  },
  [constants.FETCH_SIMILAR_LISTINGS_SUCCESS]: (state, action = {}) => {
    const payload = action.payload;

    return assign({}, state, {
      similarListings: payload,
    });
  },
  [constants.FETCH_SIMILAR_LISTINGS_RESET]: (state) => {
    return assign({}, state, {
      similarListings: [],
    });
  },
  [constants.FETCH_RELATED_RENTALS_SUCCESS]: (state, action = {}) => {
    const payload = action.payload;

    return assign({}, state, {
      relatedRentals: payload,
    });
  },
  [constants.FETCH_RELATED_RENTALS_RESET]: (state) => {
    return assign({}, state, {
      relatedRentals: [],
    });
  },
  [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (state, action) => {
    const listing = action.payload.listing;
    const type = action.payload.type; // favorite, viewed, hidden, inquiry, etc.
    const addOrRemove = action.payload.action; // add, remove

    if (!listing.maloneLotIdEncoded || !listing.geo) {
      return state;
    }

    let tempListingToUpdate;
    const indexInSimilarListings = findListingIndex(state.similarListings, listing);

    if (indexInSimilarListings > -1) {
      state.similarListings = [].concat(state.similarListings);
      tempListingToUpdate = assign({}, state.similarListings[indexInSimilarListings]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.similarListings[indexInSimilarListings] = tempListingToUpdate;
    }

    return assign({}, state);
  },
};

const fetchListing = reduxUtils.createReducer(mapActionsToReducer, initState());

export default fetchListing;

const StringConstants = {
  //http method types
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete',

  REP_HDP_URI: '/listing-tools/rep/hdp?aliasEncoded=',

  FETCH_ALL_COMPONENTS: 'all',
  FETCH_BUILDING_COMPONENTS: 'areas,basic,layouts,malone,details,listing_photo,pricing,useritem',
  OPTIONAL_COMPONENTS:
    'responsiveness,company_photo,popularity,listedby,pricing_stats,lotIdReviews,schools,company,floorplan',
  OPTIONAL_COMPONENTS_TIMEOUT: 100,

  CONTACT_INQUIRY_PREFIX: `I'd like to schedule a viewing for `,
  CONTACT_INQUIRY_SUFFIX: 'Please contact me with more information!',

  APPLICATION_REQUEST_INQUIRY: `I'd like to request an application for this property.`,

  LISTING_VIEWED: 'viewed',
  EXPIRED_LISTING_VIEWED: 'expiredListingViewed',
  LISTING_REALLY_VIEWED: 'listingReallyViewed',
  EXPIRED_LISTING_REALLY_VIEWED: 'expiredListingReallyViewed',

  LISTING_NOT_FOUND: 'LISTING_NOT_FOUND',
  MALONE_LOT_ID_NOT_FOUND: 'MALONE_LOT_ID_NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',

  NOTIFICATION_DEVICE_EMAIL: 'EMAIL',
  NOTIFICATION_DEVICE_MOBILE: 'NATIVE_MOBILE',
  NOTIFICATION_DEVICE_BROWSER: 'BROWSER',
  NOTIFICATION_REC_SEARCH: 'REC_SEARCH',
  NOTIFICATION_SAVED_SEARCH: 'SAVED_SEARCH',
  NOTIFICATION_PROPERTY_UPDATE: 'PROPERTY_UPDATE',

  ALL_LISTINGS: 'AllListings',
  HOTPADS_MAIN: 'HotPadsMain',
  UNSEARCHABLE: 'Unsearchable',
  HOTPADS_MAIN_PREMIUM: 'HotPadsMainPremium', // paid listings

  // Filter Descriptions used in `AreaHead/Controller`
  APARTMENTS_FOR_RENT: 'Apartments for Rent',
  FOR_RENT_BY_OWNER: 'for Rent By Owner',
  HOUSES_FOR_RENT: 'Houses for Rent',

  // User action constants
  FAVORITE: 'favorite',
  INQUIRY: 'inquiry',
  FAVORITE_AND_INQUIRY: 'favorite,inquiry',
  HIDDEN: 'hidden',
  VIEWED: 'viewed',

  // Freepass login error string constants
  FREEPASS_DEFAULT: 'An error occured, please try again later.',

  // Facebook login error strings constants
  FACEBOOK_DEFAULT: 'Something went wrong. Unable to login with Facebook.',

  // Sign In With Google login error string constants
  SIGN_IN_WITH_GOOGLE_ACCOUNT_DEACTIVATED: 'Your account is disabled. Please try another one.',
  SIGN_IN_WITH_GOOGLE_DEFAULT: 'Something went wrong. Unable to sign in with Google.',
  SIGN_IN_WITH_GOOGLE_EMAIL_NOT_SUPPLIED: 'Email not supplied. Please try again.',
  SIGN_IN_WITH_GOOGLE_INTERNAL_ERROR: 'Unable to sign in with Google. Please try another account.',
  SIGN_IN_WITH_GOOGLE_INVALID_API_CALL: 'Unable to sign in with Google. Please try again.',
  SIGN_IN_WITH_GOOGLE_INVALID_CREDENTIALS: 'Email and password do not match. Please try again.',
  SIGN_IN_WITH_GOOGLE_USER_ALREADY_EXISTS: 'This email is associated with an existing account.',

  // Sonar test constants
  HOTPADS_SERVICE_NAME: 'hotpads-web',
  HOTPADS_BASE_URL: 'https://hotpads.com',
  HOTPADS_HEALTH_CHECK_URL: 'https://hotpads.com/check',

  SONAR_15_MINUTE_RUN_INTERVAL: 'EVERY_15_MINUTES',

  SONAR_SOURCE_RESPONSE_STATUS: 'RESPONSE_STATUS',
  SONAR_SOURCE_RESPONSE_TEXT: 'RESPONSE_TEXT',
  SONAR_SOURCE_RESPONSE_HEADERS: 'RESPONSE_HEADERS',
  SONAR_SOURCE_REQUEST_HEADERS: 'REQUEST_HEADERS',

  SONAR_COMPARISON_EQUAL: 'EQUAL',
  SONAR_COMPARISON_CONTAINS: 'CONTAINS',
  SONAR_COMPARISON_DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',

  SONAR_VALUE_STATUS_CODE_200: '200',
  SONAR_VALUE_STATUS_CODE_404: '404',
  SONAR_VALUE_STATUS_CODE_410: '410',

  SONAR_PROPERTY_LOCATION: 'location',
  SONAR_PROPERTY_TRACEPARENT: 'traceparent',
};

export default StringConstants;

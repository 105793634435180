// @ts-nocheck
/* eslint-enable */
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import uniq from 'lodash/uniq';

import browserUtils from 'app/client/utils/browserUtils';
import { getPis } from 'app/client/utils/browserCookieUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';

const listingDict = {
  auction: 'Buy_Auction',
  foreclosure: 'Buy_Foreclosure',
  land: 'Buy_Land',
  newHome: 'Buy_New_Home',
  corporate: 'Rent_Corporate',
  rental: 'Rent_Rental',
  room: 'Rent_Roommate',
  sublet: 'Rent_Sublet',
};

export const adUtils_squareAd = () => {
  return {
    name: 'squaread',
    index: 0,
    placement: 'HotPads/Property_Details',
    format: 'b_right_p1',
    sizes: [[300, 250]],
    height: 250,
    width: 300,
    type: 'squareAd',
  };
};

const getListtp = (listingTypes) => {
  if (listingTypes.length > 1 || isEmpty(listingTypes)) {
    return 'Rent_General';
  } else {
    return listingDict[listingTypes[0]];
  }
};

const getPlacementForNavAd = (listingTypes) => {
  const adUnit = 'HotPads';

  const baseUrl = `${adUnit}/Search_Results`;
  const listingUrl = getListtp(listingTypes);

  return `${baseUrl}/${listingUrl}`;
};

const getPlacementForNavAdV2 = (listingTypes) => {
  let adUnit = 'HotPads';
  const device = browserUtils.getDevice();

  if (device.screenWidth === 'sm' || device.screenWidth === 'xs') {
    adUnit = 'hotpads_phone_mweb';
  } else if (device.screenWidth === 'md') {
    adUnit = 'hotpads_tablet_mweb';
  }

  const baseUrl = `${adUnit}/Search_Results`;
  const listingUrl = getListtp(listingTypes);

  return `${baseUrl}/${listingUrl}`;
};

/**
 * adUtils_getStateForAdvertisement - HPWEB-5862 - sending two states for the advertisement call
 * @param {boolean} isMapBorderActive - is the map border active or not
 * @param {string|false} reduxAreaState - current area state in Area reducer (i.e. 'ca', 'ny', 'tx', 'fl')
 * @param {string|undefined} apiAreaState - current area state from API response (i.e. 'ca', 'ny', 'tx', 'fl')
 * @returns boolean
 */
export const adUtils_getStateForAdvertisement = (isMapBorderActive, reduxAreaState, apiAreaState = 'ny') => {
  // For the state of Oregon only, use `ore` instead of `or`
  const formattedReduxAreaState = reduxAreaState === 'or' ? 'ore' : reduxAreaState;

  /**
   * Use state found in area reducer if the map has a definend boundary AND areaState is truthy
   * else, fall back on the adTags fetched state
   */

  return isMapBorderActive && reduxAreaState ? formattedReduxAreaState : apiAreaState;
};

export const adUtils_adIsBlockedOrEmpty = (id) => {
  if (typeof window === 'undefined') {
    return false;
  }

  const adElement = document.getElementById(id);
  const adEmpty = adElement && adElement.innerHTML === '';

  if (adEmpty) {
    return true;
  }

  return false;
};

export const adUtils_createDivId = (options = {}) => {
  const { adConfig = {} } = options;

  return `div-gpt-ad-${adConfig.placement}-${adConfig.index}-${adConfig.type}`;
};

export const adUtils_getAdSlot = (options = {}) => {
  const { adConfig, ads = {} } = options;
  const adType = adConfig.type;

  if (adType && ads[adType].id) {
    return ads[adType].slot;
  }

  return false;
};

export const adUtils_getListingAd = (options) => {
  const { listingTypes } = options;

  return {
    name: 'navAd',
    index: 4,
    placement: getPlacementForNavAd(listingTypes),
    format: 'n_right_p1',
    sizes: [[1, 1], [805, 115], 'fluid'],
    type: 'navAd',
  };
};

export const adUtils_getListingAdV2 = (options) => {
  const { listingTypes } = options;

  return {
    name: 'navAd',
    index: 4,
    placement: getPlacementForNavAdV2(listingTypes),
    format: 'search_1',
    sizes: [[1, 1], [805, 115], 'fluid'],
    type: 'navAd',
  };
};

export const adUtils_getAdConfig = (options = {}) => {
  const { filter = {}, navAd, squareAd, listingsAd } = options;
  const adaptedFilter = adapt_reduxToJava({ filter });
  const listingTypes = adaptedFilter.listingTypes.split(',');
  let adConfig = {};

  if (squareAd) {
    adConfig = adUtils_squareAd();
  } else if (navAd) {
    adConfig = adUtils_getListingAd({
      listingTypes,
    });
  } else if (listingsAd) {
    adConfig = adUtils_getListingAdV2({
      listingTypes,
    });
  }

  if (!isEmpty(adConfig)) {
    const pis = getPis();

    (adConfig.customTargeting || {}).pis = pis;
  }

  return adConfig;
};

export const adUtils_getParsedString = (str = '') => {
  return str.toLowerCase().split(' ').join('_');
};

export const adUtils_removeFractionalValues = (arr = []) => {
  return arr.filter((v) => {
    if (!includes(v, '.5')) {
      return v;
    }
  });
};

export const adUtils_getPriceRangeBucketValue = (lowPrice = 0, highPrice = Number.POSITIVE_INFINITY) => {
  const rentalBuckets = [
    [0, 499],
    [500, 749],
    [750, 999],
    [1000, 1249],
    [1250, 1499],
    [1500, 1749],
    [1750, 1999],
    [2000, 2499],
    [2500, 2999],
    [3000, 3499],
    [3500, 3999],
    [4000, 4499],
    [4500, 4999],
    [5000, 5999],
    [6000, 6999],
    [7000, 7999],
    [8000, 8999],
    [9000, 9999],
  ];
  const selectedBuckets = [];

  rentalBuckets.forEach((bucket) => {
    const bucketMin = bucket[0];
    const bucketMax = bucket[1];

    if (lowPrice <= bucketMax && highPrice >= bucketMin) {
      selectedBuckets.push(bucket.join('-'));
    }
  });

  if (highPrice > last(rentalBuckets)[1]) {
    selectedBuckets.push('10000_and_up');
  }

  return flatten(selectedBuckets).join(',');
};

export const adUtils_getTargetingForNavAd = ({
  city,
  state,
  zip,
  latitude,
  longitude,
  beds,
  baths,
  lowPrice,
  highPrice,
  listingTypes,
  pis,
}) => {
  return {
    city: [adUtils_getParsedString(city)],
    state: [state],
    zip,
    prange: adUtils_getPriceRangeBucketValue(lowPrice, highPrice),
    bd: adUtils_removeFractionalValues(beds),
    listtp: getListtp(listingTypes),
    ba: baths,
    latitude,
    longitude,
    pis,
  };
};

export const adUtils_getTargeting = (options = {}) => {
  const { defaultFilter = {}, filter = {}, area = {}, searchListings } = options;
  const pis = getPis();
  const adaptedFilter = adapt_reduxToJava({ filter });
  const listingTypes = adaptedFilter.listingTypes.split(',');

  const targetingObj = {
    city: area.area.city,
    state: area.area.state,
    listingTypes,
    pis,
  };

  if (area?.area?.coordinates?.lat) {
    targetingObj.latitude = Number(area.area.coordinates.lat);
    targetingObj.longitude = Number(area.area.coordinates.lon);
  }

  if (!isEqual(defaultFilter.bedrooms, filter.bedrooms)) {
    const bedrooms = adaptedFilter.bedrooms.split(',');
    targetingObj.beds = bedrooms;
  }
  if (!isEqual(defaultFilter.bathrooms, filter.bathrooms)) {
    const bathrooms = adaptedFilter.bathrooms.split(',');
    targetingObj.baths = bathrooms;
  }
  if (!isEqual(defaultFilter.price.min, filter.price.min)) {
    targetingObj.lowPrice = filter.price.min;
  }
  if (!isEqual(defaultFilter.price.max, filter.price.max)) {
    targetingObj.highPrice = filter.price.max;
  }

  targetingObj.zip = uniq(
    compact(
      searchListings.slice(0, 5).map((listing) => {
        return listing.address.zip;
      }),
    ),
  );

  return adUtils_getTargetingForNavAd(targetingObj);
};

/**
 * Watch for changes in path and dynamically load GTM based on routes where we need to show ad slots.
 * This handles instances where user loads home page (or favorites, etc) and transitions to an SRP or HDP.
 * This method also easily allows us to easily add support for loading GTM for additional routes in the
 * future, if needed.
 */
export const adUtils_routeShouldLoadGtm = (pathname) => {
  if (routeUtils.isPadOrBuildingUrl(pathname)) {
    return true;
  }

  if (routeUtils.isAreaUrl(pathname)) {
    return true;
  }

  return false;
};

import * as zanalytics from '@zillow/zanalytics';

// apiKeys are available in "Clickstream Config By Brand" table via:
// https://zwiki.zillowgroup.net/pages/viewpage.action?spaceKey=AT&title=ZG+Brand+DSP+Info
const options = {
  zillow: {
    apiKey: __USE_DSP_STAGING_SERVER__ ? 'hp_dev_web' : 'hp_prod_web',
    apiHost: __USE_DSP_STAGING_SERVER__ ? 'analytics-zg-api.test.zillow.net' : 'e.zg-api.com',
    secure: true,
    anonymousId: '',
  },
};

export function initialize({ userToken = '' } = {}) {
  if (typeof window === 'undefined') {
    return;
  }

  options.zillow.anonymousId = userToken;
  zanalytics.initZanalytics(window, document, options);
}

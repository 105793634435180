// @ts-nocheck
/* eslint-enable */
import findIndex from 'lodash/findIndex';
import max from 'lodash/max';
import pull from 'lodash/pull';

const findListingIndex = (listingGroups, listing) => {
  return findIndex(listingGroups, (currentListing) => {
    return currentListing.maloneLotIdEncoded === listing.maloneLotIdEncoded;
  });
};

// same as above but will return 0 if it does not find an index instead of -1
const getCurrentListingIndex = (list, currentListing) => {
  return max([findListingIndex(list, currentListing), 0]);
};

const updateUserItemTypes = (userItemTypes, action, type) => {
  if (action === 'add') {
    if (userItemTypes.indexOf(type) === -1) {
      userItemTypes.push(type);
    }
  } else {
    userItemTypes = pull(userItemTypes, type);
  }
  return [].concat(userItemTypes);
};

export { findListingIndex, updateUserItemTypes, getCurrentListingIndex };

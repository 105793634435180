export const colors = {
  // General
  '$hpx-white': '#fff',
  '$hpx-black': '#000',

  // Blues
  '$hpx-blue-500': '#2f3c53',
  '$hpx-blue-600': '#212a3b',
  '$hpx-blue-700': '#121821',

  // Greys
  '$hpx-grey-100': '#f2f2f2',
  '$hpx-grey-400': '#d8d8d8',
  '$hpx-grey-500': '#949494',
  '$hpx-grey-600': '#6e6e6e',

  // Teals
  '$hpx-teal-100': '#daf2f0',
  '$hpx-teal-300': '#00e8d4',
  '$hpx-teal-500': '#007a70',
  '$hpx-teal-600': '#005952',
  '$hpx-teal-700': '#00403b',

  // Reds
  '$hpx-red-100': '#f6d9d1',
  '$hpx-red-400': '#c93a1a',
  '$hpx-red-500': '#a32f15',
  '$hpx-red-600': '#7d2410',

  // Yellows
  '$hpx-yellow-400': '#fde786',
  '$hpx-yellow-500': '#fed130',
  '$hpx-yellow-600': '#efae2c',

  // Company
  '$facebook-blue': '#3b5998',
  '$facebook-hover-blue': '#507ed9',
  '$facebook-active-blue': '#2756b3',
  '$gplus-blue': '#4285f4',
  '$gplus-hover-blue': '#679df5',
  '$gplus-active-blue': '#146af5',
  '$gplus-red': '#dd4b39',
  '$twitter-blue': '#4099ff',
  '$linked-in-blue': '#007bb5',
  '$pinterest-red': '#c92228',

  // Other
  '$hp-hover-state': 'rgba(0, 0, 0, 0.1)',
  '$hp-modal-overlay': 'rgba(255, 255, 255, 0.9)',
  '$hp-gray-translucent': 'rgba(33, 42, 59, 0.8)',

  // Purple
  '$hp-purple-500': '#6D4772',
  '$hp-purple-600': '#49304D',
  '$hp-purple-700': '#302033',
};

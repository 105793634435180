import React from 'react';
import IconElectric from 'images/icons/electric.inline.svg';
import * as S from './styles';

const OfflineNotification: React.FC = () => {
  return (
    <S.OfflineNotification>
      <S.OfflineNotificationLeft>
        <S.OfflineNotificationTitle>You are browsing HotPads offline</S.OfflineNotificationTitle>
        <p>Please check your internet connection.</p>
      </S.OfflineNotificationLeft>
      <S.OfflineNotificationRight>
        <S.OfflineNotificationImg src={IconElectric} />
      </S.OfflineNotificationRight>
    </S.OfflineNotification>
  );
};

export default OfflineNotification;

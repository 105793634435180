const mapStrings = {
  BICYCLE_OVERLAY: 'bicycleOverlay',
  TRANSIT_OVERLAY: 'transitOverlay',
  NO_OVERLAY: 'none',
};

const overlays = [mapStrings.BICYCLE_OVERLAY, mapStrings.TRANSIT_OVERLAY, mapStrings.NO_OVERLAY];

const mapTypeStrings = {
  DEFAULT: 'default',
  SATELLITE: 'satellite',
};

const mapTypes = [mapTypeStrings.DEFAULT, mapTypeStrings.SATELLITE];

const mapConstants = {
  ...mapStrings,
  ...mapTypeStrings,
  MAP_TYPES: mapTypes,
  OVERLAYS: overlays,
  ID: '751c42f5dfd15580',
};

export default mapConstants;

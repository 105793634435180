import numberUtils from 'app/shared/utils/numberUtils';

interface MapDataProps {
  lat?: number;
  lon?: number;
  maxLat?: number;
  minLat?: number;
  maxLon?: number;
  minLon?: number;
  zoom?: number;
}

class MapData {
  lat: number;
  lon: number;
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
  zoom: number;

  constructor({ lat = 0, lon = 0, maxLat = 0, minLat = 0, maxLon = 0, minLon = 0, zoom = 0 }: MapDataProps = {}) {
    this.lat = lat
      ? numberUtils.trimDecimal(lat)
      : (numberUtils.trimDecimal(maxLat) + numberUtils.trimDecimal(minLat)) / 2;
    this.lon = lon
      ? numberUtils.trimDecimal(lon)
      : (numberUtils.trimDecimal(maxLon) + numberUtils.trimDecimal(minLon)) / 2;
    this.minLat = numberUtils.trimDecimal(minLat);
    this.maxLat = numberUtils.trimDecimal(maxLat);
    this.minLon = numberUtils.trimDecimal(minLon);
    this.maxLon = numberUtils.trimDecimal(maxLon);
    this.zoom = zoom;
  }
}

export default MapData;

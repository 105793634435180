import VALUE_CONSTANTS from 'app/shared/constants/ValueConstants';
/**
 * List of allowed Filter names and filter params. To be used as a source of truth
 */

const _BEDROOMS_ENUM = {
  '0': '0',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
};

const _BATHROOMS_ENUM = {
  '0': '0',
  '1': '1',
  '1.5': '1.5',
  '2': '2',
  '3': '3',
  '4': '4',
};

const _AMENITIES_ENUM = {
  cooling: 'cooling',
  dishwasher: 'dishwasher',
  doorman: 'doorman',
  fitnessCenter: 'fitnessCenter',
  gatedEntry: 'gatedEntry',
  heating: 'heating',
  parking: 'parking',
  swimmingPool: 'swimmingPool',
};

const _LAUNDRY_ENUM = {
  inUnit: 'inUnit',
  shared: 'shared',
};

const _PETS_ENUM = {
  cats: 'cats',
  dogs: 'dogs',
};

const _COMMUTE_ENUM = {
  DRIVING: 'DRIVING',
  CYCLING: 'CYCLING',
  WALKING: 'WALKING',
  TRANSIT: 'TRANSIT',
  now: 'now',
  offPeak: 'offPeak',
  rushHour: 'rushHour',
  '60': 60,
  '45': 45,
  '30': 30,
  '15': 15,
};

const _LISTING_TYPES_ENUM = {
  rental: 'rental',
  room: 'room',
  sublet: 'sublet',
  corporate: 'corporate',
};

const _MAX_CREATED_ENUM = {
  '1': 1,
  '24': 24,
  '168': 168,
  '720': 720,
};

const _ORDER_BY_ENUM = {
  score: 'score',
  experimentScore: 'experimentScore',
  weekViews: 'weekViews',
  activated: 'activated',
  highPrice: 'highPrice',
  lowPrice: 'lowPrice',
};

const _PROPERTY_TYPES_ENUM = {
  house: 'house',
  divided: 'divided',
  condo: 'condo',
  townhouse: 'townhouse',
  medium: 'medium',
  large: 'large',
  garden: 'garden',
  land: 'land',
};

export const FILTER_ENUM = {
  BEDROOMS: _BEDROOMS_ENUM,
  BATHROOMS: _BATHROOMS_ENUM,
  AMENITIES: _AMENITIES_ENUM,
  LAUNDRY: _LAUNDRY_ENUM,
  PETS: _PETS_ENUM,
  COMMUTE: _COMMUTE_ENUM,
  LISTING_TYPES: _LISTING_TYPES_ENUM,
  MAX_CREATED: _MAX_CREATED_ENUM,
  ORDER_BY: _ORDER_BY_ENUM,
  PROPERTY_TYPES: _PROPERTY_TYPES_ENUM,
};

const ALL_BEDROOMS = ['0', '1', '2', '3', '4', '5', '6', '7', '8plus'];
export const BEDROOMS = {
  ANY: ALL_BEDROOMS.join(','),
  ONE_BEDROOM_AND_UP: ALL_BEDROOMS.slice(1).join(','),
  TWO_BEDROOMS_AND_UP: ALL_BEDROOMS.slice(2).join(','),
  THREE_BEDROOMS_AND_UP: ALL_BEDROOMS.slice(3).join(','),
  FOUR_BEDROOMS_AND_UP: ALL_BEDROOMS.slice(4).join(','),
};

const ALL_BATHROOMS = [
  '0',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8plus',
];
export const BATHROOMS = {
  ANY: ALL_BATHROOMS.join(','),
  ONE_BATHROOM_AND_UP: ALL_BATHROOMS.slice(2).join(','),
  ONE_HALF_BATHROOMS_AND_UP: ALL_BATHROOMS.slice(3).join(','),
  TWO_BATHROOMS_AND_UP: ALL_BATHROOMS.slice(4).join(','),
  THREE_BATHROOMS_AND_UP: ALL_BATHROOMS.slice(6).join(','),
  FOUR_BATHROOMS_AND_UP: ALL_BATHROOMS.slice(8).join(','),
};

const ALL_PROPERTY_TYPES = ['condo', 'divided', 'garden', 'house', 'land', 'large', 'medium', 'townhouse'];
export const PROPERTY_TYPES = {
  ANY: ALL_PROPERTY_TYPES.join(','),
  APARTMENT: ['garden', 'large', 'medium'].join(','),
  DUPLEX: ['divided'].join(','),
};

export const COMMUTE_MODE_TYPES_REDUX_TO_JAVA = {
  Driving: 'DRIVING',
  Transit: 'TRANSIT',
  Walking: 'WALKING',
  Cycling: 'CYCLING',
  Now: 'now',
  'Rush hour': 'rushHour',
  'Off peak': 'offPeak',
};

export const COMMUTE_MODE_TYPES_JAVA_TO_REDUX = {
  DRIVING: 'Driving',
  CYCLING: 'Cycling',
  WALKING: 'Walking',
  TRANSIT: 'Transit',
  now: 'Now',
  offPeak: 'Off peak',
  rushHour: 'Rush hour',
};

export const MAX_CREATED_TYPES_REDUX_TO_JAVA = {
  hour: 1,
  day: 24,
  week: 168,
  month: 720,
};

export const ORDER_BY_TYPES_REDUX_TO_DISPLAY = {
  score: 'Sort: Recommended',
  experimentScore: 'Sort: Recommended',
  activated: 'Sort: Newest',
  weekViews: 'Sort: Most popular',
  highPrice: 'Sort: High price',
  lowPrice: 'Sort: Low price',
};

export const SQFT_TYPES = {
  MAX: 3800,
  MIN: 0,
};

export const PRICE_SLIDER_MAX = (1 / VALUE_CONSTANTS.PRICE_FILTER_INCREMENT) * 5000;

interface InstantTourFormProps {
  tourType?: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  selectedDate?: string;
  selectedDateTime?: string;
  step?: string;
  isOpen?: boolean;
}

class InstantTourForm {
  tourType: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  selectedDate: string;
  selectedDateTime: string;
  step: string;
  isOpen: boolean;

  constructor({
    tourType = '',
    email = '',
    phone = '',
    firstName = '',
    lastName = '',
    selectedDate = '',
    selectedDateTime = '',
    step = '',
    isOpen = false,
  }: InstantTourFormProps = {}) {
    this.tourType = tourType;
    this.email = email;
    this.phone = phone;
    this.firstName = firstName;
    this.lastName = lastName;
    this.selectedDate = selectedDate;
    this.selectedDateTime = selectedDateTime;
    this.step = step;
    this.isOpen = isOpen;
  }
}

export default InstantTourForm;

// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import map from 'lodash/map';
import {
  listingUtils_getCondensedModelsAndPricing,
  listingUtils_getIconType,
  listingUtils_isApartmentBldg,
  listingUtils_userOnWaitlist,
} from 'app/shared/utils/listingUtils';
import { filterUtils_getSearchSlugFromListing } from 'app/shared/utils/filterUtils';
import dateUtils from 'app/shared/utils/dateUtils';

import Address from 'app/shared/models/Address';
import Broker from 'app/shared/models/Broker';
import Amenities from 'app/shared/models/Amenities';
import Contact from 'app/shared/models/Contact';
import Details from 'app/shared/models/Details';
import FloorplanModel from 'app/shared/models/FloorplanModel';
import Geo from 'app/shared/models/Geo';
import History from 'app/shared/models/History';
import ListedBy from 'app/shared/models/ListedBy';
import ListingAreas from 'app/shared/models/ListingAreas';
import ListingReviews from 'app/shared/models/ListingReviews';
import ModelCollection from 'app/shared/models/ModelCollection';
import OpenHouse from 'app/shared/models/OpenHouse';
import Photo from 'app/shared/models/Photo';
import PopularitySummary from 'app/shared/models/PopularitySummary';
import Pricing from 'app/shared/models/Pricing';
import RecencyTime from 'app/shared/models/RecencyTime';
import School from 'app/shared/models/School';
import UserItemDates from 'app/shared/models/UserItemDates';
import { RoomDetails } from 'app/shared/models/RoomForRent';
import { getGlobalLogger } from '@zg-rentals/logger-base';

import noPhoto from 'images/noPhoto.png';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('models');

const UnitV2 = function (
  {
    active = true,
    address = {},
    aliasEncoded = '',
    amenities = {},
    companyProfile = {},
    contact = {},
    created = 0,
    details = {},
    featuredPropertyProductId = 0,
    floorplans = [],
    history = {},
    isPaidAndNotDemoted = false,
    legalDisclaimer = '',
    listedBy = {},
    listingPhotos = [],
    listingType = '',
    maloneLotIdEncoded = '',
    medPhotoUrls = [], // HPWEB-6082: Medium photo URLs for new listing cards
    models = [],
    openHouses = [],
    photoCount = 0,
    popularity = {},
    pricing = {},
    pricingStats,
    priority = 0,
    propertyType = '',
    rentalApplicationStatus = '',
    searchKeyword = '',
    thumbPhotoUrl = noPhoto,
    title = '',
    unit = '',
    updated = 0,
    uriBuilding = '',
    uriMalone = '',
    userActivity = {},
    userItems = {},
    userItemTypes = [],
    waitlisted = false,
    zestimate = {},
  } = {},
  {
    areas = {},
    geo = {},
    belongsToMultipleUnitBuilding = false,
    forRentUnitsInBuilding = [],
    lotIdReviews = {},
    schools = [],
  } = {},
) {
  try {
    if (!lotIdReviews.reviews) {
      lotIdReviews.reviews = [];
    }

    listingType = listingType.toLowerCase();
    propertyType = propertyType.toLowerCase();

    const searchSlug = filterUtils_getSearchSlugFromListing({ listingType, propertyType });
    const processedAreas = new ListingAreas({ searchSlug, areas, pricingStats, pricing });
    const modelCollection = ModelCollection();
    /*
     * `floorplans` for /listing/byMaloneLotIdEncodedV2
     * `models` for all other calls
     */
    modelCollection.add(floorplans.length ? floorplans : models, listingType, propertyType);
    const showPricingStats = pricingStats ? true : false; // eslint-disable-line no-unneeded-ternary
    const neighborhood = (areas.specific && areas.specific.name) || '';
    this.active = Boolean(active === false ? false : true); // eslint-disable-line no-unneeded-ternary
    this.address = new Address(assign(address, { neighborhood }));
    this.aliasEncoded = String(aliasEncoded);
    this.amenities = new Amenities(amenities, new RoomDetails(details?.roomForRent?.roomDetails ?? {}));
    this.areas = processedAreas;
    this.belongsToMultipleUnitBuilding = Boolean(belongsToMultipleUnitBuilding);
    this.building = false;

    // HP-5674: Strip street address from defaultInquiryMessage received via API if needed.
    if (this.address.hideStreet) {
      contact.defaultInquiryMessage = `I'd like to schedule a viewing for this listing. Please contact me with more information!`;
    }

    this.contact = new Contact(contact);
    this.created = Number(created); // does the api return the `activated` value as `created`?
    this.details = new Details(details, floorplans);
    this.displayName = String(title);
    this.featuredPropertyProductId = Number(featuredPropertyProductId);
    this.floorplans = map(floorplans, (floorplan) => {
      return new FloorplanModel(floorplan);
    });
    this.forRentUnitsInBuilding = map(forRentUnitsInBuilding, (unitInBuilding) => {
      return String(unitInBuilding);
    });
    this.geo = new Geo(geo);
    this.history = new History(history);
    this.iconType = String(listingUtils_getIconType(propertyType));
    this.isApartmentBldg = Boolean(listingUtils_isApartmentBldg(listingType, propertyType));
    this.isPaidAndNotDemoted = Boolean(isPaidAndNotDemoted);
    this.isUnit = true;
    this.legalDisclaimer = String(legalDisclaimer);
    this.largePhotos = map(listingPhotos, (image = {}) => {
      return new Photo({
        url: image.large && image.large.url,
        caption: image.caption,
      });
    });
    this.listedBy = new ListedBy(
      assign({}, listedBy, {
        mlsLogo: details.mlsLogo,
        disclaimer: details.disclaimer,
        landlordPhotoUrl: details.landlordPhotoUrl,
        contactPhone: listedBy.contactPhone || companyProfile.contactPhone || null,
      }),
    );
    this.listingMinMaxPriceBeds = modelCollection.summarize();
    this.listingType = String(listingType);
    this.maloneLotIdEncoded = String(maloneLotIdEncoded);

    // HPWEB-6082: Medium photo URLs for new listing cards
    this.medPhotoUrls =
      medPhotoUrls ||
      listingPhotos
        ?.filter((image) => {
          return image?.medium?.url;
        })
        .map((image) => {
          return image.medium.url;
        });

    this.modelsAndPricing = listingUtils_getCondensedModelsAndPricing(floorplans);
    this.openHouses = map(openHouses, (openHouseEvent) => new OpenHouse(openHouseEvent));
    this.photoCount = Number(photoCount);
    this.photos = map(listingPhotos, (image = {}) => {
      return new Photo({
        url: image.medium && image.medium.url,
        caption: image.caption,
      });
    });
    this.popularity = {
      summary: new PopularitySummary(popularity.summary),
    };
    this.previewPhoto =
      thumbPhotoUrl === noPhoto ? new Photo({ url: noPhoto, noPhoto: true }) : new Photo({ url: thumbPhotoUrl });
    this.pricing = new Pricing(pricing);
    this.priority = Number(priority);
    this.propertyType = String(propertyType);
    this.ratingsAndReviews = new ListingReviews(lotIdReviews);
    this.recencyTime = new RecencyTime(dateUtils.recencyTime(created, new Date()));
    this.rentalApplicationStatus = String(rentalApplicationStatus);
    this.schools = map(schools, (school) => {
      school.searchSlug = searchSlug;
      return new School(school);
    });
    this.score = Number(priority);
    this.searchKeyword = String(searchKeyword);
    this.showPricingStats = showPricingStats;
    this.specialOffers = details.specialOffers || [];
    this.thumbPhotos = map(listingPhotos, (image = {}) => {
      return new Photo({
        url: image.squareThumb && image.squareThumb.url,
        caption: image.caption,
      });
    });
    this.trusted = Boolean(details.trusted);
    this.unit = String(unit); // Unit A
    this.updated = Number(updated);
    this.uriBuilding = String(decodeURI(uriBuilding));
    this.uriMalone = String(decodeURI(uriMalone));
    this.urlMaloneUnit = String(unit).toLowerCase().replace(' ', '-'); // 'unit-a'
    this.uriV2 = String(decodeURI(uriMalone));
    this.userItemDates = new UserItemDates(userItems);
    this.userItemTypes = map(userItemTypes, (userItem) => {
      return String(userItem || '');
    });
    this.userOnWaitlist = listingUtils_userOnWaitlist(userActivity);
    this.waitlisted = Boolean(waitlisted);
    this.zestimate = Number(zestimate.zestimate || 0);
    this.broker = new Broker(
      Object.assign(
        {
          isBrokerExclusiveListing: details.isBrokerExclusiveListing,
          isBrokerListing: details.isBrokerListing,
        },
        listedBy,
      ),
    );
    this.isAllowedToRetain = Boolean(details.isAllowedToRetain);
  } catch (err) {
    logError({ error: err, errorType: 'DataModelError', context: 'UnitV2' });
    return false;
  }
};

export default UnitV2;

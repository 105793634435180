import type { FC, ReactNode } from 'react';
import React from 'react';
import { isUserAgentBot } from 'app/shared/utils/userAgentUtils';
import { BotContext } from '@zillow/react-deferred-hydration';

interface BotProviderProps {
  children: ReactNode;
  userAgent: string;
}

const BotProvider: FC<BotProviderProps> = ({ children, userAgent }) => {
  const isSearchEngineCrawler = isUserAgentBot(userAgent);

  return <BotContext.Provider value={{ isSearchEngineCrawler }}>{children}</BotContext.Provider>;
};

export default BotProvider;

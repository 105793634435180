// @ts-nocheck
/* eslint-enable */
/* eslint-disable no-undef */

import stringHelper from 'app/shared/utils/stringHelper';

export const setOptOutCookie = () => {
  return new Promise((res, rej) => {
    let oneTrustInitSetInterval;

    // reject promise if OneTrust does not initialize in time
    const oneTrustInitSetTimeout = setTimeout(() => {
      clearInterval(oneTrustInitSetInterval);
      rej({ errorCode: '400', errorMsg: '[Cookie] Could not initialize OneTrust' });
    }, 10 * 1000);

    // Adapted from https://community.cookiepro.com/s/article/UUID-dd6ec545-2281-e68f-8acf-f8254c9b00fd
    const updateTargeting = () => {
      clearTimeout(oneTrustInitSetTimeout);
      clearInterval(oneTrustInitSetInterval);

      const domain = window.location.hostname;
      let oneTrustCookie = stringHelper.getCookieByName('OptanonConsent');

      if (!oneTrustCookie) {
        // cookies manually cleared by user - do nothing
        return res(false);
      }
      /**
       * IDs:
       * 1. required cookies (userToken, sessionId, etc)
       * 2. performance cookies (no longer used)
       * 3. functional cookies (analytics/performance)
       * 4. advertisting and misc
       */
      const IDS = [2, 3, 4];

      for (let i = 0; i < IDS.length; i++) {
        oneTrustCookie = oneTrustCookie.replace('%2C' + IDS[i] + '%3A0', '%2C' + IDS[i] + '%3A0');
        oneTrustCookie = oneTrustCookie.replace('%2C' + IDS[i] + '%3A1', '%2C' + IDS[i] + '%3A0');
        OptanonActiveGroups = OptanonActiveGroups.replace(',' + IDS[i] + ',', ',');
        OnetrustActiveGroups = OnetrustActiveGroups.replace(',' + IDS[i] + ',', ',');
      }
      document.cookie = 'OptanonConsent=' + oneTrustCookie + ';path=/;domain=' + domain + ';';

      res(true);
    };

    // method 1: poll for oneTrust initialization
    oneTrustInitSetInterval = setInterval(() => {
      if (window.oneTrustLoaded === true) {
        updateTargeting();
      }
    }, 1000);

    // method 2: wait for OneTrust initialize event
    window.addEventListener('oneTrustLoadedEvent', updateTargeting);
  });
};

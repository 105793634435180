import React from 'react';
import loadable from '@loadable/component';

const PromptLogin = loadable(
  () => import(/* webpackChunkName: "PromptLogin.loadable" */ 'app/shared/modules/auth/PromptLogin/component'),
);

class LoadablePromptLogin extends React.Component {
  render() {
    return <PromptLogin {...this.props} />;
  }
}

export default LoadablePromptLogin;

import pino from 'pino';

export type Logger = pino.Logger;

export type LoggerOptions = pino.LoggerOptions & {
  logPath?: string;
};

// WeakMap pairing a logger instance with its children
const loggers = new WeakMap<Logger, Map<string, Logger>>();

// Keep track of a logger's children so that we can
// A) keep the child log level in sync with the parent log level
// B) avoid creating multiple children with the same bindings
export function trackChildLoggers(logger: Logger) {
  const children = loggers.get(logger) ?? new Map<string, Logger>();
  if (!loggers.has(logger)) {
    loggers.set(logger, children);
  }

  // Pino attaches events to a global emitter, so to avoid warnings we have to be
  // a touch clever
  if ('getMaxListeners' in logger && 'setMaxListeners' in logger) {
    const maxListeners = logger.getMaxListeners();
    logger.setMaxListeners(Math.ceil(maxListeners * 1.5));
  }

  logger.on('level-change', (level, levelValue, _prevLevel, _prevValue, instance) => {
    if (instance === logger && levelValue != undefined) {
      for (const child of loggers.get(instance)?.values() ?? []) {
        child.level = level;
      }
    }
  });

  const makeChildLogger = logger.child;

  // @ts-ignore
  logger.child = function (this: Logger, bindings, ...args) {
    // If this child already exists, don't create a new one.
    let id = bindings.name;
    if (!id) {
      try {
        id = JSON.stringify(bindings);
      } catch (err) {
        id = 'rjs';
      }
    }

    let child = children.get(id);
    if (!child) {
      // @ts-ignore
      child = makeChildLogger.call(this, bindings, ...args);
      if (child) {
        trackChildLoggers(child);
        children.set(id, child);
      }
    }

    return child;
  };
}

// This isn't pretty, but it allows us to make logger children accessible
// without extending the pino.Logger type. This keeps our Logger portable
// and interoperable with anything that accepts a pino logger.
export function getLoggerChildren(logger: Logger): Map<string, Logger> | undefined {
  return loggers.get(logger);
}

export function getChild(logger: Logger, id: string) {
  return getLoggerChildren(logger)?.get(id);
}

// Reference to pino lib
export const createLogger = pino;

import constants from 'app/shared/constants/ConstantsBundle';
import assign from 'lodash/assign';
import isPlainObject from 'lodash/isPlainObject';
import reduxUtils from 'app/shared/utils/reduxUtils';
import mapConstants from 'app/shared/constants/MapConstants';
import { getCurrentEnvForClientSide, PRODUCTION } from '@zg-rentals/rental-platform-config';
import { getNamespace } from '@zg-rentals/environment-utils';

/**
 * State
 */
interface Device {
  deviceType: string;
  customVariableSiteType: string;
  screenWidth: string;
}

interface MapBounds {
  maxLat: number | null;
  minLat: number | null;
  maxLon: number | null;
  minLon: number | null;
}

interface Overlays {
  photoGalleryFull: boolean;
  bicycleOverlay: boolean;
  transitOverlay: boolean;
  createSearchAlert: boolean;
  optInSearchAlerts: boolean;
  mapOverlay: string;
}

interface PopupModal {
  visible: boolean;
  component: any;
  data: Record<string, any>;
}

interface ShowNotification {
  visible: boolean;
  content: any;
  notificationStyle: 'primary' | 'alert' | 'accent';
  visibleTime?: number;
}

export interface AppState {
  buildNumber: number;
  advancedFilterVisible: boolean;
  areaPageMaloneLotIdPosition: any;
  clientLoaded: boolean;
  clusterSettingDict: Record<string, any>;
  device: Device;
  facebookInit: boolean;
  fetchListingsByCoordsComplete: boolean;
  fetchListingsByCoordsOnMapIdleComplete: boolean;
  fetchNearbyListingsComplete: boolean;
  gmapApiReady: boolean;
  gmapLoaded: boolean;
  signInWithGoogleInit: boolean;
  hamburger: boolean;
  host: any;
  indexAdWrapperInit: boolean;
  ip: string;
  fullReqIps: Record<string, any>;
  ipFromJava: Record<string, any>;
  isClientSideLoadedPage: boolean;
  isInitialSsrPage: boolean;
  isMapPanning: boolean;
  isOnline: boolean;
  isValidAreaPage: boolean;
  isValidSearchSlug: boolean;
  loadStatePageComplete: boolean;
  mapBounds: MapBounds;
  mapShouldPan: boolean;
  overlays: Overlays;
  mapType: string;
  popupModalOpen: boolean;
  popupModal: PopupModal;
  preserveAreaBoundary: boolean;
  reviewsPopupModalOpen: boolean;
  server: any;
  showFilterModal: boolean;
  showNotification: ShowNotification;
  hasShownPostContactSavedSearch: boolean;
  twitterInit: boolean;
  userAgent: any;
  userExists: boolean;
  xEids: Array<any>;
  zgEnv: string;
  isProd: boolean;
  traceId: string;
  requestId: string;
  bbTestGroup: any;
}

const initState = (): AppState => ({
  buildNumber: __BUILD_NUMBER__,
  advancedFilterVisible: false,
  areaPageMaloneLotIdPosition: null,
  clientLoaded: false,
  clusterSettingDict: {},
  device: {
    deviceType: 'mobile',
    customVariableSiteType: 'mobileWeb',
    screenWidth: 'sm',
  },
  facebookInit: false,
  fetchListingsByCoordsComplete: false,
  fetchListingsByCoordsOnMapIdleComplete: false,
  fetchNearbyListingsComplete: false,
  gmapApiReady: false, // google map script initialized
  gmapLoaded: false, // our google map instance loaded (available in window)
  signInWithGoogleInit: false,
  hamburger: false,
  host: null,
  indexAdWrapperInit: false,
  ip: '',
  fullReqIps: {},
  ipFromJava: {},
  isClientSideLoadedPage: false,
  isInitialSsrPage: true,
  isMapPanning: false,
  isOnline: true,
  isValidAreaPage: true,
  isValidSearchSlug: true,
  loadStatePageComplete: false,
  mapBounds: {
    maxLat: null,
    minLat: null,
    maxLon: null,
    minLon: null,
  },
  mapShouldPan: false,
  overlays: {
    photoGalleryFull: false,
    bicycleOverlay: false,
    transitOverlay: false,
    createSearchAlert: false,
    optInSearchAlerts: false,
    mapOverlay: mapConstants.NO_OVERLAY,
  },
  mapType: mapConstants.DEFAULT,
  popupModalOpen: false,
  popupModal: {
    visible: false,
    component: null,
    data: {},
  },
  preserveAreaBoundary: false,
  reviewsPopupModalOpen: false,
  server: null,
  showFilterModal: false,
  showNotification: {
    visible: false,
    content: null,
    notificationStyle: 'primary',
  },
  hasShownPostContactSavedSearch: false,
  twitterInit: false,
  userAgent: null,
  userExists: false,
  xEids: [],
  zgEnv: '',
  // isomorphic prod check
  isProd: typeof window === 'undefined' ? getNamespace() === 'production' : getCurrentEnvForClientSide() === PRODUCTION,
  traceId: '',
  requestId: '',
  bbTestGroup: null,
});

const mapActionsToReducer = {
  [constants.SERVER_SIDE_APP_STORE_INIT_STATE]: (state: AppState, action: any) => {
    const initData = action.payload;

    if (!isPlainObject(initData)) {
      return state;
    }
    let device;
    if (initData.isMobile) {
      device = {
        deviceType: 'mobile',
        customVariableSiteType: 'mobileWeb',
        screenWidth: 'sm',
      };
    } else {
      device = {
        deviceType: 'desktop',
        customVariableSiteType: 'desktopWeb',
        screenWidth: 'xl',
      };
    }
    return assign({}, state, {
      advertisementEnv: initData.advertisementEnv,
      clickstreamApi: initData.clickstreamApi,
      clickstreamApiKey: initData.clickstreamApiKey,
      clickstreamWriteKey: initData.clickstreamWriteKey,
      googleMaps: initData.googleMaps,
      device,
      zgEnv: initData.zgEnv,
      userAgent: initData.userAgent,
      host: initData.host || state.host,
      server: initData.server || state.server,
      ip: initData.ip || state.ip,
      clusterSettingDict: initData.clusterSettingDict || state.clusterSettingDict,
      requestId: initData.requestId,
      traceId: initData.traceId,
    });
  },
  [constants.UPDATE_CLUSTER_SETTINGS]: (state: AppState, action: any) => {
    return assign({}, state, {
      clusterSettingDict: action.payload,
    });
  },
  [constants.NOTIFICATION_DISPLAY_BOOL]: (state: AppState, action: any) => {
    let newState = {
      visible: false,
      visibleTime: 0,
      content: null,
    } as ShowNotification;

    if (!action.payload) {
      // Use default state
    } else if (action.payload.content) {
      // Allow users to trigger the notification modal without passing in a visible property.
      newState = {
        visible: true,
        content: action.payload.content,
        notificationStyle: action.payload.notificationStyle,
      };
    } else {
      newState = {
        visible: action.payload.visible,
        content: action.payload.content,
        notificationStyle: action.payload.notificationStyle,
      };
    }

    if (action.payload && action.payload.visibleTime) {
      newState.visibleTime = action.payload.visibleTime;
    }

    // TODO: Add way to pass in custom css themes, so we can display alert message on map instead of just at
    // top of the page.
    return assign({}, state, {
      showNotification: assign({}, state.showNotification, newState),
    });
  },
  [constants.SHOW_POPUP_MODAL]: (state: AppState, action: any) => {
    if (!action.payload) {
      return state;
    }
    return assign({}, state, {
      popupModalOpen: true,
      popupModal: assign({}, state.popupModal, {
        visible: true,
        component: action.payload.component || {},
        data: action.payload.data || {},
      }),
    });
  },
  [constants.HIDE_POPUP_MODAL]: (state: AppState) => {
    return assign({}, state, {
      popupModalOpen: false,
      popupModal: assign({}, initState().popupModal),
    });
  },
  [constants.GMAP_API_READY]: (state: AppState) => {
    return assign({}, state, {
      gmapApiReady: true,
    });
  },
  [constants.TWITTER_INIT_SUCCESS]: (state: AppState) => {
    return assign({}, state, {
      twitterInit: true,
    });
  },
  [constants.FACEBOOK_INIT_SUCCESS]: (state: AppState) => {
    return assign({}, state, {
      facebookInit: true,
    });
  },
  [constants.SIGN_IN_WITH_GOOGLE_INIT_SUCCESS]: (state: AppState) => {
    return assign({}, state, {
      signInWithGoogleInit: true,
    });
  },
  [constants.TOGGLE_OVERLAY]: (state: AppState, action: any) => {
    const partialStateObject = assign({}, state.overlays);

    // @ts-expect-error Need to fix action types
    partialStateObject[action.payload.type] = action.payload.bool;
    return assign({}, state, {
      overlays: assign({}, partialStateObject),
    });
  },
  [constants.MAP_OVERLAY_CHANGED]: (state: AppState, action: any) => {
    const overlays = assign({}, state.overlays);
    const transitOverlayKey = action.payload;
    return {
      ...state,
      overlays: {
        ...overlays,
        mapOverlay: transitOverlayKey,
      },
    };
  },
  [constants.MAP_TYPE_CHANGED]: (state: AppState, action: any) => {
    const mapType = action.payload;

    return {
      ...state,
      mapType,
    };
  },
  [constants.SET_APP_STORE_BOOL]: (state: AppState, action: any) => {
    const partialStateObject = {};

    // @ts-expect-error Need to fix action types
    partialStateObject[action.payload.name] = action.payload.bool;
    return assign({}, state, partialStateObject);
  },
  [constants.SET_BOUNTY_BRAIN_TEST_GROUP]: (state: AppState, action: any) => {
    return assign({}, state, { bbTestGroup: action.payload.bbTestGroup });
  },
  [constants.HAMBURGER_ACTIVE]: (state: AppState) => {
    return assign({}, state, {
      hamburger: true,
    });
  },
  [constants.HAMBURGER_INACTIVE]: (state: AppState) => {
    return assign({}, state, {
      hamburger: false,
    });
  },
  [constants.PUSH_XEID]: (state: AppState, action: any) => {
    const newxEid = action.payload;

    return assign({}, state, {
      // @ts-ignore Not sure how to type this at the moment
      xEids: [].concat(state.xEids).concat(newxEid),
    });
  },
  [constants.SET_DEVICE]: (state: AppState, action: any) => {
    return assign({}, state, {
      device: action.payload,
    });
  },
  [constants.SET_AREA_PAGE_SCROLL]: (state: AppState, action: any) => {
    return assign({}, state, {
      areaPageMaloneLotIdPosition: action.payload,
    });
  },
  [constants.UPDATE_IP_FROM_JAVA]: (state: AppState, action: any) => {
    return assign({}, state, {
      ipFromJava: action.payload,
    });
  },
};

const app = reduxUtils.createReducer(mapActionsToReducer, initState());

export default app;

// Define the interface for the ReviewsSummary input properties
interface ReviewsSummaryProps {
  totalNumberOfTextReviews?: number;
  totalNumberOfReviews?: number;
  averageStarLevel?: number;
  reviewType?: string;
}

class ReviewsSummary {
  totalNumberOfTextReviews: number;
  totalNumberOfReviews: number;
  averageStarLevel: number;
  reviewType: string;
  ratingsWithoutReviews: number;
  ratingLabel: string;

  constructor({
    totalNumberOfTextReviews = 0,
    totalNumberOfReviews = 0,
    averageStarLevel = 0,
    reviewType = '',
  }: ReviewsSummaryProps = {}) {
    this.totalNumberOfTextReviews = Number(totalNumberOfTextReviews);
    this.totalNumberOfReviews = Number(totalNumberOfReviews);
    this.averageStarLevel = Number(Number(averageStarLevel).toFixed(2));
    this.reviewType = String(reviewType);
    this.ratingsWithoutReviews = this.totalNumberOfReviews - this.totalNumberOfTextReviews;

    // Determine the rating label based on the average star level
    if (this.averageStarLevel >= 4) {
      this.ratingLabel = 'Excellent';
    } else if (this.averageStarLevel >= 3) {
      this.ratingLabel = 'Good';
    } else if (this.averageStarLevel >= 2) {
      this.ratingLabel = 'Average';
    } else if (this.averageStarLevel >= 1) {
      this.ratingLabel = 'Poor';
    } else {
      this.ratingLabel = 'Bad';
    }
  }
}

export default ReviewsSummary;

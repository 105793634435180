// @ts-nocheck
/* eslint-enable */
const clientUtils = {
  getMobileOperatingSystem(userAgent) {
    // adapted from https://stackoverflow.com/a/21742107/7669090
    const _userAgent = userAgent || navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(_userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(_userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(_userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  },
  // FIXME: Use passive events where support exists (see consolidated-events)
  mountDomListeners(setDeviceAndWidth, updateOnlineStatus) {
    window.addEventListener('resize', setDeviceAndWidth);
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  },
  // FIXME: Use passive events where support exists (see consolidated-events)
  removeDomListeners(setDeviceAndWidth, updateOnlineStatus) {
    window.removeEventListener('resize', setDeviceAndWidth);
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
  },
  hasEmojiSupport() {
    const pixelRatio = window.devicePixelRatio || 1;
    const offset = 12 * pixelRatio;
    const node = document.createElement('canvas');

    // canvastext support
    if (!node.getContext || !node.getContext('2d') || typeof node.getContext('2d').fillText !== 'function') {
      return false;
    }

    const ctx = node.getContext('2d');

    ctx.fillStyle = '#f00';
    ctx.textBaseline = 'top';
    ctx.font = '32px Arial';
    ctx.fillText('\ud83d\udc28', 0, 0); // U+1F428 KOALA
    return ctx.getImageData(offset, offset, 1, 1).data[0] !== 0;
  },
};

export default clientUtils;

import type Area from './Area';

interface RelatedAreasProps {
  borough?: Array<Area>;
  city?: Array<Area>;
  county?: Array<Area>;
  elemschdist?: Array<Area>;
  highschool?: Array<Area>;
  middleschool?: Array<Area>;
  mixedschool?: Array<Area>;
  neighborhood?: Array<Area>;
  primaryschool?: Array<Area>;
  secschdist?: Array<Area>;
  state?: Array<Area>;
  unifschdist?: Array<Area>;
  university?: Array<Area>;
  zip?: Array<Area>;
}

// Define the class with TypeScript
class RelatedAreas {
  borough: Array<Area>;
  city: Array<Area>;
  county: Array<Area>;
  elemschdist: Array<Area>;
  highschool: Array<Area>;
  middleschool: Array<Area>;
  mixedschool: Array<Area>;
  neighborhood: Array<Area>;
  primaryschool: Array<Area>;
  secschdist: Array<Area>;
  state: Array<Area>;
  unifschdist: Array<Area>;
  university: Array<Area>;
  zip: Array<Area>;

  constructor({
    borough = [],
    city = [],
    county = [],
    elemschdist = [],
    highschool = [],
    middleschool = [],
    mixedschool = [],
    neighborhood = [],
    primaryschool = [],
    secschdist = [],
    state = [],
    unifschdist = [],
    university = [],
    zip = [],
  }: RelatedAreasProps = {}) {
    this.borough = borough;
    this.city = city;
    this.county = county;
    this.elemschdist = elemschdist;
    this.highschool = highschool;
    this.middleschool = middleschool;
    this.mixedschool = mixedschool;
    this.neighborhood = neighborhood;
    this.primaryschool = primaryschool;
    this.secschdist = secschdist;
    this.state = state;
    this.unifschdist = unifschdist;
    this.university = university;
    this.zip = zip;
  }
}

export default RelatedAreas;

import React from 'react';
import loadable from '@loadable/component';

const Animation = loadable(
  () => import(/* webpackChunkName: "Animation.loadable" */ 'app/shared/modules/Animation/component'),
);

interface AnimationProps {
  className: string;
  animationData: object;
  shouldLoop: boolean;
  shouldAutoplay: boolean;
}

const LoadableAnimation = (props: AnimationProps) => {
  return <Animation {...props} />;
};

export default LoadableAnimation;

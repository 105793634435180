import { BrowserMonitor } from './browserMonitor';
import { getGlobalLogger } from '@zg-rentals/logger-browser';
import { getGlobalMonitor as getBaseMonitor, setGlobalMonitor, warningBanner } from '@zg-rentals/monitor-base';
import { isTest } from '@zg-rentals/environment-utils';

export function getGlobalMonitor() {
  let monitor = getBaseMonitor();

  if (!monitor) {
    if (!isTest()) {
      warningBanner('monitor-browser not initialized! You should add a bootstrap to your app');
    }

    monitor = new BrowserMonitor({
      logger: getGlobalLogger('BrowserMonitor'),
      reporters: [],
      plugins: [],
    });

    setGlobalMonitor(monitor);
  }

  return monitor;
}

export { setGlobalMonitor };
